define('gooru-web/components/teacher/class/gru-add-collaborator',['exports','ember','gooru-web/config/config','gooru-web/utils/utils'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-add-collaborator'], // -------------------------------------------------------------------------
// Dependencies
profileService:_ember['default'].inject.service('api-sdk/profile'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.inputTypeHandler();},init:function init(){var component=this;component._super.apply(component,arguments);component.set('users',_ember['default'].A([]));}, // -------------------------------------------------------------------------
// Events
actions:{ //Action triggered when remove an user
onRemoveUser:function onRemoveUser(user){this.get('users').removeObject(user);}, //Action triggered when add collaborators into a class
onAddCollaborators:function onAddCollaborators(){var component=this;var selectedUsers=component.get('users');component.sendAction('onAddCollaborators',selectedUsers);component.set('users',_ember['default'].A([]));}, //Action triggered when click on cancel
onToggleAddCollaborator:function onToggleAddCollaborator(){this.sendAction('onToggleAddCollaborator');}, //Action triggered when click on tick icon which is next to input box
onValidateEmail:function onValidateEmail(){var component=this;var enteredUserEmail=component.get('userEmail');var isEnteredValidEmailId=(0,_gooruWebUtilsUtils.isValidEmailId)(enteredUserEmail);if(isEnteredValidEmailId){if(!component.isDuplicate()){component.doValidateTeacherUser(enteredUserEmail);}else {component.set('isDuplicateEmail',true);}}component.set('inValidEmail',!isEnteredValidEmailId);}}, // -------------------------------------------------------------------------
// Properties
isDuplicate:function isDuplicate(){var component=this;var isDuplicate=false;var enteredUserEmail=component.get('userEmail');var selectedUsers=component.get('users');var doesExists=selectedUsers.findBy('email',enteredUserEmail);var existsAsOwner=component.get('class').get('owner')?component.get('class').get('owner').email === enteredUserEmail:false;var collaborator=component.get('class').get('collaborators') || component.get('class').get('collaborator');var existsAsCoTeacher=collaborator.findBy('email',enteredUserEmail);if((!doesExists || doesExists && doesExists.length === 0) && (!existsAsOwner || existsAsOwner && existsAsOwner.length === 0) && (!existsAsCoTeacher || existsAsCoTeacher && existsAsCoTeacher.length === 0)){isDuplicate = false;}else {isDuplicate = true;}return isDuplicate;}, /**
   * @property {Boolean} isEnableDone
   */isEnableDone:_ember['default'].computed('users','userEmail',function(){var component=this;var isAddedUsers=component.get('users.length');var enteredUserEmail=component.get('userEmail');var enteredValidEmail=enteredUserEmail?(0,_gooruWebUtilsUtils.isValidEmailId)(enteredUserEmail):true;return isAddedUsers && enteredValidEmail;}), /**
   * @property {Array} users
   * Property for list of selected users
   */users:_ember['default'].A([]), /**
   * @property {Boolean} inValidEmail
   * Property to evaluate whether the entered email id is valid or not
   */inValidEmail:false, /**
   * @property {Boolean} isDuplicate
   * Property to evaluate whether the entered email id already exits or not
   */isDuplicateEmail:false, /**
   * @property {String} userEmail
   * Property for entered user email
   */userEmail:null, /**
   * @property {Boolean} isEnableValidateIcon
   * Property to enable validation icon which is next to input box
   */isEnableValidateIcon:_ember['default'].computed('userEmail',function(){var component=this;var userEmail=component.get('userEmail');return userEmail !== null && userEmail !== '';}), // -------------------------------------------------------------------------
// Methods
/**
   * @function inputTypeHandler
   * Method to handle the input key event
   */inputTypeHandler:function inputTypeHandler(){var component=this;component.$('.email-value-container .email-value').keyup(function(event){var userEmail=component.get('userEmail');if(event.keyCode === _gooruWebConfigConfig.KEY_CODES.ENTER && (0,_gooruWebUtilsUtils.isValidEmailId)(userEmail)){if(!component.isDuplicate()){component.doValidateTeacherUser(userEmail);}else {component.set('isDuplicateEmail',true);}}else {component.set('inValidEmail',false);component.set('isDuplicateEmail',false);}});}, /**
   * @function doValidateTeacherUser
   * Method to validate whether the entered email id is valid to add as a collaborator or not
   */doValidateTeacherUser:function doValidateTeacherUser(userEmail){var component=this;var users=component.get('users');var userData=users.findBy('email',userEmail) || _ember['default'].Object.create({});return _ember['default'].RSVP.hash({userDetails:component.fetchMatchingUserProfiles(userEmail)}).then(function(_ref){var userDetails=_ref.userDetails;if(userDetails.get('role') === _gooruWebConfigConfig.ROLES.TEACHER){userData.set('id',userDetails.get('id'));userData.set('firstName',userDetails.get('firstName'));userData.set('lastName',userDetails.get('lastName'));userData.set('fullName',userDetails.get('fullName'));userData.set('avatarUrl',userDetails.get('avatarUrl'));userData.set('email',userEmail);users.pushObject(userData);component.set('inValidEmail',false);component.set('isDuplicateEmail',false);component.set('userEmail',null);}else {component.set('inValidEmail',true);}},function(){component.set('inValidEmail',true);});}, /**
   * @function fetchMatchingUserProfiles
   * Method to fetch user profile details for given emailId
   */fetchMatchingUserProfiles:function fetchMatchingUserProfiles(userEmail){var controller=this;var profileService=controller.get('profileService');return profileService.checkEmailExists(userEmail);}});});