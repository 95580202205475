define('gooru-web/components/cards/gru-navigator-card',['exports','ember','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{classNames:['gru-navigator-card'],taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'),taxonomyGrades:null,independentCourses:null, /**
   * @type {FeaturedCourseService} Service to retrieve Featured Courses Service information
   */featuredCourseService:_ember['default'].inject.service('api-sdk/featured-courses'),profileService:_ember['default'].inject.service('api-sdk/profile'),session:_ember['default'].inject.service('session'),actions:{ /**
     * Action triggered when click on study now button
     */studyNow:function studyNow(){var component=this;var sessionId=this.get('session.userId');var tenantSetting=component.get('tenantSettingsObj');var programCourse=component.get('course.programCourse');var fwCode=programCourse.get('settings.framework');var subjectCode=programCourse.subject;var filters={subject:programCourse.subject};var navigatorPrograms=component.get('isEnableNavigatorPrograms');if(fwCode){if(tenantSetting && tenantSetting.tx_fw_prefs && navigatorPrograms){var isFound=tenantSetting.tx_fw_prefs[subjectCode];filters.fw_code = isFound?isFound.default_fw_id:fwCode;programCourse.set('settings.framework',filters.fw_code);}else {filters.fw_code = fwCode;}}var independentParams={filterOutJoinedCourses:false};if(navigatorPrograms){independentParams.navigatorProgram = true;}_ember['default'].RSVP.hash({taxonomyGrades:component.get('taxonomyService').fetchGradesBySubject(filters),independentCourses:component.get('featuredCourseService').getIndependentCourseList(independentParams),profileInfo:component.get('profileService').readUserProfile(sessionId)}).then(function(hash){if(component.get('showUserGradeSelection')){if(!hash.profileInfo.info || hash.profileInfo.info && hash.profileInfo.info.grade === undefined){var queryParams={queryParams:{showKnowMore:true}};component.get('router').transitionTo('sign-up-finish',queryParams);}else {component.setUpInfo(hash);}}else {component.setUpInfo(hash);}});},onShowMore:function onShowMore(){event.stopPropagation();$(event.target).toggleClass('show-less');}}, // -------------------------------------------------------------------
// Methods
performanceScore:_ember['default'].computed('course',function(){var course=this.get('course.programCourse');if(course.performance && course.performance.score){return course.performance.score;}else {return 0;}}), /**
   * @function joinPublicClassbyGrade
   * Method to join public class by student
   */joinPublicClassbyGrade:function joinPublicClassbyGrade(gradeLowerBound){var _this=this;var courseId=this.get('course.programCourse.id');var featuredCourseService=this.get('featuredCourseService');return _ember['default'].RSVP.hash({joinClass:_ember['default'].RSVP.resolve(featuredCourseService.joinPublicClassbyGrade(courseId,gradeLowerBound))}).then(function(_ref){var joinClass=_ref.joinClass;_this.sendAction('updateUserClasses'); // Triggers the refresh of user classes in top header
var courseSettings=_this.get('course.programCourse.settings');if(!courseSettings.get('gradeCurrent')){_this.get('router').transitionTo('student.class.course-map',joinClass.get('classId'));}else {_this.get('router').transitionTo('student.class.proficiency',joinClass.get('classId'),{queryParams:{subProgramId:_this.get('course.programCourse.subProgramId')}});}});},getDefaultGradeLevel:function getDefaultGradeLevel(){var component=this;var tenantSetting=component.get('tenantSettingsObj');var currentCourse=component.get('course.programCourse');var taxonomyGrades=component.get('taxonomyGrades').sortBy('sequence');var userProfile=component.get('profileInfo');var courseLowerBound=undefined;var frameworkId=currentCourse.get('settings.framework');var subjectCode=currentCourse.get('subject');var defaultGradeDiff=tenantSetting.default_skyline_grade_diff_for_diagnostic_flow?tenantSetting.default_skyline_grade_diff_for_diagnostic_flow:null;var gradeDiff=tenantSetting.default_skyline_grade_diff && tenantSetting.default_skyline_grade_diff[frameworkId + '.' + subjectCode]?tenantSetting.default_skyline_grade_diff[frameworkId + '.' + subjectCode]:defaultGradeDiff;var studentSelectedGradeLevel=taxonomyGrades.find(function(taxonomyGrade){return taxonomyGrade.commonGradeLevelId.toLowerCase() === userProfile.info.grade.id.toLowerCase();}); // get course lower bound using the grade level diff from tenant settings
if(gradeDiff){(function(){var gradeLowerBoundSeq=studentSelectedGradeLevel.sequence - gradeDiff;if(gradeLowerBoundSeq >= 1){courseLowerBound = taxonomyGrades.find(function(item){return Number(item.sequence) === gradeLowerBoundSeq;});}})();}var gradeLowerBoundSeq=courseLowerBound.sequence - gradeDiff;var gradeId=undefined;if(gradeLowerBoundSeq >= 1){var activeGrade=taxonomyGrades.findBy('sequence',studentSelectedGradeLevel.sequence - gradeDiff);gradeId = activeGrade.id;}component.set('studentProgramBound',{lowerBound:gradeId,upperBound:studentSelectedGradeLevel.id});},setUpInfo:function setUpInfo(hash){var component=this;component.set('taxonomyGrades',hash.taxonomyGrades);component.set('independentCourses',hash.independentCourses);component.set('profileInfo',hash.profileInfo);component.getDefaultGradeLevel();var gradeCurrent=component.get('studentProgramBound.lowerBound');component.joinPublicClassbyGrade(gradeCurrent);component.set('isExpand',false);}});});