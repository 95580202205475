define('gooru-web/components/reports/pull-up/milestone/student-milestone-lesson-performance-accordion',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['milestone-report','student-milestone-lesson-performance-accordion'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/performance
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), /**
   * @requires service:sesion
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:api-sdk/course-map
   */courseMapService:_ember['default'].inject.service('api-sdk/course-map'), // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when toggle a lesson
onToggleLesson:function onToggleLesson(){var component=this;if(!component.get('collections.length')){component.loadCollectionsPerformance().then(function(){component.lessonAccordionToggleHandler();});}else {component.lessonAccordionToggleHandler();}}, //Action triggered when click on collection performance
onOpenCollectionReport:function onOpenCollectionReport(collection){var component=this;var collectionReportContext={userId:component.get('userId'),classId:component.get('classId'),courseId:component.get('courseId'),unitId:component.get('unitId'),lessonId:component.get('lessonId'),collectionId:collection.get('id') || collection.get('collectionId'),type:collection.get('format') || collection.get('collectionType'),lesson:component.get('lesson'),isStudent:component.get('isStudent'),isTeacher:component.get('isTeacher'),collection:collection};var reportType=collection.get('format') || collection.get('collectionType');if(reportType === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT){component.set('isShowExternalAssessmentReport',true);}else if(reportType === _gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY){collectionReportContext.performance = collection.get('performance');component.set('isShowStudentOfflineActivityReport',true);}else {component.set('isShowCollectionReport',true);}component.set('collectionReportContext',collectionReportContext);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} unitId
   */unitId:_ember['default'].computed('lesson',function(){return this.get('lesson.unit_id') || this.get('lesson.unitId');}), /**
   * @property {UUID} lessonId
   */lessonId:_ember['default'].computed('lesson',function(){return this.get('lesson.lesson_id') || this.get('lesson.lessonId');}), /**
   * @property {Array} collections
   */collections:_ember['default'].A([]), /**
   * @property {Boolean} isExpanded
   */isExpanded:false, /**
   * @property {Boolean} isRescopedLesson
   */isRescopedLesson:_ember['default'].computed('lesson.isRescopedLesson','lesson.performance',function(){var component=this;var isRescopedLesson=component.get('lesson.isRescopedLesson');var isLessonPerformed=component.get('lesson.isPerformed') || component.get('lesson.performance');return isRescopedLesson && !isLessonPerformed;}), /**
   * @property {Array} rescopedCollectionIds
   */rescopedCollectionIds:_ember['default'].A([]), /**
   * @property {Boolean} isTeacher
   */isTeacher:_ember['default'].computed.equal('session.role','teacher'), /**
   * @property {Boolean} isStudent
   */isStudent:_ember['default'].computed.not('isTeacher'), /**
   * @property {Boolean} isPrevLessonExpanded
   */isPrevLessonExpanded:_ember['default'].computed('milestoneLessons.@each.isExpaned',function(){var lessonIndex=this.get('lessonIndex');return this.get('milestoneLessons').objectAt(lessonIndex - 1)?this.get('milestoneLessons').objectAt(lessonIndex - 1).isExpaned:false;}),isUnit0Milestone:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadCollectionsPerformance
   */loadCollectionsPerformance:function loadCollectionsPerformance(){var _this=this;var component=this;component.set('isLoading',true);var lesson=component.get('lesson');return _ember['default'].RSVP.hash({lessonInfo:component.get('isRoute0MileStone') || lesson.isDiagnosticLesson || component.get('isUnit0Milestone')?component.get('lesson'):component.fetchLessonInfo(),collectionsPerformance:component.fetchMilestoneCollectionsPerformance(_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION),assessmentsPerformance:component.fetchMilestoneCollectionsPerformance(_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT)}).then(function(_ref){var lessonInfo=_ref.lessonInfo;var collectionsPerformance=_ref.collectionsPerformance;var assessmentsPerformance=_ref.assessmentsPerformance;var collections=lessonInfo?lessonInfo.get('children') || lessonInfo.get('collections'):_ember['default'].A([]);if(component.get('isRoute0MileStone') || lessonInfo.get('isDiagnosticLesson')){collections = lessonInfo.get('collections');}if(!component.isDestroyed){component.set('collections',component.parseCollectionsPerformance(collections,collectionsPerformance.concat(assessmentsPerformance)));component.handleLineBasedOnPreviousLine(_this.get('milestoneLessons'),_this.get('lesson'),_this.get('collections'));component.parseRescopedCollections(collections);component.set('isLoading',false);}});}, /**
   * @function fetchMilestoneCollectionsPerformance
   */fetchMilestoneCollectionsPerformance:function fetchMilestoneCollectionsPerformance(collectionType){var component=this;var classId=component.get('classId');var courseId=component.get('courseId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var userId=component.get('userId');var performanceService=component.get('performanceService');return performanceService.getCollectionsPerformanceByLessonId(classId,courseId,unitId,lessonId,collectionType,userId);}, /**
   * @function fetchLessonInfo
   */fetchLessonInfo:function fetchLessonInfo(){var component=this;var courseMapService=component.get('courseMapService');var classId=component.get('classId');var courseId=component.get('courseId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var userId=component.get('isTeacher')?component.get('userId'):undefined;return courseMapService.getLessonInfo(classId,courseId,unitId,lessonId,true,userId);}, /**
   * @function parseCollectionsPerformance
   */parseCollectionsPerformance:function parseCollectionsPerformance(collections,collectionsPerformance){var component=this;if(collections && !component.isDestroyed){collectionsPerformance.map(function(collectionPerformance){var collectionData=collections.findBy('id',collectionPerformance.get('collectionId'));if(collectionData){var collectionPerformanceData=collectionPerformance.get('performance');collectionData.set('performance',_ember['default'].Object.create({score:collectionPerformanceData.get('scoreInPercentage'),scoreInPercentage:collectionPerformanceData.get('scoreInPercentage'),timeSpent:collectionPerformanceData.get('timeSpent')}));collectionData.set('isAssessment',(collectionData.get('format') || collectionData.get('collectionType')) === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT || (collectionData.get('format') || collectionData.get('collectionType')) === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT);collectionData.set('isOfflineActivity',(collectionData.get('format') || collectionData.get('collectionType')) === _gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY);component.set('lesson.isPerformed',true);}});}return collections;}, /**
   * @function parseRescopedCollections
   */parseRescopedCollections:function parseRescopedCollections(collections){var component=this;var rescopedCollectionIds=component.get('rescopedCollectionIds');rescopedCollectionIds.map(function(collectionId){var collection=collections.findBy('id',collectionId);if(collection){collection.set('isRescopedCollection',true);}});}, /**
   * @function lessonAccordionToggleHandler
   */lessonAccordionToggleHandler:function lessonAccordionToggleHandler(){var component=this;if(component.get('isExpanded')){component.$('.collections-info-container').slideUp(400);}else {component.$('.collections-info-container').slideDown(400);}component.toggleProperty('isExpanded');component.set('lesson.isExpaned',component.get('isExpanded'));}, /**
   * @function handlePathLinePointers
   * Method to handle path line connector
   * TODO It's not yet integrated need to extend the support
   */handlePathLinePointers:function handlePathLinePointers(collections){var suggestedCollections=collections.filterBy('isSuggestedContent',true);if(suggestedCollections){suggestedCollections.map(function(collection){var curCollectionPos=collections.indexOf(collection);var prevCollection=collections.objectAt(curCollectionPos - 1);if(prevCollection && prevCollection.get('isSuggestedContent')){if(prevCollection.get('pathType') === collection.get('pathType')){collection.set('alignmentType','' + prevCollection.get('alignmentType'));collection.set('pathLine','identical');}else if(prevCollection.get('alignmentType') === 'center-path'){collection.set('alignmentType',collection.get('pathType') === 'teacher'?'left-path':'right-path');collection.set('pathLine',collection.get('pathType') === 'teacher'?'left-line':'right-line');}else {collection.set('alignmentType','center-path');collection.set('pathLine',collection.get('pathType') === 'teacher'?'left-line':'right-line');}}else {collection.set('alignmentType',collection.get('pathType') === 'teacher'?'left-path':'right-path');collection.set('pathLine',collection.get('pathType') === 'teacher'?'left-line':'right-line');}var nextCollection=collections.objectAt(curCollectionPos + 1);if(nextCollection && !nextCollection.get('isSuggestedContent')){nextCollection.set('alignmentType',collection.get('alignmentType') === 'left-path'?'right-path':'left-path');nextCollection.set('pathLine',collection.get('alignmentType') === 'left-path'?'right-line':'left-line');}});}return collections;}, /**
   * handle line and path for lesson and collection
   */handleLineBasedOnPreviousLine:function handleLineBasedOnPreviousLine(lessons,selectedLesson,collections){var collectionSuggestions=collections.filter(function(collection){var pathType=collection.get('pathType');return pathType === 'system' || pathType === 'teacher';});if(collectionSuggestions.length > 0){collectionSuggestions.forEach(function(collectionSuggestion){var indexOfCollection=collections.indexOf(collectionSuggestion);if(indexOfCollection === 0){selectedLesson.set('firstCollHasSuggsType',collectionSuggestion.get('pathType'));}if(collections.length === indexOfCollection + 1){var selectedLessonIndex=lessons.indexOf(selectedLesson);var nextLesson=lessons.objectAt(selectedLessonIndex + 1);if(nextLesson){nextLesson.set('prevLeCollHasSuggsType',collectionSuggestion.get('pathType'));}}var prevCollection=collections.objectAt(indexOfCollection - 1);if(prevCollection){if(prevCollection.get('pathId') > 0){collectionSuggestion.set('prevCollHasSuggsType',prevCollection.get('pathType'));}prevCollection.set('nextCollHasSuggsType',collectionSuggestion.get('pathType'));}var nextCollection=collections.objectAt(indexOfCollection + 1);if(nextCollection){if(nextCollection.get('pathId') > 0){collectionSuggestion.set('nextCollHasSuggsType',nextCollection.get('pathType'));}nextCollection.set('prevCollHasSuggsType',collectionSuggestion.get('pathType'));}});}}});});