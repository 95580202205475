define('gooru-web/components/reports/pull-up/res-player-pull-up',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ /**
   * Property will decided to show the player pull up or not
   * @type {Boolean}
   */showResPullUp:false, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;_ember['default'].$(document.body).addClass('iframe-panel');component.set('showResPullUp',true);component.openPullUp();},didReceiveAttrs:function didReceiveAttrs(){var component=this; /**
     * method used to listen the events from iframe.
     **/function receiveMessage(event){if(event.data === 'triggerclosebuttonevent'){component.set('showResPullUp',false);component.send('closePlayer');}else if(event.data === _gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_LOADING_COMPLETED){if(!component.get('isDestroyed')){component.set('isLoading',false);}}else if(event.data.message === _gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE){component.closePullUp(event.data.classId);}}window.addEventListener('message',receiveMessage,false);}, // -------------------------------------------------------------------------
// Actions
actions:{closePlayer:function closePlayer(){var component=this;var classId=component.get('classId');if(classId){component.get('router').transitionTo('student.class.course-map',classId);}else {component.closePullUp();}}}, //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.set('isOpenResourcePlayer',true);},closePullUp:function closePullUp(data){var component=this;component.set('isOpenResourcePlayer',false);}});});