define('gooru-web/components/reports/pull-up/struggling-competency-report',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ //--------------------------------------------------------
//Attributes
classNames:['pull-up','struggling-competency-report'], //--------------------------------------------------------
//Dependencies
session:_ember['default'].inject.service('session'), /**
   * @property {service} searchService
   */searchService:_ember['default'].inject.service('api-sdk/search'), /**
   * @type {ProfileService} Profile service object
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @type {libraryService} Library service object
   */libraryService:_ember['default'].inject.service('api-sdk/library'), /**
   * Struggling compentency service
   */strugglingCompetencyService:_ember['default'].inject.service('api-sdk/struggling-competency'), /**
   * @requires service:api-sdk/search
   */suggestService:_ember['default'].inject.service('api-sdk/suggest'), //--------------------------------------------------------
// Properties
/**
   * @property {String} classId
   */classId:_ember['default'].computed.alias('class.id'), /**
   * @property {Object} selectedCompetency
   * property hold selected competency
   */selectedCompetency:null, /**
   * @property {Array} studentsPerformanceList
   * property hold student performance list
   */studentsPerformanceList:[], /**
   * @property {Array} studentsPerformanceList
   * property hold student count exceed max 5
   */studentsCount:0, /**
   * @property {Array} collectionContents
   * property hold collection based on competency code
   */collectionContents:[], /**
   * @property {Array} menuItems
   * property hold menu list that are showing in dropdown
   */menuItems:[], /**
   * @property {Object} selectedMenuItem
   * property hold selected menu item
   */selectedMenuItem:null, /**
   * @property {Array} libraries
   * property hold the library content
   */libraries:[], /**
   * @property {Boolean} showTenantLibraries
   * property used to show library list in competency pullup
   */showTenantLibraries:false, /**
   * @property {Boolean} showLibraryCollection
   * property used to show library collection list in competency pullup
   */showLibraryCollection:false, /**
   * @property {Object} selectedLibrary
   */selectedLibrary:null, /**
   * @property {boolean} showSuggestConfirmation
   */showSuggestConfirmation:false, /**
   * @property {Object} suggestedCollection
   */suggestedCollection:null, /**
   * Maintains the current page number of search
   * @type {Number}
   */startAt:0, /**
   * Maintains the value of default search page size.
   * @type {Number}
   */pageSize:5, /**
   * @property {Boolean} isShowMoreButton
   */isShowMoreButton:false, /**
   * @property {Boolean} isLoading
   */isLoading:true, /**
   * Allowed filter content types
   * @type {Array}
   */filterContentType:_gooruWebConfigConfig.SUGGESTION_FILTER_BY_CONTENT_TYPES, /**
   * Maintains the state of active content type (collection/Assessment), default collection
   * @type {String}
   */activeContentType:'collection', /**
   * Maintains the selected Filters result data
   * @type {Array}
   */selectedFilters:_ember['default'].A([]), /**
   * Propery to hide the search box and selected Filters
   * @property {showLibraryFilter}
   */showLibraryFilter:false, /**
   * Propery to hide the selected Filters
   * @property {showSearchFilter}
   */showSearchFilter:false, /**
   * Maintains the when enter search box get data
   *  @type {String}
   */suggestionSearchText:'', /**
   * @property {Boolean} hasCollectionContent
   */hasCollectionContent:_ember['default'].computed('collectionContents.[]','showLibraryCollection','showTenantLibraries','isLoading',function(){var showTenantLibraries=this.get('showTenantLibraries');var showLibraryCollection=this.get('showLibraryCollection');var isLoading=this.get('isLoading');var collectionContents=this.get('collectionContents');return (!showTenantLibraries || showLibraryCollection) && !collectionContents.length && !isLoading;}), /**
   * @property {Observe} onSelectFilter
   */onSelectFilter:_ember['default'].observer('selectedFilters.[]',function(){var component=this;var selectedFilters=component.get('selectedFilters');var selectedFiltersLimit=component.get('selectedFiltersLimit');if(selectedFilters.length < selectedFiltersLimit){component.set('filters',selectedFilters);}else {component.set('filters',selectedFilters.slice(0,selectedFiltersLimit));component.set('moreFilters',selectedFilters.slice(selectedFiltersLimit,selectedFilters.length));}}), /**
   * Maintains the value of popover position
   * @type {String}
   */tagPopoverDefaultPosition:'bottom', //-------------------------------------------------------
//Actions
actions:{ //Action trigger when click student heading
showStudentList:function showStudentList(){var component=this;component.$('.sc-student-dropdown-list-container').slideToggle(500);}, // Action trigger when click close
onClosePullUp:function onClosePullUp(){var isCloseAll=arguments.length <= 0 || arguments[0] === undefined?false:arguments[0];var component=this;component.set('isShowStrugglingCompetencyReport',false);component.$().slideUp({complete:function complete(){component.sendAction('closePullUp',isCloseAll);}});}, /**
     * Action triggered when the user play collection
     * It'll open the player in new tab
     */onPlayCollection:function onPlayCollection(collection){var component=this;component.sendAction('onPreviewContent',collection);}, /**
     * Action triggered when add collection to dca.
     * @param  {Object} collection
     */onAddContentToDCA:function onAddContentToDCA(collection){var component=this;var studentList=component.get('studentsPerformanceList');component.sendAction('onAddContentToDCA',collection,studentList);}, /**
     * Action get triggered when schedule content to CA got clicked
     */onScheduleContentToDCA:function onScheduleContentToDCA(content){var studentList=this.get('studentsPerformanceList');this.sendAction('onScheduleContentToDCA',content,studentList);}, /*
     * Action triggered when click search dropdown from competency pullup
     */onSelectDropdown:function onSelectDropdown(){var component=arguments.length <= 0 || arguments[0] === undefined?this:arguments[0];component.$('.search-filter-container-list').slideToggle(500);}, /**
     * Evengt get triggered when filter by content type menu is selected
     * @param  {String} contentType
     */onSelectFilterBy:function onSelectFilterBy(contentType){this.set('activeContentType',contentType);this.set('collectionContents',[]);this.set('startAt',0);this.set('isLoading',true);this.fetchSuggestedCollection();}, /**
     * Action triggered when click menu list from dropdown
     */onSelectMenuItems:function onSelectMenuItems(item){var component=this;component.set('collectionContents',[]);var preSelectedMenu=component.get('menuItems').findBy('isSelected',true);if(preSelectedMenu){preSelectedMenu.set('isSelected',false);}item.set('isSelected',true);component.set('showTenantLibraries',false);component.set('selectedMenuItem',item);component.set('startAt',0);component.set('isShowMoreButton',false);component.set('isLoading',true);component.set('showLibraryCollection',false);component.actions.onSelectDropdown(component);component.fetchSuggestedCollection();component.set('showLibraryFilter',false);component.set('itemLabel',item.name);if(item.name === 'tenantLibrary'){component.set('showContentTypeFilter',true);}else if(item.name === 'gooruCatalog' || item.name === 'myContent' || item.name === 'suggested'){component.set('showContentTypeFilter',false);}if(item.name === 'gooruCatalog' || item.name === 'myContent'){component.set('showSearchFilter',true);}else if(item.name === 'tenantLibrary' || item.name === 'suggested'){component.set('showSearchFilter',false);}}, // Action triggered when click on library
onSelectLibrary:function onSelectLibrary(library){var component=this;component.set('selectedLibrary',library);component.set('showLibraryCollection',true);component.set('showTenantLibraries',false);component.set('showLibraryFilter',true);component.set('isLoading',true);component.set('showContentTypeFilter',false);component.fetchSuggestedCollection();}, // Action triggered when click on back button in library
backToLibrary:function backToLibrary(){this.set('showLibraryCollection',false);this.set('startAt',0);this.set('isShowMoreButton',false);this.set('showContentTypeFilter',true);this.set('showSearchFilter',false);this.set('showLibraryFilter',false);}, // Action call when click suggestion button
onSuggestContent:function onSuggestContent(collection){this.set('suggestedCollection',collection);this.set('showSuggestConfirmation',true);}, // Action trigger when click cancel button in suggest
onCancelSuggest:function onCancelSuggest(){var component=this;component.set('showSuggestConfirmation',false);}, // Action trigger when click confirm button in suggest pullup
onConfirmSuggest:function onConfirmSuggest(){var component=this;var collection=component.get('suggestedCollection');var competencyCode=component.get('selectedCompetency.code')?component.get('selectedCompetency.code'):component.get('selectedCompetency.competencyCode');component.set('showSuggestConfirmation',false);collection.set('isSuggested',true);var studentList=component.get('studentsPerformanceList');if(studentList.length){studentList.map(function(student){component.suggestContent(student.get('id')?student.get('id'):student.get('userId'),collection,component.get('activeContentType'),competencyCode);});}}, // Action trigger when click show more on competency pull up
onShowMore:function onShowMore(){var component=this;component.set('isLoading',true);component.fetchSuggestedCollection();}, /**
     * Action get triggered when filter button is clicked
     */toggleSearchFilter:function toggleSearchFilter(){var component=this;component.toggleProperty('isShow');}, /**
     * Action get triggered when search button is clicked
     */doSearch:function doSearch(){var component=this;component.set('startAt',0);component.set('collectionContents',[]);component.fetchSuggestedCollection();}, /**
     * Action get triggered when search icon is clicked
     */suggestSearchFilter:function suggestSearchFilter(){var component=this;component.set('startAt',0);var term=component.getSearchTerm();if(term.length > 0){component.set('collectionContents',[]);component.fetchSuggestedCollection();}}, /**
     * Action get triggered when enter the search box
     */filterSearch:function filterSearch(){var component=this;component.set('startAt',0);var term=component.getSearchTerm();if(term.length > 0){component.set('collectionContents',[]);component.fetchSuggestedCollection();}}, /**
     * Action get triggered when clear button is clicked
     */clearFilter:function clearFilter(item){var component=this;if(item.get('filter') === 'flt.standard'){component.set('unCheckedItem',item);}component.get('selectedFilters').removeObject(item);component.send('doSearch');}}, //---------------------------------------------------------------------
// Hooks
/**
   * Function to triggered once when the component element is first rendered.
   */didInsertElement:function didInsertElement(){var component=this;if(component.get('selectedCompetency')){component.set('collectionContents',[]);component.set('isShowMoreButton',false);var selectedCompetency=component.get('selectedCompetency');component.fetchStudentsPerfomance(selectedCompetency);component.fetchSuggestedCollection();component.openPullUp();component.createMenuItems();}component.set('activeContentType','collection');component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});component.setupTooltip();}, //---------------------------------------------------------------------
// Method
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().slideDown();}, /**
   * @function createMenuItems
   * Method used to create menu list that are show in dropdown
   */createMenuItems:function createMenuItems(){var component=this;var menuList=_ember['default'].A([_ember['default'].Object.create({label:'common.gooru-catalog',name:'gooruCatalog'}),_ember['default'].Object.create({label:'common.myContent',name:'myContent'}),_ember['default'].Object.create({label:'common.tenantLibrary',name:'tenantLibrary'}),_ember['default'].Object.create({label:'common.suggested',name:'suggested',isSelected:true})]);component.set('selectedMenuItem',menuList.findBy('isSelected',true));component.set('menuItems',menuList);}, /**
   * @function fetchStudentsPerfomance
   * Method used to fetch student performance
   */fetchStudentsPerfomance:function fetchStudentsPerfomance(selectedCompetency){var component=this;var studentPerformance=component.get('studentsPerformanceList');var params={competency:selectedCompetency.get('code'),classId:component.get('classId'),month:moment().format('MM'),year:moment().format('YYYY')};_ember['default'].RSVP.hash({studentsPerfomance:component.get('strugglingCompetencyService').fetchStudentsPerfomance(params)}).then(function(_ref){var studentsPerfomance=_ref.studentsPerfomance;if(studentsPerfomance.length){component.set('studentsPerformanceList',studentsPerfomance);}else {component.set('studentsPerformanceList',studentPerformance);}var isMobile=window.matchMedia('only screen and (max-width: 768px)');var profileCount=isMobile.matches?5:9;component.set('profileCount',profileCount);if(studentsPerfomance.length > profileCount){component.set('studentsCount',studentsPerfomance.length - profileCount);}});}, /**
   * @function fetchSuggestedCollection
   * Method used to fetch suggested collections
   */fetchSuggestedCollection:function fetchSuggestedCollection(){var component=this;_ember['default'].RSVP.hash({collections:component.getSearchServiceByType()}).then(function(_ref2){var collections=_ref2.collections;if(!component.isDestoryed){(function(){var collectionContents=component.get('collectionContents');var collectionList=collections;component.set('isShowMoreButton',false);if(collectionList.length){var startAt=component.get('startAt');component.set('startAt',startAt + 1);if(collectionList.length === 5 && component.get('selectedMenuItem').name !== 'suggested'){component.set('isShowMoreButton',true);}collectionList.map(function(collection){collectionContents.pushObject(collection);});}component.set('isLoading',false);})();}});}, /**
   * @function suggestContent
   * Method used to post suggest content
   */suggestContent:function suggestContent(userId,collection,collectionType,competencyCode){var component=this;var contextParams={user_id:userId,class_id:component.get('classId'),suggested_content_id:collection.get('id'),suggestion_origin:'teacher',suggestion_originator_id:component.get('session.userId'),suggestion_criteria:'performance',suggested_content_type:collectionType,suggested_to:'student',suggestion_area:'proficiency',tx_code:competencyCode,tx_code_type:'competency'};component.get('suggestService').suggestContent(contextParams);}, /**
   * Method is used to get search text
   */getSearchTerm:function getSearchTerm(){var component=this;var searchText=component.get('suggestionSearchText');return searchText;}, /**
   * @function getSearchServiceByType
   * Method used to show collection or assessment data based on filter
   */getSearchServiceByType:function getSearchServiceByType(){var defaultparams=arguments.length <= 0 || arguments[0] === undefined?null:arguments[0];var component=this;var item=component.get('selectedMenuItem');var params=defaultparams?defaultparams:component.getSearchParams();var isDefaultShowFW=component.get('isDefaultShowFW');var classFramework=component.get('classFramework');params.classFramework = classFramework;params.isDefaultShowFW = isDefaultShowFW;var selectedService=_ember['default'].RSVP.resolve([]);if(!item || item.name === 'suggested'){var term=component.getSearchTerm()?component.getSearchTerm():'*';var actionKey=undefined;if(component.get('activeContentType') === 'assessment'){actionKey = 'searchAssessments';}else {actionKey = 'searchCollections';}selectedService = component.get('searchService').searchCollectionsOpenAllKey(actionKey,params,term,null,true);}else if(item.name === 'gooruCatalog'){params.filters.scopeKey = 'open-all';selectedService = component.getFiltersDetails(params);}else if(item.name === 'myContent'){params.filters.scopeKey = 'my-content';params.filters['flt.publishStatus'] = 'published,unpublished';selectedService = component.getFiltersDetails(params);}else if(item.name === 'tenantLibrary'){if(component.get('showTenantLibraries')){params.filters.scopeKey = 'open-library';params.filters['flt.publishStatus'] = 'published,unpublished';params.filters.scopeTargetNames = component.get('selectedLibrary.shortName');selectedService = component.getFiltersDetails(params);}else {component.set('showTenantLibraries',true);}}return selectedService;}, /**
   * Method is used to show return search params with filter data
   */getFiltersDetails:function getFiltersDetails(params){var component=this;var activeContentType=component.get('activeContentType');var term=component.getSearchTerm()?component.getSearchTerm():'*';if(activeContentType === _gooruWebConfigConfig.CONTENT_TYPES.COLLECTION){return component.get('searchService').searchCollections(term,params);}else if(activeContentType === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT){return component.get('searchService').searchAssessments(term,params);}}, /**
   * Method is used to get search params
   */getSearchParams:function getSearchParams(){var component=this;var competencyCode=component.get('selectedCompetency.code')?component.get('selectedCompetency.code'):component.get('selectedCompetency.competencyCode');var params={taxonomies:null,page:component.get('startAt'),pageSize:component.get('pageSize')};var filters=component.filterBuilder();filters['flt.relatedGutCode'] = competencyCode;var term=component.getSearchTerm();var label=component.get('selectedMenuItem.name');if(label === 'myContent'){filters.scopeKey = 'my-content';filters['flt.publishStatus'] = 'published,unpublished';}else if(label === 'tenantLibrary'){filters.scopeKey = 'open-library';filters.scopeTargetNames = component.get('selectedTenantLibrary.shortName');filters['flt.publishStatus'] = 'published,unpublished';}else {filters.scopeKey = 'open-all';filters['flt.publishStatus'] = 'published';}var primaryLanguage=component.get('class.primaryLanguage');if(primaryLanguage){filters['flt.languageId'] = primaryLanguage;}var subject=component.get('course.subject');var competencyData=component.get('competencyData');var tags=component.get('tags');var taxonomies=null;if(tags){taxonomies = tags.map(function(tag){return tag.data.id;});}params.taxonomies = taxonomies != null && taxonomies.length > 0?taxonomies:null;var gutCode=competencyData?competencyData.get('competencyCode'):null;if(!component.get('selectedFilters').length && !term){if(subject){filters['flt.subject'] = subject;}if(gutCode){filters['flt.gutCode'] = gutCode;}}params.filters = filters;return params;}, /**
   * Method is used to build the filters
   */filterBuilder:function filterBuilder(){var component=this;var filters={};filters['flt.audience'] = component.filterSelectedItems('filter','flt.audience');filters['flt.educationalUse'] = component.filterSelectedItems('filter','flt.educational');filters['flt.language'] = component.filterSelectedItems('filter','flt.language');filters['flt.audience'] = component.filterSelectedItems('filter','flt.audience');filters['flt.standard'] = component.filterSelectedItems('filter','flt.standard');filters['flt.creator'] = component.get('selectedFilters')['flt.authorName'];return filters;}, /**
   * Method is used to filter out selected items
   */filterSelectedItems:function filterSelectedItems(keyField,keyValue){var component=this;var filterList=component.get('selectedFilters').filterBy(keyField,keyValue);var keyName=keyValue === 'flt.standard'?'id':'name';return component.toArray(filterList,keyName);}, /**
   * Method is used to join values by comma seperator
   */toArray:function toArray(filterList,key){var params=filterList.map(function(filter){return filter[key];});return params.length > 0?params.join(','):null;},setupTooltip:function setupTooltip(){var component=this;var $anchor=this.$('.lo-content');var isMobile=window.matchMedia('only screen and (max-width: 768px)');var tagPopoverDefaultPosition=this.get('tagPopoverDefaultPosition');$anchor.attr('data-html','true');$anchor.popover({placement:tagPopoverDefaultPosition,content:function content(){return component.$('.tag-tooltip').html();},trigger:'manual',container:'body'});if(isMobile.matches){$anchor.on('click',function(){var $this=$(this);if(!$this.hasClass('list-open')){ // Close all tag tooltips by simulating a click on them
$('.struggling-competency-report > .content.list-open').click();$this.addClass('list-open').popover('show');}else {$this.removeClass('list-open').popover('hide');}});}else {$anchor.on('mouseenter',function(){$(this).popover('show');});$anchor.on('mouseleave',function(){$(this).popover('hide');});}}});});