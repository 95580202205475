define('gooru-web/components/gru-html-editor',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-html-editor'],textData:null,description:'',editorContent:null,session:_ember['default'].inject.service(),neonRteNodeId:'html-resource-editor',neonRteSunEditorNodeCssSelector:'.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable.neon-rte', // --------------------------------------------------------------------
// Hooks
didRender:function didRender(){ // neon-rte properties such as modelValue do not work since the flow of
// execution between neon-rte and ember is very different.
this.initializeNeonRte();}, // --------------------------------------------------------------------
// Methods
getNeonRte:function getNeonRte(){return document.getElementById(this.get('neonRteNodeId'));},getNeonRteSunEditor:function getNeonRteSunEditor(){var neonRte=this.getNeonRte();return neonRte?neonRte.querySelector(this.get('neonRteSunEditorNodeCssSelector')):null;},setNeonRteContent:function setNeonRteContent(){this.getNeonRteSunEditor().innerHTML = this.get('description');},initializeNeonRte:function initializeNeonRte(){var neonRteSunEditorNode=this.getNeonRteSunEditor();if(this.getNeonRte() && neonRteSunEditorNode){this.setNeonRteContent(); // By default sun editor (neon-rte uses suneditor) sets inline
// height of the editor to 250px, we want to remove that, so that the
// editor takes the height of the parent element i.e., 100%
neonRteSunEditorNode.style.height = ''; // Sun editor persists to keep contentEditable=true even if keep
// is-ready-only=true in the neon-rte tag
neonRteSunEditorNode.removeAttribute('contentEditable');}else {_ember['default'].Logger.error('Neon RTE or neon sun editor is not found');}}});});