define('gooru-web/controllers/application',['exports','ember','gooru-web/mixins/configuration','gooru-web/mixins/modal','gooru-web/config/environment','gooru-web/config/config','gooru-web/mixins/ui-helper-mixin','gooru-web/utils/endpoint-config','gooru-web/mixins/note-tool-mixin','gooru-web/utils/gainsight-initialization'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebMixinsModal,_gooruWebConfigEnvironment,_gooruWebConfigConfig,_gooruWebMixinsUiHelperMixin,_gooruWebUtilsEndpointConfig,_gooruWebMixinsNoteToolMixin,_gooruWebUtilsGainsightInitialization){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsConfiguration['default'],_gooruWebMixinsModal['default'],_gooruWebMixinsUiHelperMixin['default'],_gooruWebMixinsNoteToolMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
     * @requires service:session
     */session:_ember['default'].inject.service('session'), /**
     * @type {ProfileService} Service to retrieve profile information
     */profileService:_ember['default'].inject.service('api-sdk/profile'),classService:_ember['default'].inject.service('api-sdk/class'), /**
     * @property {TenantService}
     */tenantService:_ember['default'].inject.service('api-sdk/tenant'), /**
     * @property {HelpService}
     */helpService:_ember['default'].inject.service('api-sdk/help'),i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
queryParams:['themeId','lang'], /**
     * @property {string} application theme
     */themeId:null, /**
     * @property {string} application locale
     */lang:null, /**
     * @property {Tenant} tenant
     */tenant:null, /**
     * Maintain the state of redirection completed or not
     * @property {Boolean}
     */isRedirectionDomainDone:false,device_language_key:'deviceLanguage',init:function init(){this.get('tenantService').getActiveTenantSettingFromApi().then(function(tenantSettings){if(tenantSettings && tenantSettings.is_gainsight_enabled === 'true'){(0,_gooruWebUtilsGainsightInitialization.setGainsightIdentity)();}});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
       * Action triggered after a user has signed in
       * @see sign-in.hbs
       */signIn:function signIn(){return true;}, /**
       * Action triggered when logging out
       */logout:function logout(){return true;}, /**
       * Action triggered closed help pull up
       */closeHelpPullUp:function closeHelpPullUp(){var controller=this;$('.helpPullUp').css('display','none');$('.gruPullUp').css('right','');$('body').removeClass('gruHelpPullup');controller.set('isShowHelpPullUp',false);var dataModel=controller.get('helpInfoDescription');if(dataModel){dataModel = '';}controller.set('helpInfoDescription',dataModel);}, /**
       * Action triggered clossing note
       */onCloseNote:function onCloseNote(){$('#note-tool-div').hide();}}, // -------------------------------------------------------------------------
// Properties
/**
     * @property {GruTheme} application theme
     */theme:null, /**
     * @property {ClassesModel} list of user classes
     */myClasses:null, /**
     * @property {Profile}
     */profile:null, /**
     * @property {Boolean} isShowHelpPullUp
     */isShowHelpPullUp:false,castEventService:_ember['default'].inject.service('api-sdk/cast-event/cast-event'),facetPayload:null, // -------------------------------------------------------------------------
// Methods
loadUserClasses:function loadUserClasses(){var controller=this;var profile=controller.get('profile');var profilePromise=profile?_ember['default'].RSVP.resolve(profile):controller.get('profileService').readUserProfile(controller.get('session.userId'));return profilePromise.then(function(userProfile){controller.set('profile',userProfile);controller.setLibraryVisibility();controller.setAskConsentFromUser();return controller.get('classService').findMyClasses(userProfile).then(function(classes){controller.set('myClasses',classes);var useLearnData=userProfile.get('use_learning_data');if(controller.get('askConsentFromUser') && (useLearnData === null || useLearnData === undefined)){var model={onUpdateProfile:function onUpdateProfile(isAgree){userProfile.set('use_learning_data',isAgree);return new _ember['default'].RSVP.Promise(function(resolve,reject){controller.get('profileService').updateMyProfile(userProfile).then(function(){resolve();},reject);});}};controller.actions.showModal.call(controller,'modals.gru-user-consent-modal',model);}return classes;});});}, /**
     * Reload Session Profile Data
     */loadSessionProfile:function loadSessionProfile(profile){var controller=this;var sessionId=controller.get('session.userId');var profilePromise=profile?_ember['default'].RSVP.resolve(profile):controller.get('profileService').readUserProfile(sessionId);return profilePromise.then(function(userProfile){controller.set('profile',userProfile);controller.set('profile.isStudent',controller.get('session.role') === 'student');controller.set('profile.isTeacher',controller.get('session.role') === 'teacher');});}, /**
     * Setups the tenant information
     * @returns {Promise.<Tenant>}
     */setupTenant:function setupTenant(){var controller=this;var tenantService=controller.get('tenantService');return tenantService.findTenantFromCurrentSession().then(function(tenant){controller.set('tenant',tenant);return tenant;});},loadTranslationLabels:function loadTranslationLabels(lang){var _this=this;var controller=this;var defaultLanguage=_gooruWebUtilsEndpointConfig['default'].getLanguageSettingdefaultLang();var whichLocalSet=controller.getLocalStorage().getItem(controller.get('device_language_key'));controller.get('tenantService').getActiveTenantSetting().then(function(tenantSetting){var tenantDefaultLanguage=tenantSetting && tenantSetting.translation_setting && tenantSetting.translation_setting.language?tenantSetting.translation_setting.language:null;var applicationName=tenantSetting && tenantSetting.ui_element_visibility_settings && tenantSetting.ui_element_visibility_settings.application_name?tenantSetting.ui_element_visibility_settings.application_name:null;if(applicationName){document.title = applicationName;} //Prefer param over local set language, use case: coming from welcome page with language selection should set that language
whichLocalSet = lang || tenantDefaultLanguage || whichLocalSet;_this.setHTMLLang(whichLocalSet);controller.get('profileService').loadScript(whichLocalSet).then(function(response){var defaultLang=whichLocalSet && whichLocalSet !== 'null' && whichLocalSet !== null?whichLocalSet:defaultLanguage;controller.get('i18n').addTranslations(defaultLang,response);controller.setupDefaultLanguage(defaultLang);controller.getLocalStorage().setItem(controller.get('device_language_key'),defaultLang);});});}, /**
     * Setups the application default language
     * @param {lang}  // default language to use
     */setupDefaultLanguage:function setupDefaultLanguage(lang){if(lang !== undefined){this.set('i18n.locale',lang);if(lang === 'ar'){var rootElement=_ember['default'].$(_gooruWebConfigEnvironment['default'].rootElement);rootElement.addClass('changeDir');rootElement.removeClass('changeDirDefault');}else {var rootElement=_ember['default'].$(_gooruWebConfigEnvironment['default'].rootElement);rootElement.removeClass('changeDir');rootElement.addClass('changeDirDefault');}}},setLibraryVisibility:function setLibraryVisibility(){var controller=this;var profile=controller.get('profile');controller.get('tenantService').getActiveTenantSetting().then(function(tenantSettings){var showLibrary=true;if(profile && profile.get('isStudent') && tenantSettings && tenantSettings.library_visibility){showLibrary = tenantSettings.library_visibility.hide_student_library_view !== true;}controller.set('showLibrary',showLibrary);});}, /**
     * Decide  to show the consent based on the tenant settings
     */setAskConsentFromUser:function setAskConsentFromUser(){var controller=this;controller.get('tenantService').getActiveTenantSetting().then(function(tenantSettings){controller.set('askConsentFromUser',tenantSettings && tenantSettings.ask_consent_from_user === 'on');});}, /**
     * @function initalizeExternalTools
     * Method to initialize external tools to support the app
     */initalizeExternalTools:function initalizeExternalTools(){var controller=this; // Initialize tool scripts
if(!controller.get('session.data.authenticated.isAnonymous')){controller.get('tenantService').getActiveTenantSetting().then(function(tenantSetting){if(tenantSetting && tenantSetting.enable_serp_questions === 'on'){var tools=[_gooruWebConfigConfig.SERP_TOOL_PATH];controller.toolScriptInitializer(tools);}});}},showhelpPullUp:function showhelpPullUp(helpInfo,helpInfoDescription){var controller=this;controller.set('isShowHelpPullUp',true);controller.set('helpInfo',helpInfo);controller.set('helpInfoDescription',helpInfoDescription);if(helpInfoDescription){var strings=['docs.google.com','.pdf'];var hasDocuments=strings.some(function(term){return helpInfoDescription.description.includes(term);});controller.set('hasDocument',hasDocuments);}}, /**
     * @function toolScriptInitializer
     * @param {Array} libs
     * Method to initialize tool script/style
     */toolScriptInitializer:function toolScriptInitializer(libs){var controller=this;var version=new Date().getTime();libs.forEach(function(lib){controller.injectScript(lib.JS,version);controller.injectStyle(lib.CSS,version);});}, /**
     * @function injectScript
     * @param {String} toolScriptPath
     * @param {Number} version
     * @return {Object} scriptElement
     * Method to inject script into dom
     */injectScript:function injectScript(toolScriptPath,version){var script=document.createElement('script');script.type = 'text/javascript';script.src = window.location.origin + toolScriptPath + '?v=' + version;document.getElementsByTagName('head')[0].appendChild(script);return script;}, /**
     * @function injectStyle
     * @param {String} toolStylePath
     * @param {Number} version
     * Method to inject style into dom
     */injectStyle:function injectStyle(toolStylePath,version){var style=document.createElement('link');style.rel = 'stylesheet';style.type = 'text/css';style.media = 'screen';style.href = window.location.origin + toolStylePath + '?v=' + version;document.getElementsByTagName('head')[0].appendChild(style);}});});