define('gooru-web/components/reports/pull-up/unit-report-listview',['exports','ember','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','pull-up-unit-report-listview'], // -------------------------------------------------------------------------
// Properties
/**
   * List of contents associated with unit
   * @type {Array}
   */contents:_ember['default'].A(), /**
   * Students report data
   * @type {Array}
   */studentReportData:_ember['default'].A(), /**
   * Maintain the status of sort by firstName
   * @type {String}
   */sortByFirstnameEnabled:false, /**
   * Maintain the status of sort by lastName
   * @type {String}
   */sortByLastnameEnabled:true, /**
   * Maintain the status of sort by score
   * @type {String}
   */sortByScoreEnabled:false, // -------------------------------------------------------------------------
// Actions
actions:{sortByFirstName:function sortByFirstName(){var component=this;component.toggleProperty('sortByFirstnameEnabled');if(component.get('sortByFirstnameEnabled')){component.set('studentReportData',component.get('studentReportData').sortBy('firstName'));}else {component.set('studentReportData',component.get('studentReportData').sortBy('firstName').reverse());}},sortByLastName:function sortByLastName(){var component=this;component.toggleProperty('sortByLastnameEnabled');if(component.get('sortByLastnameEnabled')){component.set('studentReportData',component.get('studentReportData').sortBy('lastName'));}else {component.set('studentReportData',component.get('studentReportData').sortBy('lastName').reverse());}},sortByScore:function sortByScore(){var component=this;component.toggleProperty('sortByScoreEnabled');var studentReportData=undefined;if(component.get('sortByScoreEnabled')){studentReportData = component.get('studentReportData').sortBy('score-use-for-sort').reverse();}else {studentReportData = component.get('studentReportData').sortBy('score-use-for-sort');}component.set('studentReportData',studentReportData);},openLessonReport:function openLessonReport(lesson,lessons){this.sendAction('openLessonReport',lesson,lessons);},openStudentUnitReport:function openStudentUnitReport(userId){this.sendAction('openStudentUnitReport',userId);},onClickScrollLeftArrow:function onClickScrollLeftArrow(){var component=this;var scrollLeft=component.$('#table-right-01').scrollLeft() - 400;component.$('#table-right-01').animate({scrollLeft:scrollLeft + 'px'},400);},onClickScrollRightArrow:function onClickScrollRightArrow(){var component=this;var scrollLeft=component.$('#table-right-01').scrollLeft() + 400;component.$('#table-right-01').animate({scrollLeft:scrollLeft + 'px'},400);}}});});