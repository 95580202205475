define('gooru-web/components/content/collections/gru-collection-edit',['exports','ember','gooru-web/mixins/content/edit','gooru-web/mixins/modal','gooru-web/models/content/collection','gooru-web/config/config','gooru-web/utils/utils','gooru-web/mixins/configuration','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsContentEdit,_gooruWebMixinsModal,_gooruWebModelsContentCollection,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebMixinsConfiguration,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsContentEdit['default'],_gooruWebMixinsModal['default'],_gooruWebMixinsConfiguration['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
     * @requires service:notifications
     */notifications:_ember['default'].inject.service(), /**
     * @requires service:api-sdk/course
     */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
     * @property {MediaService} Media service API SDK
     */mediaService:_ember['default'].inject.service('api-sdk/media'), /**
     * @property {Service} I18N service
     */i18n:_ember['default'].inject.service(), /**
     * @type {SessionService} Service to retrieve session information
     */session:_ember['default'].inject.service('session'),fluencyService:_ember['default'].inject.service('api-sdk/fluency'),tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','collections','gru-collection-edit'],tagName:'article',isShowFluency:false,isShowFacets:true,selectedCatalog:_ember['default'].computed('collection',function(){return this.get('collection.product_id');}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
       * Edit Content
       */editContent:function editContent(){var collectionForEditing=this.get('collection').copy();this.set('tempCollection',collectionForEditing);this.set('isEditing',true);}, /**
       * Save Content
       */updateContent:function updateContent(){var component=this;var editedCollection=component.get('tempCollection');var collection=component.get('collection');var etlHrs=editedCollection.get('hours');var etlMins=editedCollection.get('minutes');(0,_gooruWebUtilsUtils.etlSecCalculation)(editedCollection,etlHrs,etlMins);editedCollection.validate().then((function(_ref){var validations=_ref.validations;if(validations.get('isValid')){var imageIdPromise=new _ember['default'].RSVP.resolve(editedCollection.get('thumbnailUrl'));if(editedCollection.get('thumbnailUrl') && editedCollection.get('thumbnailUrl') !== collection.get('thumbnailUrl')){imageIdPromise = component.get('mediaService').uploadContentFile(editedCollection.get('thumbnailUrl'));}imageIdPromise.then(function(imageId){editedCollection.set('thumbnailUrl',imageId);component.get('collectionService').updateCollection(editedCollection.get('id'),editedCollection).then(function(){collection.merge(editedCollection,['title','learningObjectives','thumbnailUrl','hours','minutes','primaryLanguage','metadata']);component.set('isEditing',false);if(component.get('tempCollection.metadata.fluency')){var fluData=_ember['default'].Object.create({});fluData.fluency = collection.metadata.fluency;component.get('fluencyService').updateFluencyLevel(fluData,'collections',collection.id);}})['catch'](function(error){var message=component.get('i18n').t('common.errors.collection-not-updated').string;component.get('notifications').error(message);_ember['default'].Logger.error(error);});});}this.set('didValidate',true);}).bind(this));}, /**
       * Save setting for visibility of collection in profile
       */publishToProfile:function publishToProfile(){var collectionForEditing=this.get('collection').copy();this.set('tempCollection',collectionForEditing);this.actions.updateContent.call(this);}, /**
       * Delete collection
       */deleteItem:function deleteItem(){var myId=this.get('session.userId');var model={content:this.get('collection'),isHeaderDelete:true,parentName:this.get('course.title'),deleteMethod:(function(){return this.get('collectionService').deleteCollection(this.get('collection.id'));}).bind(this),type:_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION,redirect:{route:'library-search',params:{profileId:myId,type:'my-content'}}};this.actions.showModal.call(this,'content.modals.gru-delete-content',model,null,null,null,false);},selectedFluency:function selectedFluency(fluencyData){this.set('tempCollection.metadata.fluency',fluencyData);}, //Action triggered when click back
onClickBack:function onClickBack(){var isPreview=this.get('isPreviewReferrer');var router=this.get('router');var isShowFacets=this.get('isShowFacets');var previewRoute=isShowFacets?'facets-search':'library-search';var myId=this.get('session.userId');var queryParams={profileId:myId,type:'my-content',activeContentType:'collection'};if(isPreview){router.transitionTo(previewRoute,{queryParams:queryParams});}else {router.transitionTo(previewRoute);window.history.back();}},cancelEdit:function cancelEdit(){var collectionForCancelEditing=this.get('collection').copy();this.set('isEditing',false);this.set('tempCollection',collectionForCancelEditing);}}, // -------------------------------------------------------------------------
// Properties
getFluenciesData:function getFluenciesData(fluData){var _this=this;this.get('fluencyService').getFluencyLevel(fluData).then(function(res){if(res.fluency.length){_this.set('fluencyData',res);_this.set('isShowFluency',true);}});},init:function init(){this._super.apply(this,arguments);var collection=this.get('tempCollection');if(!collection){collection = _gooruWebModelsContentCollection['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null,audience:[]});}this.set('tempCollection',collection);var catalogWithSharingAllowed=JSON.parse(localStorage.getItem('catalog_with_sharing_allowed'));var catalogList=_ember['default'].A([]);catalogList.pushObject(_ember['default'].Object.create({id:null,name:'My Content'}));catalogWithSharingAllowed.forEach(function(item){return catalogList.pushObject(_ember['default'].Object.create(item));});this.set('catalogList',catalogList);}, /**
     * Collection model as instantiated by the route. This is the model used when not editing
     * or after any collection changes have been saved.
     * @property {Collection}
     */collection:null, /**
     * Course model as instantiated by the route. This is the course that have the assigned collection
     * @property {Course}
     */course:null, /**
     * Copy of the collection model used for editing.
     * @property {Collection}
     */tempCollection:null, /**
     * @property {boolean}
     */standardLabel:true, /**
     * Indicate if the button "Back to course" is available.
     */allowBack:_ember['default'].computed('collection',function(){return this.get('collection.courseId');}),isCheckFluency:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());return tenantSettings && tenantSettings.fluency_level === 'on';})});});