define('gooru-web/models/content/resource',['exports','ember','gooru-web/models/resource/resource','gooru-web/utils/taxonomy','gooru-web/validations/edit-resource'],function(exports,_ember,_gooruWebModelsResourceResource,_gooruWebUtilsTaxonomy,_gooruWebValidationsEditResource){ /**
 * Resource model
 *
 * @typedef {Object} Resource
 */var ResourceModel=_ember['default'].Object.extend({ /**
   * @property {Number} id
   */id:0, /**
   * @property {Number} assessmentId - if has a linked assessment in the study player
   */assessmentId:0, /**
   * @property {String} url
   */url:null,htmlContent:null, /**
   * @property {String} assetUrl
   * TODO: Remove this once API 3.0 integration is completed
   */assetUrl:_ember['default'].computed.alias('url'), /**
   * @property {String} thumbnailUrl
   */thumbnailUrl:null, /**
   * @property {Boolean} isEditing
   */isEditing:false, /**
   * @property {string}
   */format:null, /**
   * @property {string}
   */title:null, /**
   * @property {string}
   */description:null, /**
   * @property {string}
   */narration:null, /**
   * @property {string} published|unpublished|requested
   */publishStatus:null, /**
   * @property { Content/User }
   */owner:null, /**
   * The original creator
   * @property { Content/User }
   */creator:null, /**
   * When the owner and the creator are the same
   * @property {boolean}
   */sameOwnerAndCreator:_ember['default'].computed.equal('owner.id','creator.id'), /**
   * @property {Boolean} isPublic
   */isPublished:_ember['default'].computed.equal('publishStatus','published'), /**
   * @property {Boolean} isVisibleOnProfile - Indicates if the Question is visible on Profile. By default it is false
   */isVisibleOnProfile:false, /**
   * @property {TaxonomyTagData[]} an array with Taxonomy data
   */standards:[], /**
   * @property {Number[]} Array with the centurySkills ids
   */centurySkills:[], /**
   * @property {Number[]} Array with the free form tag
   */freeFormTag:[], /**
   * Some metadata properties
   * @property {{}}
   */info:null, /**
   * @property facet Attributes object
   */facetAttributes:{}, /**
   * @property {string}
   */publisher:null, /**
   * @property {boolean}
   */amIThePublisher:false, /**
   * Sequence number, when is part of a collection
   * @property {number}
   */order:null, /**
   * @property {{}}
   */displayGuide:null, /**
   * @property {String} category - Category the course belongs to
   */category:_ember['default'].computed('subject',function(){var subjectId=this.get('subject');if(subjectId){return (0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectId);}}), /**
   * @property {String} Taxonomy primary subject ID
   */subject:'',product:null, /**
   * @property {String} Indicates the resource type. i.e video/youtube, assessment-question, image/png
   */resourceType:_ember['default'].computed('format',function(){var format=this.get('format');var resourceUrl=this.get('url');var youtubePattern=/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;var vimeoPattern=/(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/;var pdfPattern=/.*\.pdf/;var resourceType='resource/url'; // Default type
if(format === 'html'){resourceType = 'resource/html';}if(resourceUrl){if(youtubePattern.test(resourceUrl)){return 'video/youtube';}if(vimeoPattern.test(resourceUrl)){return 'vimeo/video';}switch(format){case 'audio':case 'interactive':case 'webpage':case 'video':resourceType = 'resource/url'; // Default type
break;case 'image':resourceType = pdfPattern.test(resourceUrl)?'handouts':'image';break;case 'text':resourceType = 'handouts';break;default:resourceType = 'resource/url'; // Default type
}}return resourceType;}), /**
   * Indicates if it is an image resource
   * @property {boolean}
   */isImageResource:_ember['default'].computed('resourceType',function(){var resourceType=this.get('resourceType');return resourceType && resourceType.indexOf('image') >= 0;}), /**
   * Indicates if it is an youtube resource
   * @property {boolean}
   */isYoutubeResource:_ember['default'].computed.equal('resourceType','video/youtube'), /**
   * Indicates if it is an pdf resource
   * @property {boolean}
   */isPDFResource:_ember['default'].computed.equal('resourceType','handouts'), /**
   * Indicates if it is an url resource
   * @property {boolean}
   */isUrlResource:_ember['default'].computed.equal('resourceType','resource/url'),playerMetadata:_ember['default'].A([]), /**
   * Indicates if it is an vimeo resource
   * @property {boolean}
   */isVimeoResource:_ember['default'].computed.equal('resourceType','vimeo/video'), /**
   * Indicates if the resources is a 3rd party/remote url
   * Uploaded resources are not considered remote
   * @property {boolean}
   */isRemote:true, /**
   * Return a copy of the resource
   *
   * @function
   * @return {Resource}
   */copy:function copy(){var properties=this.getProperties(this.modelProperties());var standards=this.get('standards');var info=this.get('info');var centurySkills=this.get('centurySkills');var freeFormTag=this.get('freeFormTag');var facetAttributes=this.get('facetAttributes'); // Copy standards and info values
properties.standards = standards.slice(0);properties.info = JSON.parse(JSON.stringify(info));properties.centurySkills = centurySkills.slice(0);properties.freeFormTag = freeFormTag.slice(0);properties.facetAttributes = facetAttributes;var ResourceValidation=ResourceModel.extend(_gooruWebValidationsEditResource['default']);return ResourceValidation.create(_ember['default'].getOwner(this).ownerInjection(),properties);}, /**
   * Copy a list of property values from another model to override the current ones
   *
   * @function
   * @param {Resource} model
   * @param {String[]} propertyList
   * @return {null}
   */merge:function merge(model){var propertyList=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var properties=model.getProperties(propertyList);this.setProperties(properties);}, /**
   * Return a list of properties
   *
   * @function
   * @return {Array}
   */modelProperties:function modelProperties(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key));if(value === 'string' || value === 'number' || value === 'boolean'){properties.push(key);}}return properties;}, /**
   * Returns a player resource
   * @return {Resource}
   */toPlayerResource:function toPlayerResource(){var model=this;return _gooruWebModelsResourceResource['default'].create({id:model.get('id'),order:model.get('order'),owner:model.get('owner'),title:model.get('title'),resourceType:model.get('resourceType'),htmlContent:model.get('htmlContent'),resourceFormat:model.get('format'),format:model.get('format'),description:model.get('description'),thumbnail:model.get('thumbnailUrl'),assetUrl:model.get('assetUrl'),url:model.get('url'),displayGuide:model.get('displayGuide'),narration:model.get('narration'), //TODO missing
options:null, //TODO missing
taxonomy:model.get('standards'),isRemote:model.get('isRemote'),playerMetadata:model.get('playerMetadata')});}, /**
   * Sets the subject of the course
   *
   * @function
   * @param {TaxonomyRoot} taxonomySubject
   */setTaxonomySubject:function setTaxonomySubject(taxonomySubject){if(!(this.get('isDestroyed') || this.get('isDestroying'))){this.set('subject',taxonomySubject?taxonomySubject.get('id'):null);}}});ResourceModel.reopenClass({ /**
   * Serializes the resource format to be API compliant
   * @param format
   * @returns {string}
   * TODO move to util
   */serializeResourceFormat:function serializeResourceFormat(format){var interactiveFormat=['h5p_interactive_video','h5p_interactive_slide','h5p_interactive_personality_quiz','h5p_drag_and_drop_resource']; //Added support for additional formats
var additionalFormat=['lesson_plan','instructional_strategy','file_download','presentation'];return interactiveFormat.includes(format) || additionalFormat.includes(format)?'' + format:format?format + '_resource':undefined;}, /**
   * Converts several app format values to api values
   * @param {string[]} values values to format
   * TODO move to util
   */serializeAllResourceFormat:function serializeAllResourceFormat(values){var model=this;return values.map(function(format){return model.serializeResourceFormat(format);});}, /**
   * Normalizes the resource format to be App compliant
   * @param format
   * @returns {string}
   * TODO move to util
   */normalizeResourceFormat:function normalizeResourceFormat(format){return format?format.split('_')[0]:undefined; // i.e video_resource to video
}});exports['default'] = ResourceModel;});