define('gooru-web/controllers/library',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {libraryService} Library service object
   */libraryService:_ember['default'].inject.service('api-sdk/library'), /**
   * @type {learningToolService}
   */learningToolService:_ember['default'].inject.service('api-sdk/learning-tools'), // -------------------------------------------------------------------------
// Attributes
queryParams:['isDeepLink'], // -------------------------------------------------------------------------
// Properties
/**
   * Maintains the current page number of search
   * @type {Number}
   */page:0, /**
   * Maintains the value of default search page size.
   * @type {Number}
   */defaultPageSize:10, /**
   * Maintains the value of default false.
   * @type {boolean}
   */noMoreDataAvailable:false,actions:{getMoreProduct:function getMoreProduct(){var controller=this;if(!controller.get('noMoreDataAvailable')){var offset=controller.get('page') + controller.get('defaultPageSize');controller.set('page',offset);return _ember['default'].RSVP.hash({product:controller.fetchProductList()}).then(function(_ref){var product=_ref.product;if(!controller.isDestroyed){if(product.length < 1){controller.set('noMoreDataAvailable',true);}var products=controller.get('products');controller.set('products',products.concat(product));}});}}}, //--------------------------------------------------------------------------
//Methods
init:function init(){var controller=this;controller.resetProperties();return _ember['default'].RSVP.hash({productIds:!controller.get('session.isAnonymous')?controller.getProductIds():[],libraries:!controller.get('isShowProductList') || [],products:controller.get('isShowProductList') && !controller.get('session.isAnonymous')?controller.fetchProductList():[]}).then(function(_ref2){var productIds=_ref2.productIds;var libraries=_ref2.libraries;var products=_ref2.products;if(!controller.isDestroyed){controller.set('productIds',productIds);var _localStorage=window.localStorage;var itemId='product_ids';_localStorage.setItem(itemId,JSON.stringify(productIds));controller.set('products',products);controller.set('libraries',libraries);}});},getProductIds:function getProductIds(){var controller=this;return controller.get('learningToolService').getProductIds(_gooruWebConfigConfig.PRODUCT_REGISTRY_TYPE.CONTENT_REGISTRY);},fetchProductList:function fetchProductList(){var controller=this;var params={page:controller.get('page'),pageSize:controller.get('defaultPageSize'),registryType:_gooruWebConfigConfig.PRODUCT_REGISTRY_TYPE.CONTENT_REGISTRY};var term='';return controller.get('learningToolService').fetchProductList(term,params);}, /**
   * Method is used to reset properties
   */resetProperties:function resetProperties(){var controller=this;controller.set('noMoreDataAvailable',false);controller.set('page',0);}});});