define('gooru-web/components/student/class/dashboard/activities-reminders',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // ----------------------------------------------------
// Attributes
classNames:['activities-reminders'], // ---------------------------------------------------------
// Dependencies
classActivityService:_ember['default'].inject.service('api-sdk/class-activity'),dashboardService:_ember['default'].inject.service('api-sdk/dashboard'),session:_ember['default'].inject.service(), // -------------------------------------------------------
// Properties
currentClass:null,activityContent:_ember['default'].A([]),userId:_ember['default'].computed.alias('session.userId'),isLoading:false,pendingCaCount:0,refreshData:false,watchRefreshData:_ember['default'].observer('refreshData',function(){if(this.get('refreshData')){this.initializeLoad();this.set('refreshData',false);}}),isShowImpersonate:_ember['default'].computed(function(){var impersonate=undefined;if(window.frameElement){impersonate = window.parent.impersonate;}return impersonate;}), // ------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.initializeLoad();}, // -----------------------------------------------------
// Actions
actions:{onPlayContent:function onPlayContent(classActivity){var component=this;var content=classActivity.get('collection');var contentId=content.get('id');var collectionType=content.get('collectionType');var classData=component.get('currentClass');var classId=classData.get('id');var caContentId=classActivity.get('id');var queryParams={collectionId:content.get('id'),classId:classId,role:'student',source:classActivity && classActivity.isDiagnostic?_gooruWebConfigConfig.DIAGNOSTIC_CONTENT_SOURCE.CA_DIAGNOSTIC:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS,type:collectionType,caContentId:caContentId,isIframeMode:true};if(collectionType === 'assessment-external' || collectionType === 'collection-external'){var playerUrl=component.get('router').generate('player-external',{queryParams:queryParams});component.sendAction('playContent',playerUrl,content);}else if(collectionType === 'offline-activity'){queryParams.offlineActivityId = contentId;var playerUrl=component.get('router').generate('player-offline-activity',contentId,{queryParams:queryParams});component.sendAction('playContent',playerUrl,content);}else {var playerUrl=component.get('router').generate('player',contentId,{queryParams:queryParams});component.sendAction('playContent',playerUrl,content);}},onClassActivity:function onClassActivity(){this.get('router').transitionTo('student.class.class-activities');}}, // --------------------------------------------------------
// Methods
initializeLoad:function initializeLoad(){var _this=this;var component=this;component.set('isLoading',true);var classData=component.get('currentClass');var classId=classData.get('id');var params={classId:classId};component.get('dashboardService').fetchRemindersList(params).then(function(content){content.results.map(function(data){var isFutureDate=_this.checkActivityDate(data);data.isActivityFuture = isFutureDate;});component.set('activityContent',content.results);component.set('pendingCaCount',content.pendingCaCount);component.set('isLoading',false);});},checkActivityDate:function checkActivityDate(data){var activityDate=data.activation_date;var currentDate=moment().format('YYYY-MM-DD');return moment(activityDate).isAfter(currentDate);}});});