define('gooru-web/components/portfolio/gru-content-filters',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['portfolio','gru-content-filters'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.reloadFilters();component.set('isReloadFilters',!!component.get('activeFiltersList.length'));}, // -------------------------------------------------------------------------
// Events
actions:{ //Action triggered when choosing a date range
onSelectDateRange:function onSelectDateRange(startDate,endDate){var component=this;var appliedFilters=component.get('appliedFilters');appliedFilters.startDate = moment(startDate).format('YYYY-MM-DD');appliedFilters.endDate = moment(endDate).format('YYYY-MM-DD');component.set('appliedFilters',appliedFilters);component.set('daterange',_ember['default'].Object.create({startDate:startDate,endDate:endDate}));var daterangeFilterObject=_ember['default'].Object.create({type:'daterange',value:appliedFilters.startDate + ' - ' + appliedFilters.endDate,displayValue:appliedFilters.startDate + ' - ' + appliedFilters.endDate});component.actions.onSelectFilterItem(daterangeFilterObject,component);}, //Action triggered when toggle date range picker
onShowDateRangeSelector:function onShowDateRangeSelector(){var component=this;component.toggleProperty('isShowDateRangeSelector');}, //Action triggered when close date range picker
onCloseDatePicker:function onCloseDatePicker(){var component=this;component.toggleProperty('isShowDateRangeSelector');}, //Action triggered when apply selected filters to portfolio
applyFilters:function applyFilters(){var component=this;var appliedFilters=component.get('appliedFilters');appliedFilters = Object.assign(appliedFilters,component.get('gutFilters'));component.sendAction('refreshFilters',appliedFilters);}, //Action triggered when selecting a filter item
onSelectFilterItem:function onSelectFilterItem(filterObject){var component=arguments.length <= 1 || arguments[1] === undefined?this:arguments[1];var activeFiltersList=component.get('activeFiltersList');var existingFilterObject=activeFiltersList.findBy('type',filterObject.get('type'));var filterObjectIndex=existingFilterObject?activeFiltersList.indexOf(existingFilterObject):-1;if(filterObjectIndex >= 0){activeFiltersList.splice(filterObjectIndex,1,filterObject);}else {activeFiltersList.pushObject(filterObject);}}, //Action triggered when reset/clear filters
onResetFilters:function onResetFilters(){var component=this;component.resetFilters();}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function resetFilters
   * Method to reset filters
   */ /**
   * @property {Object} appliedFilters
   * Property for active filters
   */appliedFilters:_ember['default'].computed(function(){return _ember['default'].Object.create({});}), /**
   * @property {Boolean} isShowDateRangeSelector
   * Property to show/hide date range selector
   */isShowDateRangeSelector:false, /**
   * @property {Date} startDate
   * Property for date range start date
   */startDate:_ember['default'].computed(function(){return moment().subtract(1,'months').format('MM-DD-YYYY');}), /**
   * @property {Date} endDate
   * Property for date range end date
   */endDate:_ember['default'].computed(function(){return moment().format('YYYY-MM-DD');}),activeFiltersList:_ember['default'].A([]),isReloadFilters:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function resetFilters
   * Method to reset filters
   */resetFilters:function resetFilters(){var component=this;component.set('appliedFilters',{});component.set('activeFiltersList',_ember['default'].A([]));component.sendAction('refreshFilters',{});}, /**
   * @function reloadFilters
   * Method to reload filters
   */reloadFilters:function reloadFilters(){var component=this;var appliedFilters=component.get('appliedFilters');if(appliedFilters.startDate && appliedFilters.endDate){component.set('daterange',_ember['default'].Object.create({startDate:appliedFilters.startDate,endDate:appliedFilters.endDate}));}var gutFilters={subjectCode:appliedFilters.subjectCode,domainCode:appliedFilters.domainCode,gutCode:appliedFilters.gutCode};component.set('gutFilters',gutFilters);}});});