define('gooru-web/components/cards/gru-student-featured-card',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -----------------------------------------------------------------
// Attributes
/**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), /**
   * @type {FeaturedCourseService} Service to retrieve Featured Courses Service information
   */featuredCourseService:_ember['default'].inject.service('api-sdk/featured-courses'), /**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'),classNames:['gru-student-featured-card'], // Attributes
classNameBindings:['coursedSeq'],coursedSeq:_ember['default'].computed(function(){return 'course-' + this.get('contentSeq');}), // ---------------------------------------------------------------
// Properties
// Properties
/**
   * @property {Course} course information
   */course:null, /**
   * @property {Boolean}
   */isExpand:false,didInsertElement:function didInsertElement(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},actions:{ /**
     * Action triggered when click on expand icon
     */toggleContent:function toggleContent(){ //
this.toggleProperty('isExpand');}, /**
     * Action triggered when click on study now button
     */studyNow:function studyNow(){var component=this;var showUserGradeSelection=component.get('showUserGradeSelection');var userId=component.get('session.userId');var profile=component.get('profile');if(showUserGradeSelection){if(profile.info && profile.info.grade){component.openStudyPlayer();}else {component.get('profileService').readUserProfile(userId).then(function(userProfile){if(!userProfile.info || userProfile.info && userProfile.info.grade === undefined){var queryParams={queryParams:{showKnowMore:true}};component.get('router').transitionTo('sign-up-finish',queryParams);}else {component.openStudyPlayer();}});}}else {component.openStudyPlayer();}},close:function close(){this.set('isExpand',false);}},openStudyPlayer:function openStudyPlayer(){this.joinPublicClassbyGrade();this.set('isExpand',false);var context={classId:this.get('course').id,courseTitle:this.get('course').title,courseId:this.get('course').id,originalCoourseId:this.get('course').originalCourseId,subject:this.get('course').subject,hasJoined:this.get('course').hasJoined,learnerCount:this.get('course').learnerCount};var userId=this.get('session.userId');var lastAccessedClassIdList=JSON.parse(localStorage.getItem('last_accessed_classId_' + userId)) || [];var currentDateTime=new Date().toISOString();var lastSelected={lastSelectedClassId:context.classId,currentDateTime:currentDateTime};lastAccessedClassIdList.push(lastSelected);localStorage.setItem('last_accessed_classId_' + userId,JSON.stringify(lastAccessedClassIdList));}, /**
   * @function joinPublicClassbyGrade
   * Method to join public class by student
   */joinPublicClassbyGrade:function joinPublicClassbyGrade(){var courseId=this.get('course.id');this.get('router').transitionTo('student-independent-study',{queryParams:{courseId:courseId}});}});});