define('gooru-web/components/reports/pull-up/player-pull-up',['exports','ember','gooru-web/config/config','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['player','player-pull-up'],attributeBindings:['bgStyle:style'],bgStyle:_ember['default'].computed('tenantSettingBg',function(){if(this.get('tenantSettingBg')){return 'background-image: url(' + this.get('tenantSettingBg') + ')';}else {return 'background-color:$grey-background';}}), //--------------------------------------------------------------------------
//property
/**
   * Indicates the status of the spinner
   * @property {Boolean}
   */isLoading:false, /**
   * @property {Service} tenant service
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;_ember['default'].$(document.body).addClass('iframe-panel');component.openPullUp();},didReceiveAttrs:function didReceiveAttrs(){var component=this; /**
     * method used to listen the events from iframe.
     **/function receiveMessage(event){if(event.data === _gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE){component.send('closePlayer');}else if(event.data === _gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_LOADING_COMPLETED){if(!component.get('isDestroyed')){component.set('isLoading',false);}}else if(event.data.message === _gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE){component.closePullUp(event.data.classId);}}window.addEventListener('message',receiveMessage,false);}, // -------------------------------------------------------------------------
// Actions
actions:{closePlayer:function closePlayer(){var component=this;var classId=component.get('classId');if(classId){component.get('router').transitionTo('student.class.course-map',classId);}else {component.closePullUp();}}}, //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.set('isLoading',true);component.$().animate({bottom:'0'},400);},closePullUp:function closePullUp(data){var component=this;if(!component.isDestroyed){component.$().animate({bottom:'100%'},400,function(){_ember['default'].$(document.body).removeClass('iframe-panel');if(data){component.get('router').transitionTo('student.class.dashboard',data);}component.sendAction('onclosePullUp');});}}});});