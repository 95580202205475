define('gooru-web/components/charts/report-bar-chart',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['charts','report-bar-chart'], // -------------------------------------------------------------------------
// Properties
/**
   * Number of bars should in one item
   * @type {[type]}
   */numberOfBars:12, /**
   * Stutent chart report data
   * @type {Object}
   */studentChartReportData:null, /**
   * Data slipt for each carousel item
   * @type {[type]}
   */dataSet:_ember['default'].computed('studentChartReportData',function(){var studentChartReportData=this.get('studentChartReportData');var numberOfBars=this.get('numberOfBars');var data=_ember['default'].A([]);for(var index=0,index1=studentChartReportData.length;index < index1;index += numberOfBars) {data.push(studentChartReportData.slice(index,index + numberOfBars));}return data;}), /**
   * This attribute will decide which field to use for chart
   * @type {Boolean}
   */useTimeSpentScore:false, // -------------------------------------------------------------------------
// Actions
actions:{onClickChart:function onClickChart(userId,showReport){this.sendAction('onClickChart',userId,showReport);},onClickPrev:function onClickPrev(){this.$('#report-bar-carousel-wrapper').carousel('prev');},onClickNext:function onClickNext(){this.$('#report-bar-carousel-wrapper').carousel('next');}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover',container:'body'});}});});