define('gooru-web/initializers/route',['exports','ember','gooru-web/config/environment'],function(exports,_ember,_gooruWebConfigEnvironment){exports.initialize = initialize; /**
 * Initialize Routes funtion
 */function initialize(app){var historyCache=_ember['default'].Object.extend({ /**
     * @property {*} the last route
     */lastRoute:_ember['default'].Object.create({url:null,name:null})});_ember['default'].Route.reopen({ /**
     * @property {HelpService}
     */helpService:_ember['default'].inject.service('api-sdk/help'), /**
     * @property {Service} I18N service
     */i18n:_ember['default'].inject.service(), /**
     * This event handlers sets an specific class to the body everytime a route is activated
     */startTime:null,endTime:null, /**
     * device_language_key
     */device_language_key:'deviceLanguage',addRouteSpecificClass:(function(){this.closeNoteToolOnRoute();var route=this;var currentRouteName=route.routeName;var currentPageIdentifier=currentRouteName.replace(/\./g,'_');var helpInfoDetail=this.get('helpService').getHelpInfo();if(currentRouteName !== 'logout'){if(helpInfoDetail === null){this.get('helpService').setHelpInfo();}else {var helpInfo=JSON.parse(helpInfoDetail);helpInfo.map(function(item){if(currentPageIdentifier === item.pageIdentifier.trim()){route.showHelpIcon(item);}});}}this.set('startTime',moment().format('hh:mm'));var rootElement=_ember['default'].$(_gooruWebConfigEnvironment['default'].rootElement);rootElement.addClass(currentRouteName.replace(/\./g,'_'));}).on('activate'), /**
     * This event handlers removes an specific class to the body everytime a route is activated
     */removeRouteSpecificClass:(function(){var route=this;var currentRouteName=route.routeName;var rootElement=_ember['default'].$(_gooruWebConfigEnvironment['default'].rootElement);rootElement.removeClass(currentRouteName.replace(/\./g,'_'));this.set('endTime',moment().format('hh:mm'));}).on('deactivate'), /**
     * When leaving a route this handler save the previous route so it can be accessed from history
     */saveLastRoute:(function(){var route=this;var currentRouteName=route.routeName;var currentRouteUrl=route.router.get('url');var lastRoute=this.get('history.lastRoute');var savedRouteUrl=lastRoute.get('url');var parentRouteIdx=savedRouteUrl && savedRouteUrl.indexOf(currentRouteUrl);if(!currentRouteName.match(/\.loading/) && (!savedRouteUrl || parentRouteIdx === -1)){ // On deactivate, save the "child-most" route
// For example: on deactive save the route "search.collection", but "search" (the parent route)
// will not be saved
lastRoute.set('name',currentRouteName);lastRoute.set('url',currentRouteUrl);this.get('history').set('lastRoute',lastRoute);}}).on('deactivate'), /**
     * Resetting the scroll to the top of the page when browsing to a new page
     */restoreScroll:(function(){window.scrollTo(0,0);}).on('activate'),getLocalStorage:function getLocalStorage(){return window.localStorage;},showHelpIcon:function showHelpIcon(helpInfoDetail){var _this=this;var route=this;var deviceLanguage=route.getLocalStorage().getItem(route.device_language_key);helpInfoDetail.uiElementIdentifiers.map(function(identifierData){_ember['default'].run.scheduleOnce('afterRender',_this,function(){_ember['default'].run.later(function(){if($('body').hasClass('' + helpInfoDetail.pageIdentifier.trim())){$('' + identifierData.identifier).closest('' + identifierData.identifier).css('display','inline-flex');if(!identifierData.identifier.includes('.new-version.teacher') && !identifierData.identifier.includes('.new-version.student')){_ember['default'].$('' + identifierData.identifier).append('<div class="showHelpIcon" id=' + identifierData.id + ' data-toggle="tooltip" title="' + identifierData.title + '"><span class="material-icons">help</span></div>');}$('.showHelpIcon').click(function(event){route.get('helpService').getHelpInfoDescription(event.currentTarget.id,deviceLanguage).then(function(response){$('body').addClass('gruHelpPullup');$('.helpPullUp').css('display','block');route.controllerFor('application').showhelpPullUp(identifierData,response);});});}},3000);});});},closeNoteToolOnRoute:function closeNoteToolOnRoute(){$('#note-tool-div').hide();}}); // History cache is available to all routes
app.register('history:main',historyCache);app.inject('route','history','history:main');}exports['default'] = {name:'route',after:'gooru-configuration',initialize:initialize};});