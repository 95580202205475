define('gooru-web/controllers/profile/guardian',['exports','ember','gooru-web/validations/guardian-invite','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebValidationsGuardianInvite,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
parentController:_ember['default'].inject.controller('profile'),profileService:_ember['default'].inject.service('api-sdk/profile'),session:_ember['default'].inject.service('session'),isGuestAccount:_ember['default'].computed.alias('session.isGuest'), // -------------------------------------------------------------------------
// Properties
showInviteGuardian:false,didValidate:false, /**
   * showRoleErrorMessage
   * @property {Boolean}
   */showRoleErrorMessage:false,userProfile:_ember['default'].computed('appRootPath',function(){return this.get('appRootPath') + _gooruWebConfigConfig.DEFAULT_IMAGES.USER_PROFILE;}), /**
   * @property {userId}
   * @returns {string}
   */userId:_ember['default'].computed('profile','session',function(){return this.get('profile.id') || this.get('session.userId');}),isShowImpersonate:_ember['default'].computed(function(){var impersonate=undefined;if(window.frameElement){impersonate = window.parent.impersonate;}return impersonate;}), /**
   * A link to the parent profile controller
   * @see controllers/profile.js
   * @property {Class}
   */profile:_ember['default'].computed.reads('parentController.profile'), /**
   * @type {String} Error with email field
   */emailError:false, /**
   * @type {guardianInfo} guardianInfo
   */guardianInfo:null, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var information=this.getGuardianInformation();this.set('guardianInfo',information);}, // -------------------------------------------------------------------------
// Actions
actions:{showInviteGuardian:function showInviteGuardian(show){if(show){this.set('showInviteGuardianFlag',true);}else {this.set('showInviteGuardianFlag',false);}},roleSelect:function roleSelect(id){var guardianInfo=this.get('guardianInfo');guardianInfo.set('relationshipId',Number(id));},inviteGuardian:function inviteGuardian(){var controller=this;var information=controller.get('guardianInfo');if(controller.get('didValidate') === false){var firstName=_ember['default'].$('.gru-input.firstName input').val(),lastName=_ember['default'].$('.gru-input.lastName input').val(),email=_ember['default'].$('.gru-input.email input').val();information.set('firstName',firstName);information.set('lastName',lastName);information.set('email',email);}var relationshipId=information.get('relationshipId');var isValid=true;var showRoleErrorMessage=false;var userId=controller.get('userId');if(!relationshipId){showRoleErrorMessage = true;isValid = false;}controller.set('showRoleErrorMessage',showRoleErrorMessage);if(isValid){information.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){controller.get('profileService').inviteGuardian(userId,information).then(function(){controller.getGuardianList();controller.resetController();controller.set('didValidate',false);},function(error){if(error && error.message){controller.set('emailError',error.message);controller.keydownEvents();}});}});}},onClosePullUp:function onClosePullUp(){this.set('showInviteGuardianFlag',false);this.resetController();},acceptGuardianRequest:function acceptGuardianRequest(guardianInfo){var controller=this;var guardianId=Number(guardianInfo.id);controller.get('profileService').acceptGuardianRequest(guardianId).then(function(){controller.getGuardianList();});}}, // -------------------------------------------------------------------------
// Methods
/**
   * Method to use create guardian object
   */getGuardianInformation:function getGuardianInformation(){var Information=_ember['default'].Object.extend({firstName:null,lastName:null,email:null,relationshipId:null}),extendedInformation=Information.extend(_gooruWebValidationsGuardianInvite['default']);var information=extendedInformation.create(_ember['default'].getOwner(this).ownerInjection(),{firstName:null,lastName:null,email:null,relationshipId:null});return information;}, /**
   * Method to use get guardians list
   */getGuardianList:function getGuardianList(){var controller=this;controller.get('profileService').getGuardianList().then(function(guardianList){controller.set('guardianList',guardianList.guardians);});}, /**
   * Add keydown events to clear errors on username and email
   */keydownEvents:function keydownEvents(){var controller=this;var $email=_ember['default'].$('.email input');$email.unbind('keydown');$email.on('keydown',function(){controller.set('emailError',false);});}, /**
   * Method to use reset data values
   */resetController:function resetController(){var controller=this;var information=controller.getGuardianInformation();controller.set('guardianInfo',information);controller.set('showRoleErrorMessage',false);controller.set('showInviteGuardianFlag',false);controller.set('emailError',false);}});});