define('gooru-web/components/search/gru-search-student',['exports','ember','gooru-web/config/config','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['search-student','gru-search-student'], // -------------------------------------------------------------------------
// Dependencies
profileService:_ember['default'].inject.service('api-sdk/profile'),classService:_ember['default'].inject.service('api-sdk/class'),i18n:_ember['default'].inject.service(), /**
   * @property {Service} TenantService service
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.inputHandler();component.resetProperties();},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},willDestroyElement:function willDestroyElement(){var component=this;component.resetProperties();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when select a student
onSelectStudent:function onSelectStudent(student){var component=this;var selectedStudents=component.get('selectedStudents');var selectedStudentPos=selectedStudents.indexOf(student);if(selectedStudentPos >= 0){selectedStudents.removeObject(student);student.set('isSelected',false);}else {selectedStudents.pushObject(student);student.set('isSelected',true);}}, //Action triggered when hit Add button
onAddStudents:function onAddStudents(){var component=this;var selectedStudents=component.get('selectedStudents');component.sendAction('onAddStudents',selectedStudents);component.resetProperties();}, //Action triggered when toggle search criteria dropdown
onToggleSearchCriteria:function onToggleSearchCriteria(){var component=arguments.length <= 0 || arguments[0] === undefined?this:arguments[0];component.$('.search-type-selector-container .search-type-list-container').slideToggle(400,function(){if(component.$(this).is(':visible')){component.$(this).css('display','inline-block');}});component.toggleProperty('isSearchCriteriaExpanded');}, //Action triggered when select a search criteria
onSelectSearchCriteria:function onSelectSearchCriteria(searchCriteria){var component=this;if(component.get('activeCriteria.type') !== searchCriteria.get('type')){var searchCriterias=component.get('searchCriterias');searchCriterias.filter(function(searchCriteria){searchCriteria.set('isActive',false);});searchCriteria.set('isActive',true);component.actions.onToggleSearchCriteria(component);component.sendAction('onAddMultiStudents',searchCriteria.type);}}, //Action triggered when click search icon
onSearchStudents:function onSearchStudents(){var component=this;component.set('filteredStudents',_ember['default'].A([]));component.loadStudents();}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {String} searchTerm
   * Property for entered search term
   */searchTerm:'', /**
   * @property {Array} filteredStudents
   * Property for filtered student list
   */filteredStudents:_ember['default'].A([]), /**
   * @property {Array} selectedStudents
   * Property for selected student list
   */selectedStudents:_ember['default'].A([]), /**
   * @property {Boolean} isEnableAdd
   * Property to enable/disable Add button
   */isEnableAdd:_ember['default'].computed.gt('selectedStudents.length',0), /**
   * @property {Array} classMembers
   * Property for active class members
   */classMembers:_ember['default'].A([]), /**
   * @property {Boolean} isAddStundentByEmail
   * Property help to add bulk student by email or username
   */isAddStundentByEmail:_ember['default'].computed('tenantSettingsObj',function(){var tenantSetting=this.get('tenantSettingsObj');return !(tenantSetting && tenantSetting.ui_element_visibility_settings && tenantSetting.ui_element_visibility_settings.add_students_by === 'username');}), /**
   * @property {Array} searchCriterias
   * Property for list of student search criterias
   */searchCriterias:_ember['default'].computed(function(){var component=this;return _ember['default'].A([_ember['default'].Object.create({type:'email',label:component.get('i18n').t('sign-up.email'),isActive:component.get('isMulitAddStud')?!!component.get('isAddStundentByEmail'):true}),_ember['default'].Object.create({type:'username',label:component.get('i18n').t('sign-up.username'),isActive:component.get('isMulitAddStud')?!component.get('isAddStundentByEmail'):false})]);}), /**
   * @property {Object} activeCriteria
   * Property for active search criteria
   */activeCriteria:_ember['default'].computed('searchCriterias.@each.isActive',function(){return this.get('searchCriterias').findBy('isActive',true);}), /**
   * @property {Boolean} isSearchCriteriaExpanded
   * Property for search criteria expanded/collapsed view
   */isSearchCriteriaExpanded:false, /**
   * @property {Boolean} isNoStudentsFound
   * Property to check whether students found or not
   */isNoStudentsFound:false, /**
   * @property {Boolean} isLoading
   */isLoading:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function inputTypeHandler
   * Method to handle the input key event
   */inputHandler:function inputHandler(){var component=this;component.$('.search-student-input-container .search-student').keyup(function(event){var searchTerm=component.get('searchTerm');if(searchTerm && event.keyCode === _gooruWebConfigConfig.KEY_CODES.ENTER && searchTerm.length >= 3){component.set('filteredStudents',_ember['default'].A([]));component.loadStudents();}});}, /**
   * @function loadStudents
   * Method to load student according to enabled search criteria
   */loadStudents:function loadStudents(){var component=this;component.set('isLoading',true);component.set('isNoStudentsFound',false);component.fetchStudentProfiles().then(function(userProfiles){component.callTenantSettings();if(!component.isDestroyed){(function(){var studentProfiles=userProfiles.filter(function(user){return user.get('role') === _gooruWebConfigConfig.ROLES.STUDENT;});var classMembers=component.get('classMembers');studentProfiles.map(function(student){if(!classMembers.findBy('id',student.get('id'))){component.get('filteredStudents').pushObject(student);}});component.set('filteredStudents',component.get('filteredStudents').sortBy('lastName'));component.set('isLoading',false);})();}})['catch'](function(){component.set('isNoStudentsFound',true);component.set('isLoading',false);});},callTenantSettings:function callTenantSettings(){var component=this;component.get('tenantService').getActiveTenantSetting().then(function(tenantSettings){component.set('tenantSettings',tenantSettings);component.set('tenantClassSettings',tenantSettings.class_settings_show_student_info);});}, /**
   * @function fetchStudentProfiles
   * @return {Promise}
   * Method to fetch student profiles based on enabled search term and criteria
   */fetchStudentProfiles:function fetchStudentProfiles(){var component=this;var searchType=component.get('activeCriteria.type');var searchTerm=component.get('searchTerm');var searchCriteria={};searchCriteria['' + searchType] = searchTerm;return component.get('profileService').searchUserProfiles(searchCriteria);}, /**
   * @function resetProperties
   * Method to reset active properties
   */resetProperties:function resetProperties(){var component=this;component.set('filteredStudents',_ember['default'].A([]));component.set('selectedStudents',_ember['default'].A([]));component.set('searchTerm','');}});});