define('gooru-web/components/proficiency/student-proficiency-panel',['exports','ember','gooru-web/config/config','gooru-web/utils/utils'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({classNames:['student-proficiency-panel'], // -------------------------------------------------------------------------
// Dependencies
taxonomyService:_ember['default'].inject.service('taxonomy'), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Object}
   * Property to store active subject selected
   */activeSubject:null, /**
   * @property {Object}
   * Property to store timeLine
   */timeLine:null, /**
   * @property {Object}
   * Property to store class data
   */'class':null, /**
   * @property {Object}
   * Property to store selected grade
   */selectedGrade:null, /**
   * @property {Boolean}
   * Property to find is student or not
   */isStudent:null, /**
   * @property {Array}
   * Property to store taxonomy categories
   */categories:null, /**
   * @property {Boolean}
   * Property to show matrix chart or not
   */isShowMatrixChart:null, /**
   * @property {Boolean}
   * Property to store given screen value is compatible
   */isMobile:(0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.SMALL - 1), /**
   * @property {Array}
   * Property to store competency domains
   */competencyMatrixDomains:null, /**
   * @property {Object}
   * Property to store user proficiency baseline data
   */userProficiencyBaseLine:null, /**
   * @property {Array}
   * Property to store taxonomy subjects
   */taxonomySubjects:null, /**
   * @property {Array}
   * Property to store competency coordinates
   */competencyMatrixCoordinates:null, /**
   * @property {Object}
   * Property to store tagged subject bucket
   */subjectBucket:null, /**
   * @property {Array}
   * Property to identify baseline is selected or not
   */isSelectBaseLine:null,isLegendPullup:false, /**
   * @property {Boolean} isShowTimeSeries
   */isShowTimeSeries:true, /**
   * @property {Date} timeSeriesEndDate
   */timeSeriesEndDate:moment().toDate(), /**
   * @property {Date} timeSeriesStartDate
   */timeSeriesStartDate:_ember['default'].computed('class',function(){return moment(this.get('class.startDate')).format('YYYY-MM-DD');}), /**
   * @property {Object} studentDestination
   */studentDestination:_ember['default'].computed('class',function(){var memberbounds=this.get('class.memberGradeBounds');var userId=this.get('studentProfile.id');var activeStudentGrade=memberbounds && memberbounds.find(function(item){return item[userId];});return activeStudentGrade?activeStudentGrade[userId]:null;}),selectedCategory:null, /**
   * @property {Date}
   * Property to store course started date or one year before date
   */courseStartDate:_ember['default'].computed('course',function(){var component=this;var course=component.get('course');var courseCreatedDate=new Date();if(course && course.createdDate){courseCreatedDate = new Date(course.createdDate);}else {var curMonth=courseCreatedDate.getMonth();var curYear=courseCreatedDate.getFullYear();var oneYearBeforeFromCurrentDate=courseCreatedDate;courseCreatedDate = new Date(oneYearBeforeFromCurrentDate.setMonth(curMonth - 11));courseCreatedDate = new Date(oneYearBeforeFromCurrentDate.setFullYear(curYear - 1));}return courseCreatedDate;}),proficiencyChartData:_ember['default'].A([]),totalTopics:_ember['default'].computed('proficiencyChartData',function(){return this.get('proficiencyChartData.length');}),getTenantFW:_ember['default'].observer('activeSubject',function(){var tenantSetting=this.get('tenantSettingsObj');if(tenantSetting && tenantSetting.tx_fw_prefs && this.get('isPublic') && this.get('isEnableNavigatorPrograms')){var activeSubject=this.get('activeSubject');var isFound=tenantSetting.tx_fw_prefs[activeSubject.id];var tenantFW=isFound?isFound.default_fw_id:null;this.set('tenantFW',tenantFW);}}),disableHeader:false,actions:{onSelectCategory:function onSelectCategory(category){var component=this;component.sendAction('onSelectCategory',category);}, /**
     * Action triggered when select a month from chart
     */onSelectMonth:function onSelectMonth(date){this.sendAction('onSelectMonth',date);},onToggleBaseline:function onToggleBaseline(){var component=this;component.toggleProperty('isSelectBaseLine');},goBack:function goBack(){var component=this;var isStudent=component.get('isStudent');var classId=component.get('class.id');var courseId=component.get('course.id');if(isStudent){component.get('router').transitionTo('student-locate',{queryParams:{classId:classId,courseId:courseId}});}else {component.sendAction('onChangeToBack');}},goBackDashboard:function goBackDashboard(){var component=this;component.get('router').transitionTo('student.class.dashboard');}, /**
     * Action triggered at once the baseline is drawn
     */onShownBaseLine:function onShownBaseLine(){var component=this;component.set('isShowTimeSeries',true);}, /**
     * Action triggered when select a subject
     */onSelectSubject:function onSelectSubject(subject){var component=this;component.set('isSelectBaseLine',false);component.set('activeSubject',subject);component.sendAction('onSubjectChange',subject);}, /**
     * Action triggered when select a competency
     */onSelectCompetency:function onSelectCompetency(competency,domainCompetencyList){var component=this;component.sendAction('onSelectCompetency',competency,domainCompetencyList);},showPullup:function showPullup(){this.set('isLegendPullup',true);}, /**
     * Action triggered when select a domain
     */onDomainSelect:function onDomainSelect(domain,isNotSet){var component=this;component.sendAction('onDomainSelect',domain,isNotSet);},onSelectTopic:function onSelectTopic(topic){this.sendAction('onSelectTopic',topic);},onClickPrev:function onClickPrev(){var startDate=this.get('timeSeriesEndDate');var getStartMonth=startDate.getMonth();var getStartYear=startDate.getFullYear();var startDomainDate=new Date(getStartYear - 1,getStartMonth);this.set('timeSeriesEndDate',startDomainDate);this.showButton(this.get('timeSeriesEndDate'),this.get('timeSeriesStartDate'));},onClickNext:function onClickNext(){var startDate=this.get('timeSeriesEndDate');var getStartMonth=startDate.getMonth();var getStartYear=startDate.getFullYear();var startDomainDate=new Date(getStartYear + 1,getStartMonth);this.set('timeSeriesEndDate',startDomainDate);this.showButton(this.get('timeSeriesEndDate'),this.get('timeSeriesStartDate'));},isShowLoaderSet:function isShowLoaderSet(value){this.set('isShowLoader',value);}},showButton:function showButton(timeSeriesEndDate,endDate){var component=this;var timeSeriesEndDates='01-' + moment(timeSeriesEndDate).format('MM-YYYY');var currentDate='01-' + moment().format('MM-YYYY');var getEndDate='01-' + moment(endDate).format('MM-YYYY');component.set('showNextbtn',currentDate !== timeSeriesEndDates);var checkPrevDate='01-' + moment(endDate).format('MM') + '-' + (parseInt(moment(timeSeriesEndDate).format('YYYY')) - 1);component.set('showPrevtbtn',getEndDate >= checkPrevDate);},didDestroyElement:function didDestroyElement(){var component=this;component.set('isSelectBaseLine',false);},didInsertElement:function didInsertElement(){var component=this;var currentDate=moment().toDate();component.set('timeSeriesEndDate',currentDate);component.showButton(this.get('timeSeriesEndDate'),this.get('timeSeriesStartDate'));if(component.get('isStudent')){component.set('disableHeader',true);}}});});