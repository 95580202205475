define('gooru-web/components/reports/pull-up/activity/collection-performance-data',['exports','ember','gooru-web/config/config','gooru-web/utils/utils'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['add-data','collection-performance-data'], // -------------------------------------------------------------------------
// Serevices
collectionService:_ember['default'].inject.service('api-sdk/collection'),performanceService:_ember['default'].inject.service('api-sdk/performance'),analyticsService:_ember['default'].inject.service('api-sdk/analytics'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;if(component.get('isCollection')){component.loadCollectionData();}else { //pre populate external collection timespent
var activityData=component.get('activityData');if(activityData.get('collection.performance')){component.resetHourMinute((0,_gooruWebUtilsUtils.formatTime)(activityData.get('collection.performance.timeSpent')));}}if(component.get('isMobileView')){component.set('timeSpentElementContainer',component.$('.timespent-container'));}},didRender:function didRender(){var component=this;if(component.get('isMobileView') && component.get('isCollection')){component.$('.active-resource .resource-timespent-details').append(component.get('timeSpentElementContainer'));}component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when select a resource
onSelectResource:function onSelectResource(resource){var component=this;if(component.get('activeResource.id') !== resource.get('id')){component.set('activeResource',resource);component.resetHourMinute((0,_gooruWebUtilsUtils.formatTime)(resource.get('timeSpent')));}}, //Action triggered when save given timespent into active resource
onSaveTimeSpent:function onSaveTimeSpent(){var component=this;var maxHour=parseInt(component.get('maxHour'));var maxMinute=parseInt(component.get('maxMinute'));maxHour = isNaN(maxHour)?0:maxHour;maxMinute = isNaN(maxMinute)?0:maxMinute;var timeSpentInmIlliSec=(maxHour * 60 * 60 + maxMinute * 60) * 1000;component.set('activeResource.timeSpent',timeSpentInmIlliSec);if(component.get('isLastResource')){if(component.get('isOverwritePerformance')){component.overwriteCollectionPerformance();}else {component.submitCollectionPerformanceData();}}else {var resources=component.get('resources');var curResourceIndex=resources.indexOf(component.get('activeResource'));var nextResource=resources.objectAt(curResourceIndex + 1);component.set('activeResource',nextResource);component.resetHourMinute((0,_gooruWebUtilsUtils.formatTime)(nextResource.get('timeSpent')));component.clearFilteredResources();}}, //Action triggered when search for a resource
onSearchResource:function onSearchResource(){var component=this;var resourceSearchPattern=component.get('resourceSearchPattern').toLowerCase();var resources=component.get('resources');var filteredResources=resources.filter(function(resource){return resource.get('title').toLowerCase().includes(resourceSearchPattern);});component.set('resourceList',filteredResources);}, //Action triggered when clear search resources text pattern
onClearSearchResources:function onClearSearchResources(){var component=this;component.clearFilteredResources();}, //Action triggered when submit external collection timespent
onSubmitExternalCollectionTimeSpent:function onSubmitExternalCollectionTimeSpent(){var component=this;component.submitExternalCollectionPerformanceData();}}, // -------------------------------------------------------------------------
// Properties
timeSpentElementContainer:null, /**
   * @property {Boolean} isMobileView
   */isMobileView:(0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.MEDIUM), /**
   * @property {Boolean} isCollection
   */isCollection:_ember['default'].computed.equal('collection.format',_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION), /**
   * @property {String} resourceSearchPattern
   */resourceSearchPattern:'', /**
   * @property {Array} resources
   */resources:_ember['default'].A([]), /**
   * @property {Array} resourceList
   */resourceList:_ember['default'].computed('resources',function(){return this.get('resources');}), /**
   * @property {Object} activeResource
   */activeResource:_ember['default'].computed('resources',function(){var component=this;return component.get('resources').objectAt(0);}), /**
   * @property {Number} maxHour
   */maxHour:0, /**
   * @property {Number} maxMinute
   */maxMinute:0, /**
   * @property {Boolean} isLastResource
   */isLastResource:_ember['default'].computed('activeResource',function(){var component=this;var resources=component.get('resources');var activeResource=component.get('activeResource');return resources.indexOf(activeResource) === resources.length - 1;}), /**
   * @property {Boolean} isValidTimeSpent
   */isValidTimeSpent:_ember['default'].computed('maxHour','maxMinute',function(){var component=this;var maxHour=parseInt(component.get('maxHour'));var maxMinute=parseInt(component.get('maxMinute'));return (0,_gooruWebUtilsUtils.validateTimespent)(maxHour,maxMinute);}), /**
   * @property {Number} timeSpentInmIlliSec
   */timeSpentInmIlliSec:0, /**
   * @property {Boolean} isShowClearSearchResources
   */isShowClearSearchResources:_ember['default'].computed('resourceSearchPattern',function(){var component=this;return component.get('resourceSearchPattern.length') > 0;}), /**
   * @property {String} contentSource
   */contentSource:'dailyclassactivity', /**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}), /**
   * @property {Boolean} isOverwritePerformance
   */isOverwritePerformance:_ember['default'].computed.alias('activityData.isUpdatePerformance'), // -------------------------------------------------------------------------
// Functions
clearFilteredResources:function clearFilteredResources(){var component=this;component.set('resourceSearchPattern','');component.set('resourceList',component.get('resources'));}, /**
   * @function resetHourMinute
   * Method to reset active maxHour and maxMinute
   */resetHourMinute:function resetHourMinute(timeString){var component=this;var maxHour=0;var maxMinute=0;if(timeString){var splittedTime=timeString.split(' ');var firstHalfString=splittedTime[0];var secodHalfString=splittedTime[1];if(firstHalfString && firstHalfString.includes('h')){maxHour = firstHalfString.slice(0,-1);}else if(firstHalfString && firstHalfString.includes('m')){maxMinute = firstHalfString.slice(0,-1);}if(secodHalfString && secodHalfString.includes('m')){maxMinute = secodHalfString.slice(0,-1);}}component.set('maxHour',parseInt(maxHour));component.set('maxMinute',parseInt(maxMinute));}, /**
   * @function loadCollectionData
   * Method to load collection data
   */loadCollectionData:function loadCollectionData(){var component=this;var collection=component.get('collection');component.fetchCollectionData(collection.get('id')).then(function(collectionData){component.set('resources',collectionData.get('children'));if(component.get('isOverwritePerformance')){component.loadStudentsActivityPerformance();}});}, /**
   * @function loadStudentsActivityPerformance
   * Method to load students activity performance
   */loadStudentsActivityPerformance:function loadStudentsActivityPerformance(){var component=this;var analyticsService=component.get('analyticsService');var activityData=component.get('activityData');var activityDate=activityData.get('activation_date')?activityData.get('activation_date'):moment().format('YYYY-MM-DD');var collectionId=activityData.get('collection.id');var collectionType='collection';var classId=component.get('classId');var endDate=activityData.get('end_date');return _ember['default'].RSVP.hash({studentsActivityPerformance:analyticsService.getDCAPerformance(classId,collectionId,collectionType,activityDate,endDate)}).then(function(_ref){var studentsActivityPerformance=_ref.studentsActivityPerformance;component.parseStudentsActivityPerformanceData(studentsActivityPerformance);});}, /**
   * @function fetchCollectionData
   * Method to fetch collection data
   */fetchCollectionData:function fetchCollectionData(collectionId){var component=this;var collectionService=component.get('collectionService');return collectionService.readCollection(collectionId);}, /**
   * @function submitCollectionPerformanceData
   * Method to submit student collection timespent data
   */submitCollectionPerformanceData:function submitCollectionPerformanceData(){var component=this;var students=component.get('students');var studentCollectionPerformanceData=component.getCollectionDataParams();var studentPerformanceData=students.map(function(student){studentCollectionPerformanceData.session_id = (0,_gooruWebUtilsUtils.generateUUID)();studentCollectionPerformanceData.student_id = student.get('id');return component.saveStudentCollectionPerformanceData(studentCollectionPerformanceData);});_ember['default'].RSVP.Promise.all(studentPerformanceData).then(function(){component.sendAction('onClosePullUp');});}, /**
   * @function submitExternalCollectionPerformanceData
   * Method to submit external collection performance data
   */submitExternalCollectionPerformanceData:function submitExternalCollectionPerformanceData(){var component=this;component.saveStudentCollectionPerformanceData(component.getExternalCollectionDataParams()).then(function(){component.sendAction('onClosePullUp');});}, /**
   * @function overwriteCollectionPerformance
   * Method to overwrite collection performance
   */overwriteCollectionPerformance:function overwriteCollectionPerformance(){var component=this;var students=component.get('students');var activityData=component.get('activityData');var studentCollectionPerformanceData=component.getCollectionDataParams();var requestBodyKeysToRest=['conducted_on','path_id','path_type','tenant_id','course_id','additionalContext'];studentCollectionPerformanceData = component.resetRequestBodyByKeys(studentCollectionPerformanceData,requestBodyKeysToRest);var overwriteSpecifcParams={activity_date:activityData.get('activation_date'),additional_context:btoa(JSON.stringify({dcaContentId:activityData.get('id')}))};studentCollectionPerformanceData = Object.assign(studentCollectionPerformanceData,overwriteSpecifcParams); //Filter the resource timespent if not null
studentCollectionPerformanceData.resources = studentCollectionPerformanceData.resources.filter(function(item){return item.time_spent !== null;});students.map(function(student){studentCollectionPerformanceData.student_id = student.get('id');component.overwriteStudentCollectionPerformance(studentCollectionPerformanceData);});component.sendAction('onClosePullUp');}, /**
   * @function saveStudentCollectionPerformanceData
   * Method to save student collection performance data
   */saveStudentCollectionPerformanceData:function saveStudentCollectionPerformanceData(collectionParams){var component=this;var performanceService=component.get('performanceService');return performanceService.updateCollectionOfflinePerformance(collectionParams);}, /**
   * @function overwriteStudentCollectionPerformance
   * Method to overwrite student collection performance
   */overwriteStudentCollectionPerformance:function overwriteStudentCollectionPerformance(collectionParams){var component=this;var performanceService=component.get('performanceService');return performanceService.overwriteCollectionPerformance(collectionParams);}, /**
   * @function getCollectionDataParams
   * Method to generate collection data params
   */getCollectionDataParams:function getCollectionDataParams(){var component=this;var resources=component.get('resources');var collectionResources=_ember['default'].A([]);resources.forEach(function(resource){collectionResources.push(component.getResourceRequestBody(resource));});var studentPerformanceData={collection_type:'collection',resources:collectionResources};return Object.assign(studentPerformanceData,component.getCollectionRequestBody());}, /**
   * @function getExternalCollectionDataParams
   * Method to generate external collection data params
   */getExternalCollectionDataParams:function getExternalCollectionDataParams(){var component=this;var collection=component.get('collection');var studentIds=component.get('students').mapBy('id');var maxHour=parseInt(component.get('maxHour'));var maxMinute=parseInt(component.get('maxMinute'));maxHour = isNaN(maxHour)?0:maxHour;maxMinute = isNaN(maxMinute)?0:maxMinute;var timeSpentInmIlliSec=(maxHour * 60 * 60 + maxMinute * 60) * 1000;var studentPerformanceData={student_id:studentIds,unit_id:collection.get('unitId'),lesson_id:collection.get('lessonId'),collection_type:'collection-external',time_spent:timeSpentInmIlliSec,session_id:(0,_gooruWebUtilsUtils.generateUUID)()};return Object.assign(studentPerformanceData,component.getCollectionRequestBody());}, /**
   * @function parseStudentsActivityPerformanceData
   * Method to parse students activity performance data
   */parseStudentsActivityPerformanceData:function parseStudentsActivityPerformanceData(studentsActivityPerformance){var component=this;var students=component.get('students');var resources=component.get('resources');var resourcesPerformance=studentsActivityPerformance.objectAt(0).get('resourceResults');students.map(function(student){var studentActivityPerformance=studentsActivityPerformance.findBy('user',student.get('id'));student.set('performance',studentActivityPerformance?studentActivityPerformance.get('collection'):null);});resources.map(function(resource){var resourcePerformance=resourcesPerformance.findBy('resourceId',resource.get('id'));resource.set('performance',resourcePerformance);resource.set('timeSpent',resourcePerformance.get('timeSpent'));}); //First resource active by default
component.resetHourMinute((0,_gooruWebUtilsUtils.formatTime)(resources.objectAt(0).get('timeSpent')));}, /**
   * @function getResourceRequestBody
   * Method to get resource request body
   */getResourceRequestBody:function getResourceRequestBody(resource){return {resource_id:resource.get('id'),resource_type:resource.get('content_format'),time_spent:resource.get('timeSpent'),question_type:resource.get('type')};}, /**
   * @function getCollectionRequestBody
   * Method to get collection requestion body param
   */getCollectionRequestBody:function getCollectionRequestBody(){var component=this;var collection=component.get('collection');var conductedOn=component.get('activityData.activation_date')?new Date(component.get('activityData.activation_date')):new Date();var classId=component.get('classId');var activityId=component.get('activityData.id');var courseId=component.get('course')?component.get('course.id'):null;return {tenant_id:component.get('session.tenantId') || null,class_id:classId,collection_id:collection.get('id'),content_source:component.get('contentSource'),time_zone:component.get('timeZone'),conducted_on:conductedOn.toISOString(),path_id:0,path_type:null,course_id:courseId,additionalContext:btoa(JSON.stringify({dcaContentId:activityId}))};}, /**
   * @function resetRequestBodyByKeys
   * Method to reset request body keys
   */resetRequestBodyByKeys:function resetRequestBodyByKeys(dataToRest,jsonKeys){jsonKeys.map(function(key){dataToRest['' + key] = undefined;});return dataToRest;}});});