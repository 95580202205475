define('gooru-web/controllers/force-change-password',['exports','ember','gooru-web/models/profile/profile','gooru-web/validations/force-change-password'],function(exports,_ember,_gooruWebModelsProfileProfile,_gooruWebValidationsForceChangePassword){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// Actions
actions:{changePassword:function changePassword(){var controller=this;var profile=controller.get('profile');if(controller.get('didValidate') === false){var password=_ember['default'].$('.gru-input.password input').val();var confirmPassword=_ember['default'].$('.gru-input.chPassword input').val();profile.set('password',password);profile.set('chPassword',confirmPassword);}profile.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){controller.get('profileService').forceChangePassword(profile.password).then(function(){controller.set('didValidate',true);var successMessage=controller.get('i18n').t('change-password.change-success').string;controller.get('notifications').success(successMessage);var role=controller.get('session.role');_ember['default'].run.later(function(){if(role === 'teacher'){controller.transitionToRoute('facets-search');}else {controller.transitionToRoute('student-home');}},3000);},function(error){controller.get('notifications').error(error.message);_ember['default'].Logger.error(error);});profile.set('password',null);profile.set('chPassword',null);_ember['default'].$('.gru-input.password input').val('');_ember['default'].$('.gru-input.chPassword input').val('');}});}}, // -------------------------------------------------------------------------
// Methods
/**
   * init and reset all the properties for the validations
   */resetProperties:function resetProperties(){var controller=this;var changePasswordProfile=_gooruWebModelsProfileProfile['default'].extend(_gooruWebValidationsForceChangePassword['default']);var profile=changePasswordProfile.create(_ember['default'].getOwner(this).ownerInjection(),{password:null,chPassword:null});controller.set('profile',profile);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Profile} profile
   */profile:null, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false});});