define('gooru-web/components/ca-daterange-datepicker',['exports','ember','gooru-web/config/config','gooru-web/utils/utils','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/models/taxonomy/taxonomy-tag'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebModelsTaxonomyTaxonomyTag){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['ca-daterange-picker'], // -------------------------------------------------------------------------
// Properties
/**
   * Maintains the value which of activity startDate
   * @type {Integer}
   */startDate:moment().format('YYYY-MM-DD'), /**
   * Maintains the value which of activity endDate
   * @type {Integer}
   */endDate:null, /**
   * Maintains the value which of month activities displaying
   * @type {Integer}
   */forMonth:_ember['default'].computed(function(){return moment().format('MM');}), /**
   * Maintains the value which of year activities displaying
   * @type {Integer}
   */forYear:_ember['default'].computed(function(){return moment().format('YYYY');}), /**
   * Maintains  the value of first date of month
   * @return {String}
   */forFirstDateOfMonth:_ember['default'].computed('forMonth','forYear',function(){var forMonth=this.get('forMonth');var forYear=this.get('forYear');var date=forYear + '-' + forMonth + '-01';return moment(date).format('YYYY-MM-DD');}), /**
   * @property {Integer} selectedTabIndex
   * Property to handle selected tab index value
   */selectedTabIndex:0, /**
   * Enable future date as editable
   */userStartDateAsToday:true, /**
   * Enablr scheduled month
   */enableScheduleMonth:true, /**
   * Maintain course active date
   */courseStartDate:null, /**
   * Disable future Date
   */disableFutureDate:true, /**
   * @property {Boolean} isMobileView
   * Property to handle is mobile view
   */isMobileView:(0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.MEDIUM),isValid:_ember['default'].computed('allowTwoDateRangePicker','endDate',function(){var component=this;return component.get('allowTwoDateRangePicker') && component.get('endDate') || !component.get('allowTwoDateRangePicker') && component.get('startDate');}), /**
   * Change start date datepicker navigation month
   */forChangeStartDateNavMonth:false, /**
   * @property {Boolean} hasVideoConference used to toggle activity popup
   */hasVideoConference:false, /**
   * @property {Boolean} isAddActivity used to toggle activity popup
   */isConferenceAllow:false, /**
   * @property {Boolean} enableVideoConference used to enable conference card
   */enableVideoConference:false, // -------------------------------------------------------------------------
// Actions
actions:{close:function close(){var component=this;component.sendAction('closeDatePicker');},submitDate:function submitDate(){var component=this;var startDate=component.get('startDate');var endDate=component.get('endDate');component.sendAction('onScheduleForDate',startDate,endDate);component.send('close');},onSelectTab:function onSelectTab(index){var component=this;component.set('selectedTabIndex',index);},onScheduleEndDate:function onScheduleEndDate(date){var component=this;component.set('endDate',date);},onScheduleStartDate:function onScheduleStartDate(startDate){var component=this;component.set('startDate',startDate);if(component.get('allowTwoDateRangePicker')){component.set('endDate',startDate);}if(!this.get('enableVideoConference') && !component.get('allowTwoDateRangePicker')){component.sendAction('onScheduleForDate',startDate,startDate);}},onScheduleForMonth:function onScheduleForMonth(month){var component=this;var forMonth=month.get('monthNumber');var forYear=month.get('monthYear');component.sendAction('onScheduleForMonth',forMonth,forYear);}, /**
     * @function onAddActivity  add class activity
     */onAddSheduledActivity:function onAddSheduledActivity(content){var startDate=this.get('startDate');var endDate=!this.get('allowTwoDateRangePicker')?startDate:this.get('endDate');var beginningTime=moment(content.meetingStartTime,'h:mma');var endTime=moment(content.meetingEndTime,'h:mma');if(!beginningTime.isBefore(endTime)){var endAddDate=moment(startDate).add(1,'days').toDate();endDate = moment(endAddDate).format('YYYY-MM-DD');}if(endDate === undefined && beginningTime.isBefore(endTime)){endDate = this.get('startDate');}content.set('meetingStartTime',(0,_gooruWebUtilsUtils.formatimeToDateTime)(startDate,content.get('meetingStartTime')));content.set('meetingEndTime',(0,_gooruWebUtilsUtils.formatimeToDateTime)(endDate,content.get('meetingEndTime')));if(this.get('allowTwoDateRangePicker')){this.sendAction('onScheduleForDate',startDate,endDate,content);}else {this.sendAction('onScheduleForDate',startDate,startDate,content);}}, /**
     * @function onToggleCheckbox  action trigger when click toggle checkbox
     */onToggleCheckbox:function onToggleCheckbox(){this.set('isConferenceAllow',true);}, /**
     * @function onDeny  action trigger when click deny
     */onDeny:function onDeny(){this.set('isConferenceAllow',false);this.set('hasVideoConference',false);}, /**
     * @function onDeny  action trigger when click deny
     */onAllow:function onAllow(){this.set('isConferenceAllow',false);this.set('hasVideoConference',true);}},cardThumbnail:_ember['default'].computed('selectedClassActivity',function(){return this.get('selectedClassActivity.collection.thumbnailUrl') || this.get('selectedClassActivity.thumbnailUrl');}),contentType:_ember['default'].computed('selectedClassActivity',function(){return this.get('selectedClassActivity.format') || this.get('selectedClassActivity.contentType');}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */taxonomyTags:_ember['default'].computed('selectedClassActivity.standards.[]',function(){var standards=this.get('selectedClassActivity.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);})});});