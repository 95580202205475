define('gooru-web/controllers/forgot-password',['exports','ember','gooru-web/models/forgot-password','gooru-web/config/environment'],function(exports,_ember,_gooruWebModelsForgotPassword,_gooruWebConfigEnvironment){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Session service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// Actions
actions:{resetPassword:function resetPassword(){var controller=this;var user=controller.get('user');var errorMessage=controller.get('i18n').t('forgot-password.error-email-not-exists').string;if(controller.get('didValidate') === false){var email=_ember['default'].$('.gru-input.email input').val();user.set('email',email);controller.set('userEmail',email);}user.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){controller.get('profileService').forgotPassword(user.get('email')).then(function(){controller.set('didValidate',true);controller.set('showSecondStep',true);},function(error){ // This error handler was added because PhantomJS is not handling the validation as Chrome does
controller.set('emailError',error.email_id || errorMessage);controller.keydownEvents();});}},function(error){ // This error handler was added because PhantomJS is not handling the validation as Chrome does
controller.set('emailError',error.email_id || errorMessage);controller.keydownEvents();});}}, // -------------------------------------------------------------------------
// Methods
/**
   * init and reset all the properties for the validations
   */resetProperties:function resetProperties(){var controller=this;var user=_gooruWebModelsForgotPassword['default'].create(_ember['default'].getOwner(this).ownerInjection(),{email:null});controller.set('user',user);controller.set('showSecondStep',false);controller.set('didValidate',false);controller.set('emailError',false);}, /**
   * Add keydown events to clear errors on username and email
   */keydownEvents:function keydownEvents(){var controller=this;var $email=_ember['default'].$('.email input');$email.unbind('keydown');$email.on('keydown',function(){controller.set('emailError',false);});}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {String} Error with email field
   */emailError:false, /**
   * @type {User} user
   */user:null, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false, /**
   * @param {Boolean } showSecondStep - value used to check if Second Step is showing or not
   */showSecondStep:false,isGoogleAccountError:_ember['default'].computed('user.validations.attrs.email.isValid',function(){var valid=this.get('user.validations.attrs.email.isValid');var message=this.get('user.validations.attrs.email.error.message');return !valid && message && message.indexOf('Google') >= 0;}),isRTL:_ember['default'].computed('Env.APP.isRTL',function(){return _gooruWebConfigEnvironment['default'].APP.isRTL;})});});