define('gooru-web/components/teacher/offline-class/oca-summary-report-pull-up',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({classNames:['oca-summary-report-pull-up'], /**
   * @requires service:api-sdk/analytics
   */analyticsService:_ember['default'].inject.service('api-sdk/analytics'), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array<Object>}
   */reportData:null, /**
   * Propery of class id
   * @property {Number}
   */classId:null, /**
   * @property {Object}
   */performanceSummary:null, /**
   * @property {Number}
   */timeSpent:null, /**
   * Propery to hide the default pullup.
   * @property {Boolean}
   */showPullUp:false, /**
   * @property {Boolean}
   */isShowOCAMonthReportPullUp:false, /**
   * @property {Object}
   */selectedMonthSummary:null, /**
   * @property {Array} secondaryClasses
   * Property for list of secondary classes
   */secondaryClasses:_ember['default'].A([]),primaryClass:null, /**
   * @property {Object} activeClass
   * Property for active class object
   */activeClass:_ember['default'].computed('secondaryClasses',function(){var component=this;var activeClass=null;var secondaryClasses=component.get('secondaryClasses');if(component.get('isMultiClassEnabled') && secondaryClasses.length){activeClass = secondaryClasses.findBy('isPrimaryClass',true);}return activeClass;}), /**
   * @property {UUID} activeClassId
   * Property for active class id
   */activeClassId:_ember['default'].computed.alias('activeClass.id'), /**
   * @property {Boolean} isPrimaryClassActive
   * Property to check whether primary class is active or not
   */isPrimaryClassActive:_ember['default'].computed('activeClass',function(){return this.get('isMultiClassEnabled')?this.get('activeClass.isPrimaryClass'):true;}), /**
   * @property {Object} secondaryClassPerformanceSummary
   * Propery for active secondary class performance summary
   */secondaryClassPerformanceSummary:_ember['default'].computed('reportData',function(){var component=this;var reportData=component.get('reportData');var performanceSummary=_ember['default'].Object.create({performance:_ember['default'].Object.create({scoreInPercentage:null})});if(reportData && reportData.length){var score=0;reportData.map(function(monthlyReportSummary){score += monthlyReportSummary.get('scoreInPercentage');});performanceSummary.set('performance.scoreInPercentage',score);}return performanceSummary;}), /**
   * @property {Object} classPerformanceSummary
   * Property for active class peroformance summary
   */classPerformanceSummary:_ember['default'].computed('isPrimaryClassActive','secondaryClassPerformanceSummary','performanceSummary',function(){return this.get('isPrimaryClassActive')?this.get('performanceSummary'):this.get('secondaryClassPerformanceSummary');}),isShowAddData:false,showAddDataObserver:_ember['default'].observer('isShowAddData',function(){if(!this.get('isShowAddData')){this.getYearlySummaryData();}}),actions:{onPullUpClose:function onPullUpClose(){var component=this;component.closePullUp();},closePullUp:function closePullUp(closeAll){var component=this;component.set('isShowOCAMonthReportPullUp',false);if(closeAll){component.closePullUp();}},onSelectMonth:function onSelectMonth(selectedSummaryItem){var component=this;component.set('selectedMonthSummary',selectedSummaryItem);component.set('isShowOCAMonthReportPullUp',true);},onToggleClassListContainer:function onToggleClassListContainer(){var component=this;component.$('.secondary-classes .class-container').slideToggle();},onSelectClass:function onSelectClass(selectedClass){var component=this;component.set('activeClass',selectedClass);component.getYearlySummaryData();},onOpenPerformanceEntry:function onOpenPerformanceEntry(activityClass){var component=this;component.sendAction('onOpenPerformanceEntry',activityClass);}}, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.openPullUp();this.handleAppContainerScroll();},didDestroyElement:function didDestroyElement(){this.handleAppContainerScroll();},init:function init(){var component=this;component._super.apply(component,arguments);component.getYearlySummaryData();}, /**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.set('showPullUp',true);component.$().animate({top:'10%'},400);}, /**
   * Function to animate the  pullup from top to bottom
   */closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);component.sendAction('onClosePullUp');});}, /**
   * Function to hanle the pullup scroll
   */handleAppContainerScroll:function handleAppContainerScroll(){var activePullUpCount=_ember['default'].$(document.body).find('.backdrop-pull-ups').length;if(activePullUpCount > 0){_ember['default'].$(document.body).addClass('no-vertical-scroll');}else if(activePullUpCount === 0){_ember['default'].$(document.body).removeClass('no-vertical-scroll');}}, /**
   * Function to get yearly summary report
   */getYearlySummaryData:function getYearlySummaryData(){var component=this;var classId=component.get('activeClassId') || component.get('classId');var dcaYearlySummaryPromise=component.get('analyticsService').getDCAYearlySummary(classId);_ember['default'].RSVP.hash({dcaYearlySummary:dcaYearlySummaryPromise}).then(function(hash){var dcaYearlySummary=hash.dcaYearlySummary;var summarySortedByMonth=dcaYearlySummary.sortBy('month').sortBy('year').reverse();component.set('reportData',summarySortedByMonth);if(component.get('selectedMonthSummary')){(function(){var selectedMonthSummary=component.get('selectedMonthSummary');var existingSummary=summarySortedByMonth.find(function(item){return item.month === selectedMonthSummary.month && item.year === selectedMonthSummary.year;});if(existingSummary){component.set('selectedMonthSummary',existingSummary);}})();}component.calculateTimeSpent();});}, /**
   * Function to calculate timespent
   */calculateTimeSpent:function calculateTimeSpent(){var component=this;var reportData=component.get('reportData');var timeSpent=null;reportData.forEach(function(summary){timeSpent += summary.timeSpent?summary.timeSpent:0;});component.set('timeSpent',timeSpent);}});});