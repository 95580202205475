define('gooru-web/components/content/courses/gru-course-play',['exports','ember','gooru-web/mixins/modal','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/mixins/configuration','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsModal,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebMixinsConfiguration,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],_gooruWebMixinsConfiguration['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','courses','gru-course-play'],classNameBindings:['view'],tagName:'article', // -------------------------------------------------------------------------
// Actions
actions:{view:function view(viewName){this.set('view',viewName);},remix:function remix(){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {var model={content:this.get('course')};this.send('showModal','content.modals.gru-course-remix',model);}}, /**
       * Sets the current course builder location
       * @param unitId
       * @param lessonId
       */setLocation:function setLocation(unitId){var lessonId=arguments.length <= 1 || arguments[1] === undefined?undefined:arguments[1];this.sendAction('onLocationChange',unitId,lessonId);},onCloseWindow:function onCloseWindow(){window.close();}, /**
       * Course button logic for send to previous page state
       */coursePlayBackButton:function coursePlayBackButton(){var component=this;var previewRoute=component.get('isShowFacets')?'facets-search':'library-search';if(component.get('classId') && !component.get('isLibraryContent')){component.get('router').transitionTo('teacher.class.course-map',component.get('classId'));}else {if(component.get('sourceType')){var queryParams={type:component.get('sourceType')};if(component.get('productId') || component.get('libraryId')){if(component.get('productId')){queryParams.productId = component.get('productId');}else if(component.get('libraryId')){queryParams.libraryId = component.get('libraryId');}}component.get('router').transitionTo(previewRoute,{queryParams:queryParams});}else {component.get('router').transitionTo(previewRoute,{queryParams:{profileId:component.get('session.userId'),type:'my-content'}});}}}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
     * Course model as instantiated by the route.
     * @property {Course}
     */course:null, /**
     * @property {Profile[]} remixedUsers
     */remixedUsers:null, /**
     * @property {Profile[]} createdUsers
     */createdUsers:null, /**
     * @property {TaxonomyTag[]} List of taxonomy tags
     */tags:_ember['default'].computed('course.taxonomy.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('course.taxonomy'),false);}), /**
     * @property {Boolean} isOwner
     */isOwner:null, /**
     * @property {string} action name when the location is changed
     */onLocationChange:null, /**
     * @property {string} selected lesson id
     */selectedLessonId:null,isShowFacets:true});});