define('gooru-web/controllers/teacher/class/add-course',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {searchService} Search service object
   */searchService:_ember['default'].inject.service('api-sdk/search'), /**
   * @type {libraryService} Library service object
   */libraryService:_ember['default'].inject.service('api-sdk/library'), /**
   * @type {classService} classService
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @type {courseService} courseService
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {ProfileService} Profile service object
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {Controller} Class Controller
   */classController:_ember['default'].inject.controller('teacher.class'), /**
   * @property {Controller} Application Controller
   */applicationController:_ember['default'].inject.controller('application'),session:_ember['default'].inject.service('session'),tenantService:_ember['default'].inject.service('api-sdk/tenant'), /**
   * @dependency {i18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Events
initialize:function initialize(){var controller=this;controller.set('isLoading',true);controller.fetchFeaturedCourses();}, //Action triggered when add premium course
updateGapProfile:function updateGapProfile(classId){var controller=this;var classDeatils=controller.get('class');var updateDeatils=_ember['default'].Object.create({grade_current:classDeatils.get('gradeCurrent'),grade_lower_bound:classDeatils.get('gradeLowerBound'),grade_upper_bound:classDeatils.get('gradeUpperBound'),preference:classDeatils.get('preference'),route0:true});controller.get('classService').classSettings(classId,updateDeatils);}, // -------------------------------------------------------------------------
// Actions
actions:{paginateNext:function paginateNext(){var controller=this;var catalogLibrary=controller.get('selectedCatalog');if(!controller.get('noMoreData')){controller.incrementProperty('page');return _ember['default'].RSVP.hash({libraryContents:controller.isLibrary?controller.fetchLibraryCourses(catalogLibrary):controller.searchCourses('*',controller.get('scopeKey'))}).then(function(_ref){var libraryContents=_ref.libraryContents;if(libraryContents && libraryContents.length > 0){var libraryContext=controller.get('libraryCourses').concat(libraryContents);controller.set('libraryCourses',libraryContext);controller.set('noMoreData',false);}else {controller.set('noMoreData',true);}});}}, // Action triggered when add a course
onAddCourse:function onAddCourse(courseId,course){var controller=this;controller.set('isShowpopup',false);var popupTabIndex=course && course.tabindex?course.tabindex:10001;this.set('popupTabIndex',popupTabIndex);var classId=controller.get('classId');var isPremiumClass=controller.get('isPremiumClass');controller.addCourseToClass(courseId).then(function(data){if(data && data.status === 400){controller.set('isShowpopup',true);return;}if(isPremiumClass){controller.updateGapProfile(classId);}if(course && course.isPublished){controller.get('classService').updateContentVisibility(classId,null,controller.get('isShowContentVisibility'));controller.get('classController').loadCourseContentVisibility(courseId);}controller.transitionToRoute('teacher.class.course-map',classId,{queryParams:{refresh:true}});});},onShowPop:function onShowPop(){this.set('isShowpopup',false);}, //Action triggered when remix a course
onRemixCourse:function onRemixCourse(courseId,course){var controller=this;controller.set('isShowpopup',false);var classId=controller.get('classId');var popupTabIndex=course && course.tabindex?course.tabindex:10001;this.set('popupTabIndex',popupTabIndex);controller.remixCourse(courseId).then(function(data){if(data && data.status === 400){controller.set('isShowpopup',true);return;}controller.transitionToRoute('teacher.class.course-map',classId,{queryParams:{refresh:true}});});}, //Action triggered when select a catalog type
onSelectCatalog:function onSelectCatalog(catalogLibrary,tabindex){var controller=this;controller.send('resetValue');controller.set('noMoreData',false);var dataSource=catalogLibrary.id;var customTabIndex=tabindex?tabindex:10001;if(dataSource === 'catalog'){controller.set('isLibrary',false);controller.searchCourses().then(function(searchCourses){controller.set('libraryCourses',searchCourses);});}else if(dataSource === 'content'){controller.set('isLibrary',false);controller.searchCourses('*','my-content').then(function(userCourses){controller.set('libraryCourses',userCourses);});}else {controller.set('isLibrary',true);controller.fetchLibraryCourses(catalogLibrary).then(function(libraryCourses){controller.set('libraryCourses',libraryCourses);});}controller.set('actionTabIndex',customTabIndex);controller.set('isShowSearchCoursePullUp',true);controller.set('selectedCatalog',catalogLibrary);}, // Action triggered when search course with a text
onSearchCourse:function onSearchCourse(searchText){var controller=this;var selectedCatalog=controller.get('selectedCatalog');var isCatalogSearch=selectedCatalog.id === 'catalog';if(isCatalogSearch){controller.searchCourses(searchText).then(function(searchCourses){controller.set('libraryCourses',searchCourses);});}}, //Action triggered when click show more results
showMoreResults:function showMoreResults(){var controller=this;var contentCatalogs=controller.get('contentCatalogs');controller.set('catalogListToShow',contentCatalogs);controller.set('isShowMoreCatalogs',false);},resetValue:function resetValue(){this.set('page',0);}}, // Observe libraries property changes
observeLibraries:_ember['default'].observer('libraries',function(){var controller=this;var libraries=controller.get('libraries');var contentCatalogs=controller.get('contentCatalogs');contentCatalogs = contentCatalogs.concat(libraries);controller.set('contentCatalogs',contentCatalogs);}), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isShowSearchCoursePullUp
   */isShowSearchCoursePullUp:false, /**
   * @property {Number} actionTabIndex
   */actionTabIndex:null, /**
   * @property {Number} popupTabIndex
   */popupTabIndex:null, /**
   * @property {Class}
   */'class':_ember['default'].computed.alias('classController.class'), /**
   * @property {classId}
   */classId:_ember['default'].computed.alias('class.id'), /**
   * @property {UUID} userId
   */userId:_ember['default'].computed.alias('session.userId'),noMoreData:false,isLibrary:false, /**
   * @property {Array} contentCatalogs
   */contentCatalogs:_ember['default'].computed('applicationController',function(){var controller=this;var tenantLogo=controller.get('tenantLogo');var userAvatar=controller.get('userDetails.avatarUrl');return _ember['default'].A([{id:'catalog',name:'LearningNav Catalog',image:tenantLogo},{id:'content',name:'My Content',image:userAvatar}]);}), /**
   * @property {Array} catalogListToShow
   */catalogListToShow:_ember['default'].computed('contentCatalogs',function(){var controller=this;var catalogListToShow=controller.get('contentCatalogs');if(catalogListToShow.length > 4){catalogListToShow = catalogListToShow.slice(0,4);controller.set('isShowMoreCatalogs',true);}return catalogListToShow;}), /**
   * @property {Number} classGrade
   */classGrade:_ember['default'].computed('class',function(){var controller=this;var classData=controller.get('class');return classData.grade;}),page:0,pagesize:20, /**
   * @property {tenantLogo}
   */tenantLogo:_ember['default'].computed.alias('applicationController.tenant.theme.header.logo'), /**
   * @property {Object} userDetails
   */userDetails:_ember['default'].computed.alias('applicationController.profile'), /**
   * @property {Boolean} isShowMoreCatalogs
   */isShowMoreCatalogs:false, /**
   * @property {Object} classPreference
   */classPreference:_ember['default'].computed.alias('class.preference'), /**
   * The class is premium or not
   * @property {Boolean}
   */isPremiumClass:_ember['default'].computed('class',function(){var controller=this;var currentClass=controller.get('class');var setting=currentClass.get('setting');return setting?setting['course.premium']:false;}),isMyContentNonPremium:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());return tenantSettings && tenantSettings.class_add_course_filter_out_non_premium && tenantSettings.class_add_course_filter_out_non_premium === 'on';}),isShowContentVisibility:_ember['default'].computed(function(){var tenantSetting=this.get('tenantService').getStoredTenantSetting();var parsedTenantSettings=JSON.parse(tenantSetting);if(parsedTenantSettings && parsedTenantSettings.default_class_course_content_visibility !== undefined){return parsedTenantSettings && parsedTenantSettings.default_class_course_content_visibility;}else {return 'off';}}),isShowpopup:false, // -------------------------------------------------------------------------
// Methods
/**
   * Map each course to their corresponding owner
   * @param {Course[]} course list
   * @param {Owner[]} owner list
   */mapOwners:function mapOwners(courses,owners){var ownerMap={};owners.forEach(function(owner){ownerMap[owner.id] = owner;});var mappedCourses=courses.map(function(course){course.owner = ownerMap[course.ownerId];return course;});return mappedCourses;}, /**
   * @function fetchFeaturedCourses
   * Method to fetch featured courses
   */fetchFeaturedCourses:function fetchFeaturedCourses(){var controller=this;var searchService=controller.get('searchService');var classGrade=controller.get('classGrade');var isPremiumClass=controller.get('isPremiumClass');var subject=this.get('classPreference.subject') || null;var framework=this.get('classPreference.framework') || null;var primaryLanguage=controller.get('class.primaryLanguage');var languagePreference=controller.get('userPreference.language_preference');var filters={};if(this.get('isMyContentNonPremium')){filters['flt.version'] = 'premium';}if(isPremiumClass){filters['flt.subject'] = framework + '.' + subject;filters['flt.grade'] = classGrade;}else {if(subject && framework){filters['flt.subject'] = framework + '.' + subject;}else if(subject){filters['flt.subject'] = '' + subject;}}if(primaryLanguage || languagePreference){filters['flt.languageId'] = primaryLanguage?primaryLanguage:languagePreference[0];}if(framework){filters['flt.frameworkCode'] = framework;}return _ember['default'].RSVP.hash({featuredCourses:_ember['default'].RSVP.resolve(searchService.searchFeaturedCourses('*',filters))}).then(function(_ref2){var featuredCourses=_ref2.featuredCourses;controller.set('featuredCourses',featuredCourses);controller.set('isLoading',false);});}, /**
   * @function copyCourse
   * Method to copy a course
   */copyCourse:function copyCourse(courseId){var controller=this;var courseService=controller.get('courseService');return _ember['default'].RSVP.hash({newCourseId:_ember['default'].RSVP.resolve(courseService.copyCourse(courseId))}).then(function(_ref3){var newCourseId=_ref3.newCourseId;return newCourseId;});}, /**
   * @function remixCourse
   * Method to remix a course
   */remixCourse:function remixCourse(courseId){var controller=this;return controller.copyCourse(courseId).then(function(copiedCourseId){return controller.addCourseToClass(copiedCourseId);});}, /**
   * @function addCourseToClass
   * Method to add a course to a class
   */addCourseToClass:function addCourseToClass(courseId){var controller=this;var classId=controller.get('classId');var classService=controller.get('classService');return _ember['default'].RSVP.resolve(classService.associateCourseToClass(courseId,classId));}, /**
   * @function searchCourses
   * Method to search courses
   */searchCourses:function searchCourses(){var filters=arguments.length <= 0 || arguments[0] === undefined?'*':arguments[0];var scopeKey=arguments.length <= 1 || arguments[1] === undefined?'gooru-catalog':arguments[1];var controller=this;var searchService=controller.get('searchService');var subject=this.get('classPreference.subject') || null;var framework=this.get('classPreference.framework') || null;var isPremiumClass=this.get('isPremiumClass');var page=this.get('page');var pagesize=this.get('pagesize');this.set('scopeKey',scopeKey,'searchCourses');var filter={scopeKey:scopeKey,page:page,pageSize:pagesize};if(this.get('isMyContentNonPremium')){filter['flt.version'] = 'premium';}if(isPremiumClass){filter['flt.subject'] = framework + '.' + subject;}return _ember['default'].RSVP.hash({catalogCourses:_ember['default'].RSVP.resolve(searchService.searchCourses(filters,{page:page},false,filter))}).then(function(_ref4){var catalogCourses=_ref4.catalogCourses;return catalogCourses;});}, /**
   * @function fetchLibraryCourses
   * Method to fetch library courses
   */fetchLibraryCourses:function fetchLibraryCourses(library){var controller=this;var searchService=controller.get('searchService');var subject=this.get('classPreference.subject') || null;var framework=this.get('classPreference.framework') || null;var isPremiumClass=this.get('isPremiumClass');var page=this.get('page');var filter={scopeKey:'open-library',scopeTargetNames:library.get('shortName')};if(this.get('isMyContentNonPremium')){filter['flt.version'] = 'premium';}if(isPremiumClass){filter['flt.subject'] = framework + '.' + subject;}return _ember['default'].RSVP.hash({libraryContents:_ember['default'].RSVP.resolve(searchService.searchCourses('*',{page:page},false,filter))}).then(function(_ref5){var libraryContents=_ref5.libraryContents;return libraryContents;});}});});