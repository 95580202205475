define('gooru-web/components/player/gru-player-summary-footer',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Attributes
classNames:['gru-player-summary-footer'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered for the next button
     */parentNext:function parentNext(){this.sendAction('parents');}, /**
     * Action triggered for the next button hover
     */onShowFeedback:function onShowFeedback(){this.set('isShowFeedback',true);}, /**
     * Action triggered for the next button move out
     */onCloseFeedback:function onCloseFeedback(){this.set('isShowFeedback',false);}, /**
     * Action triggered when toggle screen mode
     */onToggleScreen:function onToggleScreen(){var component=this;component.sendAction('onToggleScreen');},onExit:function onExit(route,id){var component=this;var isIframeMode=component.get('isIframeMode');if(isIframeMode){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}else {component.sendAction('onExit',route,id);}},showTimer:function showTimer(){this.sendAction('showTimer');},showFeedbackContainer:function showFeedbackContainer(){this.sendAction('showFeedbackContainer');},onClosePlayer:function onClosePlayer(){this.sendAction('onClosePlayer');},goBack:function goBack(){this.sendAction('goBack');}}});});