define('gooru-web/components/cards/suggest-confirmation',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['cards','suggest-confirmation'],session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.$('[data-toggle=popover]').popover({html:true,content:function content(){return component.$('#suggestion-profile-details').html();}});}, // -------------------------------------------------------------------------
// Properties
/**
   * collection object
   * @type {Object}
   */collection:null, /**
   * Students list for suggestion
   * @type {Array}
   */students:_ember['default'].A([]), /**
   * Maintains collection type
   * @type {String}
   */contentType:null, /**
   * more items number
   * @type {Number}
   */moreStudentsNumber:_ember['default'].computed('students',function(){return this.get('students').length - this.get('defaultListStudentNumbers');}), /**
   * default list student count
   * @type {Number}
   */defaultListStudentNumbers:3, /**
   * Defalut list students
   * @return {Array}
   */defaultStudentList:_ember['default'].computed('students',function(){return this.get('students').slice(0,this.get('defaultListStudentNumbers'));}), /**
   * Checking is demo account
   */isGuestAccount:_ember['default'].computed.alias('session.isGuest'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Trigger when cancel suggest  popup
     */onCancelSuggest:function onCancelSuggest(){this.sendAction('onCancelSuggest');}, /**
     * Trigger when confirm suggest co popup
     */onConfirmSuggest:function onConfirmSuggest(){this.sendAction('onConfirmSuggest');}}});});