define('gooru-web/components/class/activities/gru-class-activities-default-scope-sequence',['exports','ember','gooru-web/config/config','gooru-web/utils/utils','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{classNames:['gru-class-activities-default-scope-sequence'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @return {ScopeAndSequenceService} scopeAndSequenceService
   */scopeAndSequenceService:_ember['default'].inject.service('api-sdk/scope-sequence'), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'), /**
   * @type {tenantService} Service to fetch the tenant related information.
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Object} levelAndActionKeys help to define list of action name for each level
   */levelAndActionKeys:{domainList:{actionKey:'fetchDomainsByGrade',levelKey:'domainList'},topicsList:{actionKey:'fetchTopicsByDomain',levelKey:'topicsList'},competenciesList:{actionKey:'fetchCompetenciesByDomainTopic',levelKey:'competenciesList'}},scopeSeqState:null, /**
   * @property {Object} audienceType help to hold the list of audience
   */audienceType:_gooruWebConfigConfig.SCOPE_AND_SEQUENCE_RESOURCE_TYPE, /**
   * @property {Object} activeContentType it has active content type
   */activeContentType:null, /**
   * @property {Object} primaryClass it has active class details
   */primaryClass:null, /**
   * @property {Object} domainList it has list domains for the grade
   */domainList:_ember['default'].A(), /**
   * @property {Object} classPreference class preference settings
   */classPreference:_ember['default'].computed('primaryClass',function(){var preference=this.get('primaryClass.preference');return preference || null;}), /**
   * @property {String} gradeId class currrent grade
   */gradeId:_ember['default'].computed('primaryClass',function(){return this.get('primaryClass.gradeCurrent');}), /**
   * @property {Array} contentSource is has list of active content source
   */contentSource:_ember['default'].computed.alias('activeSequence'), /**
   * @property {Object} activeScopeAndSequence
   */activeScopeAndSequence:null,onChangeScopeAndSequence:_ember['default'].observer('activeScopeAndSequence',function(){this.loadDomainDetails();}),isBaselineRegularKey:_ember['default'].computed(function(){return this.get('isEnableCaBaseline');}),lasActiveCompetency:null, // -------------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.loadDomainDetails();},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * @action Action trigger when we click on accordion for each level
     * @param {Object} params it has path params for the actions
     * @param {Object} level has level object
     * @param {Object} levelActionKey it has which action need to trigger for the currrent level
     */onSelectLevel:function onSelectLevel(levelActionKey,level,params){params.pathParams = Object.assign({},this.defaultPathParams(),params.pathParams);var scopeSeqState=this.get('scopeSeqState') || {};scopeSeqState[levelActionKey.levelKey] = level.id;this.set('scopeSeqState',scopeSeqState);level.set('isActive',!level.get('isActive'));if(!level.get(levelActionKey.levelKey)){this.loadLevelData(levelActionKey,level,params);}}, /**
     *  @action trigger when click on competency tab
     * @param {Object} competency has selected competency details
     */onToggleCompetency:function onToggleCompetency(competency,idsList){var _this=this;if(!competency.get('isActive')){this.resetProperties('isActive').then(function(){_this.scrollIntoView(idsList.ids);});}this.set('scopeSeqState.lastState',competency.id);(0,_gooruWebUtilsUtils.setScopeAndSequenceState)(this.get('scopeSeqState'));competency.set('isActive',!competency.get('isActive'));if(competency.get('isActive') && !competency.get('hasContent')){this.loadActivitiesContent(competency);this.set('lasActiveCompetency',competency);}}, // Action trigger when click load more teacher or student side
onLoadMore:function onLoadMore(competency,filterKey){this.loadActivitiesContent(competency,filterKey);}, // Action tigger on clicking show preview
onShowContentPreview:function onShowContentPreview(content){this.sendAction('onShowContentPreview',content);}, // Action trigger on click add class activities from right panel
onAddActivityPop:function onAddActivityPop(content){this.sendAction('onAddActivityPop',content);}, // Action trigger on clicking add activities from the popup
onAddActivity:function onAddActivity(content,startDate,endDate){this.sendAction('onAddActivity',content,startDate,endDate);}, // Action helps to maximize minimize the competency tab
maximizePageSize:function maximizePageSize(competency){competency.set('isZoomed',!competency.get('isZoomed'));}}, // ------------------------------------------------------------------------
// Methods
// Method help to handle all the API calls
loadLevelData:function loadLevelData(levelActionKey,level){var _this2=this;var params=arguments.length <= 2 || arguments[2] === undefined?null:arguments[2];var scopeService=this.get('scopeAndSequenceService');var pathParams=params.pathParams;var lastState=(0,_gooruWebUtilsUtils.getScopeAndSequenceState)();var isDefaultShowFW=this.get('isDefaultShowFW');var classFramework=this.get('classPreference.framework'); // Here actionKey is the method name from the {scopeAndSequenceService} service file
scopeService[levelActionKey.actionKey](pathParams,isDefaultShowFW,classFramework).then(function(levelData){level.set('' + levelActionKey.levelKey,levelData);if(level && level.topicsList && level.topicsList.length){level.topicsList.forEach(function(topics){var getTopicStatus=_this2.get('activityStatus.topics')?_this2.get('activityStatus.topics').findBy('code',topics.code):null;topics.set('getTopicStatus',getTopicStatus);});var activeTopicState=lastState?level.topicsList.findBy('id',lastState.competenciesList):null;var topicsList=activeTopicState || level.topicsList[0];var levelAndAction=_this2.get('levelAndActionKeys');var levelActionTriggerKey=levelAndAction.competenciesList;var topicId=topicsList.get('id');var domainId=level.get('id');var pathParamscompetencies={pathParams:{topicId:topicId,domainId:domainId}};_this2.send('onSelectLevel',levelActionTriggerKey,topicsList,pathParamscompetencies);}if(level && level.competenciesList && level.competenciesList.length){level.competenciesList.forEach(function(competencies){var getCompetencyStatus=_this2.get('activityStatus.competencies')?_this2.get('activityStatus.competencies').findBy('displayCode',competencies.code):null;competencies.set('getCompetencyStatus',getCompetencyStatus);});var activeCompState=lastState?level.competenciesList.findBy('id',lastState.lastState):null;var competenciesList=activeCompState || level.competenciesList[0];var domainIndex=0;var topicIndex=0;var compIndex=0;var idsList={ids:{domainIndex:domainIndex,topicIndex:topicIndex,compIndex:compIndex}};_this2.send('onToggleCompetency',competenciesList,idsList);}});}, // Method help to load collection based on competency
loadActivitiesContent:function loadActivitiesContent(competency){var filterKey=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var audienceType=this.get('audienceType');var filterKeys=[filterKey];if(filterKey){competency.incrementProperty(audienceType[filterKey].name + 'Page');}if(!competency.get('hasContent')){competency.setProperties({teacherspage:0,studentspage:0,hasContent:true,teachersContent:_ember['default'].Object.create({}),studentsContent:_ember['default'].Object.create({}),studentsSkipedLevels:[],teachersSkipedLevels:[]});filterKeys = ['TEACHERS','STUDENTS'];}this.sendAction('onSelectCompetency',competency,filterKeys);}, // Method help to initialize component based on grade
loadDomainDetails:function loadDomainDetails(){var _this3=this;var activeSequence=this.get('activeScopeAndSequence');var taxonomyService=this.get('taxonomyService');var lastState=(0,_gooruWebUtilsUtils.getScopeAndSequenceState)();if(activeSequence && !activeSequence.get('domainList')){var classPreference=this.get('classPreference');var params=this.defaultPathParams();if(classPreference && params.fwId && params.subjectId && params.gradeId){taxonomyService.fetchDomains(params.fwId,params.subjectId,activeSequence.id).then(function(domains){activeSequence.set('domainList',domains);activeSequence.get('domainList').map(function(domain){var domainCode=domain.code.split('-').pop();var getDomainStatus=_this3.get('activityStatus.domains')?_this3.get('activityStatus.domains').findBy('code',domainCode):null;domain.set('getDomainStatus',getDomainStatus);});var activeDomainState=lastState?domains.findBy('id',lastState.topicsList):null;var level=domains && domains.length?activeDomainState || domains[0]:'';var levelAndAction=_this3.get('levelAndActionKeys');var levelActionKey=levelAndAction.topicsList;var domainId=level.get('id');var pathParams={pathParams:{domainId:domainId}};_this3.send('onSelectLevel',levelActionKey,level,pathParams);});}}}, // Methods has default path params that needs for all the level
defaultPathParams:function defaultPathParams(){return {fwId:this.get('classPreference.framework'),subjectId:this.get('classPreference.subject'),gradeId:this.get('activeScopeAndSequence.id')};}, // Method help to reset the properties
resetProperties:function resetProperties(propKey){var domainList=this.get('activeScopeAndSequence.domainList') || [];var promiseAll=domainList.map(function(domain){domain.topicsList && domain.topicsList.forEach(function(topics){topics.competenciesList && topics.competenciesList.filterBy('' + propKey,true).forEach(function(competency){competency.set('' + propKey,false);});});});return _ember['default'].RSVP.all(promiseAll);},scrollIntoView:function scrollIntoView(ids){setTimeout(function(){var top=undefined;var container=$('.gru-class-activities-default-scope-sequence');var topPosition=$('#dm-' + ids.domainIndex + '-tp-' + ids.topicIndex + '-cc-' + ids.compIndex).position();top = topPosition?topPosition.top:undefined;if(top){var currentScroll=container.scrollTop();container.animate({scrollTop:currentScroll + top - 85},300);}},200);}});});