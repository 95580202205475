define('gooru-web/components/cards/gru-student-featured-course-card',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {FeaturedCourseService} Service to retrieve Featured Courses Service information
   */featuredCourseService:_ember['default'].inject.service('api-sdk/featured-courses'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-student-featured-course-card'],classNameBindings:['coursedSeq'],coursedSeq:_ember['default'].computed(function(){return 'course-' + this.get('contentSeq');}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click on expand icon
     */toggleContent:function toggleContent(){this.toggleProperty('isExpand');}, /**
     * Action triggered when click on study now button
     */studyNow:function studyNow(){var courseSettings=this.get('course.settings');var gradeCurrent=courseSettings.get('gradeCurrent');this.joinPublicClassbyGrade(gradeCurrent);this.set('isExpand',false);}},didInsertElement:function didInsertElement(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Course} course information
   */course:null, /**
   * @property {Boolean}
   */isExpand:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function joinPublicClassbyGrade
   * Method to join public class by student
   */joinPublicClassbyGrade:function joinPublicClassbyGrade(gradeLowerBound){var _this=this;var courseId=this.get('course.id');var featuredCourseService=this.get('featuredCourseService');return _ember['default'].RSVP.hash({joinClass:_ember['default'].RSVP.resolve(featuredCourseService.joinPublicClassbyGrade(courseId,gradeLowerBound))}).then(function(_ref){var joinClass=_ref.joinClass;_this.sendAction('updateUserClasses'); // Triggers the refresh of user classes in top header
var courseSettings=_this.get('course.settings');if(!courseSettings.get('gradeCurrent')){_this.get('router').transitionTo('student.class.course-map',joinClass.get('classId'));}else {_this.get('router').transitionTo('student.class.proficiency',joinClass.get('classId'));}});}});});