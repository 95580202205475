define('gooru-web/components/reports/pull-up/activity/assessment-performance-data-by-question',['exports','ember','gooru-web/utils/utils','gooru-web/config/config'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['add-data','assessment-performance-data-by-question'], // -------------------------------------------------------------------------
// Serevices
assessmentService:_ember['default'].inject.service('api-sdk/assessment'),performanceService:_ember['default'].inject.service('api-sdk/performance'),analyticsService:_ember['default'].inject.service('api-sdk/analytics'),session:_ember['default'].inject.service('session'),classActivityService:_ember['default'].inject.service('api-sdk/class-activity'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;_ember['default'].RSVP.hash({assessmentPromise:component.get('isAssessment')?component.loadAssessmentData():component.loadExternalAssessmentData()}).then(function(){component.resetStudentScores();component.loadStudentsActivityPerformanceData();});if(component.get('isMobileView')){component.set('isSwitchStudent',true);component.set('rightElementContainer',component.$('.right-container'));}},didRender:function didRender(){var component=this;component.renderMobileView();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when select a student
onSelectStudent:function onSelectStudent(student){var component=this;var activeStudent=component.get('activeStudent');component.set('isSwitchStudent',true);if(component.get('isSessionStarted') && !component.get('isOverwriteScore')){if(activeStudent && activeStudent.get('id') !== student.get('id')){if(!component.doCheckQuestionScoreSubmitted() && component.get('isAssessment')){component.set('isShowWarningMessage',true);}else {component.set('isShowSaveInformation',true);}}}else {component.set('activeStudent',student);component.set('isSessionStarted',false);if(student.get('performance')){component.loadActivityQuestionsPerformanceData();}else {component.resetQuestionScores();}component.scrollToFirstQuestion();}component.set('activeStudentTemp',student);}, //Action triggered when search student list
onSearchStudent:function onSearchStudent(){var component=this;var searchPattern=component.get('studentSearchPattern').toLowerCase();var students=component.get('students');var filteredStudents=students.filter(function(student){return student.get('fullName').toLowerCase().includes(searchPattern);});component.set('studentsList',filteredStudents);}, //Action triggered when toggle question
onToggleQuestion:function onToggleQuestion(questionSeq){var component=this;component.set('isSessionStarted',true);component.toggleQuestionVisibility(questionSeq);}, //Action triggered when dimiss warning popup
onDismissWarning:function onDismissWarning(){var component=this;component.set('isShowWarningMessage',false);component.set('isValidScore',false);}, //Action triggered when clear question scores
onAcceptWarning:function onAcceptWarning(){var component=this;component.resetQuestionScores();component.scrollToFirstQuestion();component.set('activeStudent',component.get('activeStudentTemp'));component.set('isShowWarningMessage',false);component.set('isSessionStarted',false);if(component.get('isOverwriteScore')){component.loadActivityQuestionsPerformanceData();}}, //Action triggered when submit question scores
onAcceptSaveAndNext:function onAcceptSaveAndNext(){var component=this;if(component.get('isOverwriteScore')){component.overwriteAssessmentQuestionScores();}else {if(component.get('isAssessment')){component.submitQuestionDataSelectNextStudent();}else {component.submitExternalAssessmentDataSelectNextStudent();}}component.set('isSessionStarted',false);component.set('isShowSaveInformation',false);}, //Action triggered when dismiss infor popup
onDismissInfoPopup:function onDismissInfoPopup(){var component=this;if(component.get('isAssessment')){component.resetQuestionScores();component.scrollToFirstQuestion();}else {component.set('activeStudent.score',null);}component.set('activeStudent',component.get('activeStudentTemp'));component.set('isShowSaveInformation',false);component.set('isSessionStarted',false);}, //Action triggered when click save and next
onClickSave:function onClickSave(){var component=this;if(!component.doCheckQuestionScoreSubmitted()){component.set('isShowWarningMessage',true);}},onClickNext:function onClickNext(){var component=this;var questions=component.get('questions');var unAnsweredQuestions=questions.filterBy('score',null);if(unAnsweredQuestions && unAnsweredQuestions.length){component.set('isValidScore',true);component.set('isSessionStarted',true);}else {component.set('isValidScore',false);component.set('isSessionStarted',false);component.submitQuestionDataSelectNextStudent();}}, //Action triggered when submit max timespent
onSubmitMaxTimespent:function onSubmitMaxTimespent(){var component=this;var maxHour=component.get('maxHour');var maxMinute=component.get('maxMinute');var maxTimeInMilliSec=(maxHour * 60 * 60 + maxMinute * 60) * 1000;var questionTimespent=maxTimeInMilliSec / component.get('questions.length');component.set('questionTimespent',questionTimespent);component.set('isCaptureQuestionScore',true);}, //Action triggered when clear search student list
onClearSearchStudent:function onClearSearchStudent(){var component=this;component.set('studentSearchPattern','');component.set('studentsList',component.get('students'));}, //Action triggered when click cancel button
onClearQuestionScores:function onClearQuestionScores(){var component=this;component.resetQuestionScores();component.scrollToFirstQuestion();}, //Action triggered when submit external assessment student's score
onSubmitExternalAssessmentStudentScore:function onSubmitExternalAssessmentStudentScore(){var component=this;component.submitExternalAssessmentDataSelectNextStudent();}, //Actio triggered when submit external assessment max score
onSubmitExternalAssessmentMaxScore:function onSubmitExternalAssessmentMaxScore(){var component=this;component.set('isCaptureExternalAssessmentStudentScore',true);}, //Action triggered when click overwrite score
onOverwriteScore:function onOverwriteScore(){var component=this;component.overwriteAssessmentQuestionScores();}, //Action triggered when click skip button
skipAnswer:function skipAnswer(){var component=this;component.activateNextStudent();}}, // -------------------------------------------------------------------------
// Properties
rightElementContainer:null,isAssessment:_ember['default'].computed.equal('assessment.format',_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT), /**
   * @property {Boolean} isEnableSaveNext
   */isEnableSaveNext:false, /**
   * @property {Boolean} isShowSaveInformation
   */isShowSaveInformation:false, /**
   * @property {Object} activeStudent
   */activeStudent:_ember['default'].computed(function(){var component=this;return component.get('students').objectAt(0);}), /**
   * @property {Object} activeStudentTemp
   */activeStudentTemp:_ember['default'].computed('activeStudent',function(){return this.get('activeStudent');}), /**
   * @property {Number} activeQuestionSeq
   */activeQuestionSeq:0, /**
   * @property {Array} studentsList
   */studentsList:_ember['default'].computed('students',function(){return this.get('students');}), /**
   * @property {Array} students
   */students:_ember['default'].A([]), /**
   * @property {Object} assessment
   */assessment:null,isDiagnosticAssessment:false, /**
   * @property {Number} questionTimespent
   */questionTimespent:_ember['default'].computed('activeStudent.performance',function(){if(this.get('activeStudent.performance.timeSpent')){return (this.get('activeStudent.performance')?this.get('activeStudent.performance.timeSpent'):0) / this.get('questions.length');}else {var studentPerformance=this.get('students').find(function(student){if(student.performance){return student.performance;}});return (studentPerformance && studentPerformance.performance && studentPerformance.performance.timeSpent?studentPerformance.performance.timeSpent:0) / this.get('questions.length');}}), /**
   * @property {String} studentSearchPattern
   */studentSearchPattern:'', /**
   * @property {Array} questions
   */questions:_ember['default'].A([]), /**
   * @property {Number} maxHour
   */maxHour:0, /**
   * @property {Number} maxMinute
   */maxMinute:0, /**
   * @property {Boolean} isValidMaxTimespent
   */isValidMaxTimespent:_ember['default'].computed('maxHour','maxMinute',function(){var component=this;var maxHour=component.get('maxHour');var maxMinute=component.get('maxMinute');return (0,_gooruWebUtilsUtils.validateTimespent)(parseInt(maxHour),parseInt(maxMinute));}), /**
   * @property {Boolean} isCaptureQuestionScore
   */isCaptureQuestionScore:_ember['default'].computed.alias('activityData.isUpdatePerformance'), /**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}), /**
   * @property {String} contentSource
   */contentSource:'dailyclassactivity', /**
   * @property {Boolean} isShowClearStudentSearch
   */isShowClearStudentSearch:_ember['default'].computed('studentSearchPattern',function(){var component=this;return component.get('studentSearchPattern.length');}), /**
   * @property {Number} unAnsweredQuestionCount
   */unAnsweredQuestionCount:0, /**
   * @property {Number} externalAssessmentMaxScore
   */externalAssessmentMaxScore:null, /**
   * @property {Boolean} isCaptureExternalAssessmentStudentScore
   */isCaptureExternalAssessmentStudentScore:false, /**
   * @property {Boolean} isValidExternalAssessmentMaxScore
   */isValidExternalAssessmentMaxScore:_ember['default'].computed('externalAssessmentMaxScore',function(){var component=this;var externalAssessmentMaxScore=component.get('externalAssessmentMaxScore');return parseInt(externalAssessmentMaxScore) > 0?(0,_gooruWebUtilsUtils.validatePercentage)(externalAssessmentMaxScore):false;}), /**
   * @property {Boolean} isValidExternalAssessmentStudentScore
   */isValidExternalAssessmentStudentScore:_ember['default'].computed('activeStudent.score',function(){var component=this;var externalAssessmentMaxScore=parseFloat(component.get('externalAssessmentMaxScore'));var studentScore=parseFloat(component.get('activeStudent.score'));component.set('isSessionStarted',true);return studentScore >= 0 && studentScore <= externalAssessmentMaxScore;}), /**
   * @property {Boolean} isLastStudentActive
   */isLastStudentActive:_ember['default'].computed('activeStudent',function(){var component=this;var activeStudent=component.get('activeStudent');var students=component.get('students');return students.indexOf(activeStudent) === students.length - 1;}), /**
   * @property {Boolean} isSessionStarted
   */isSessionStarted:false, /**
   * @property {Boolean} isOverwriteScore
   */isOverwriteScore:_ember['default'].computed('activeStudent.performance',function(){return this.get('activeStudent.performance');}), /**
   * @property {Boolean} isMobileView
   */isMobileView:(0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.MEDIUM), /**
   * Checking activity data are want to updated or add
   * @property {Boolean} isUpdateData
   */isUpdateData:_ember['default'].computed.alias('activityData.isUpdatePerformance'),isSwitchStudent:false, // -------------------------------------------------------------------------
// Functions
/**
   * @function renderMobileView
   * Method to render assessment score container in mobile view
   */renderMobileView:function renderMobileView(){var component=this;if(component.get('isMobileView') && component.get('isSwitchStudent')){component.set('isSwitchStudent',false);component.$('.active-student .student-score-details').append(component.get('rightElementContainer'));}}, /**
   * @function scrollToFirstQuestion
   * Method to scroll to top of the questions list container
   */scrollToFirstQuestion:function scrollToFirstQuestion(){var component=this;component.$('.question-list-container').scrollTop(0);}, /**
   * @function activateNextStudent
   * Method to activate next student
   */activateNextStudent:function activateNextStudent(){var component=this;var students=component.get('students');component.set('isSwitchStudent',true);var activeStudentIndex=students.indexOf(component.get('activeStudent'));if(!component.isDestroyed){if(component.get('activeStudentTemp.id') !== component.get('activeStudent.id')){component.set('activeStudent',component.get('activeStudentTemp'));}else {if(activeStudentIndex !== students.length - 1){component.set('activeStudent',students.objectAt(activeStudentIndex + 1));component.set('activeStudentTemp',students.objectAt(activeStudentIndex + 1));}else {component.sendAction('onClosePullUp');}}}}, /**
   * @function submitQuestionDataSelectNextStudent
   * Method to submit question scores and select next student
   */submitQuestionDataSelectNextStudent:function submitQuestionDataSelectNextStudent(){var component=this;component.submitAssessmentPerformanceData().then(function(){component.loadStudentActivityPerformanceData(component.get('activeStudent'));component.resetQuestionScores();component.activateNextStudent();component.scrollToFirstQuestion();if(component.get('isOverwriteScore')){component.loadActivityQuestionsPerformanceData();}});}, /**
   * @function submitExternalAssessmentDataSelectNextStudent
   * Method to submit external assessment score and select next student
   */submitExternalAssessmentDataSelectNextStudent:function submitExternalAssessmentDataSelectNextStudent(){var component=this;component.submitExternalAssessmentPerformanceData().then(function(){component.loadStudentActivityPerformanceData(component.get('activeStudent'));component.activateNextStudent();});}, /**
   * @function overwriteAssessmentQuestionScores
   * Method to trigger score overwrite method and update student perf score
   */overwriteAssessmentQuestionScores:function overwriteAssessmentQuestionScores(){var component=this;component.updateQuestionScore(component.getOverwriteQuestionScoresPayload()).then(function(){component.loadStudentActivityPerformanceData(component.get('activeStudent'));});}, /**
   * @function loadAssessmentData
   */loadAssessmentData:function loadAssessmentData(){var component=this;var assessment=component.get('assessment');return component.fetchAssessmentData(assessment.get('id')).then(function(assessmentData){component.set('isDiagnosticAssessment',assessmentData.get('enableDiagnostic'));if(!component.isDestroyed){var questions=assessmentData?assessmentData.get('children'):_ember['default'].A([]);component.set('questions',questions);component.resetQuestionScores();return assessmentData;}});}, /**
   * @function loadExternalAssessmentData
   * Method to load external assessment data
   */loadExternalAssessmentData:function loadExternalAssessmentData(){var component=this;var assessment=component.get('assessment');return component.fetchExternalAssessmentData(assessment.get('id')).then(function(assessmentData){if(!component.isDestroyed){component.set('assessment',assessmentData);}return assessmentData;});}, /**
   * @function loadStudentsActivityPerformanceData
   * Method to load all students activity performance data
   */loadStudentsActivityPerformanceData:function loadStudentsActivityPerformanceData(){var component=this;var classActivityService=component.get('classActivityService');var activityData=component.get('activityData');var activityDate=activityData.get('activation_date')?activityData.get('activation_date'):moment().format('YYYY-MM-DD');var endDate=activityData.get('end_date');var classId=component.get('classId');return _ember['default'].RSVP.hash({studentsActivityPerformanceData:classActivityService.fetchStudentsActivityPerformance(classId,activityData,activityDate,endDate)}).then(function(_ref){var studentsActivityPerformanceData=_ref.studentsActivityPerformanceData;component.parseStudentsActivityPerforamanceData(studentsActivityPerformanceData);});}, /**
   * @function loadActivityQuestionsPerformanceData
   * Method to load activity questions performance data
   */loadActivityQuestionsPerformanceData:function loadActivityQuestionsPerformanceData(){var component=this;component.set('isLoading',true);var activeStudent=component.get('activeStudent');component.fetchAssessmentPerformanceBySession(activeStudent.get('performance.sessionId')).then(function(activityQuestionsPerformance){if(activityQuestionsPerformance){component.populateActivityQuestionsPerformance(activityQuestionsPerformance);}});}, /**
   * @function loadStudentActivityPerformanceData
   * Method to load given student activity performance data
   */loadStudentActivityPerformanceData:function loadStudentActivityPerformanceData(student){var component=this;var classActivityService=component.get('classActivityService');var activityData=_ember['default'].A([component.get('activityData')]);var userId=student.get('id');var classId=component.get('classId');var activityDate=component.get('activityData.activation_date')?component.get('activityData.activation_date'):moment().format('YYYY-MM-DD');var endDate=component.get('activityData.end_date');return _ember['default'].RSVP.hash({studentActivityPerformance:classActivityService.findStudentActivitiesPerformanceSummary(userId,classId,activityData,activityDate,endDate)}).then(function(_ref2){var studentActivityPerformance=_ref2.studentActivityPerformance;if(!component.isDestroyed && component.get('isOverwriteScore')){if(student.get('performance')){student.set('performance.score',studentActivityPerformance.objectAt(0).get('collection.performance.score'));}else {student.set('performance',studentActivityPerformance.objectAt(0).get('collection.performance'));}}else {student.set('performance',studentActivityPerformance.objectAt(0).get('collection.performance'));}});}, /**
   * @function fetchAssessmentData
   */fetchAssessmentData:function fetchAssessmentData(assessmentId){var component=this;var assessmentService=component.get('assessmentService');return assessmentService.readAssessment(assessmentId);}, /**
   * @function fetchExternalAssessmentData
   */fetchExternalAssessmentData:function fetchExternalAssessmentData(assessmentId){var component=this;var assessmentService=component.get('assessmentService');return assessmentService.readExternalAssessment(assessmentId);}, /**
   * @function fetchAssessmentPerformanceBySession
   * Method to load assessment performance data by given session ID
   */fetchAssessmentPerformanceBySession:function fetchAssessmentPerformanceBySession(){var component=this;var analyticsService=component.get('analyticsService');var userId=component.get('activeStudent.id');var sessionId=component.get('activeStudent.performance.sessionId');var classId=component.get('classId');var assessmentId=component.get('assessment.id');return analyticsService.getDCAPerformanceBySessionId(userId,classId,assessmentId,'assessment',sessionId);}, /**
   * @function updateQuestionScore
   * Method to update question score
   */updateQuestionScore:function updateQuestionScore(questionsData){var component=this;var analyticsService=component.get('analyticsService');return analyticsService.updateQuestionScore(questionsData);}, /**
   * @function submitAssessmentPerformanceData
   */submitAssessmentPerformanceData:function submitAssessmentPerformanceData(){var component=this;var performanceService=component.get('performanceService');return _ember['default'].RSVP.hash({studentPerformanceUpdated:_ember['default'].RSVP.resolve(performanceService.updateCollectionOfflinePerformance(component.getAssessmentDataParams()))}).then(function(){if(!component.isDestroyed){component.resetQuestionScores();component.set('activeStudent',component.get('activeStudentTemp'));component.set('activeStudent.isSubmitted',true);}});}, /**
   * @function submitExternalAssessmentPerformanceData
   */submitExternalAssessmentPerformanceData:function submitExternalAssessmentPerformanceData(){var component=this;var performanceService=component.get('performanceService');return _ember['default'].RSVP.hash({studentPerformanceUpdated:_ember['default'].RSVP.resolve(performanceService.updateCollectionOfflinePerformance(component.getExternalAssessmentDataParams()))}).then(function(){if(!component.isDestroyed){component.set('activeStudent',component.get('activeStudentTemp'));component.set('activeStudent.isSubmitted',true);}});}, /**
   * @function resetQuestionScores
   */resetQuestionScores:function resetQuestionScores(){var component=this;var questions=component.get('questions');questions.map(function(question){question.set('score',null);question.set('feedbackComment',null);question.set('isScored',false);});}, /**
   * @function toggleQuestionVisibility
   */toggleQuestionVisibility:function toggleQuestionVisibility(){var activePos=arguments.length <= 0 || arguments[0] === undefined?0:arguments[0];var component=this;var questions=component.get('questions');if(questions.length !== activePos){var activeQuestion=questions.objectAt(activePos);questions.map(function(question){return question.set('active',false);});activeQuestion.set('active',!activeQuestion.get('active'));component.set('activeQuestionSeq',activePos);}else {component.set('isEnableSaveNext',true);}}, /**
   * @function doCheckQuestionScoreSubmitted
   */doCheckQuestionScoreSubmitted:function doCheckQuestionScoreSubmitted(){var component=this;var questions=component.get('questions');var unAnsweredQuestions=questions.filterBy('score',null);var numberOfQuestionsNotSubmitted=unAnsweredQuestions.length;component.set('unAnsweredQuestionCount',numberOfQuestionsNotSubmitted);return !numberOfQuestionsNotSubmitted;}, /**
   * @function getAssessmentDataParams
   */getAssessmentDataParams:function getAssessmentDataParams(){var component=this;var questions=component.get('questions');var assessmentResources=_ember['default'].A([]);var activeStudent=component.get('activeStudent');var activityData=component.get('activityData');var activityId=activityData.get('id');var conductedOn=activityData.activation_date?activityData.activation_date:moment().format('YYYY-MM-DD');var classId=component.get('classId');var assessment=component.get('assessment');var courseId=component.get('course')?component.get('course').id:null;var isDiagnosticAssessment=component.get('isDiagnosticAssessment');questions.map(function(question){var resourceData={resource_id:question.get('id'),resource_type:'question',question_type:question.get('type'),score:Number(question.get('score')) || 0,max_score:question.get('maxScore'),time_spent:component.get('questionTimespent'),feedback_comment:question.get('feedbackComment')};var standards=question.get('standards') || [];var standard=standards[0]?standards[0]:null;if(isDiagnosticAssessment && standard){resourceData.standard = standard.id;}assessmentResources.push(resourceData);});var studentPerformanceData={tenant_id:component.get('session.tenantId') || null,student_id:activeStudent.get('id'),session_id:(0,_gooruWebUtilsUtils.generateUUID)(),class_id:classId,collection_id:assessment.get('id'),collection_type:'assessment',content_source:component.get('contentSource'),time_zone:component.get('timeZone'),conducted_on:conductedOn,path_id:0,path_type:null,resources:assessmentResources,course_id:courseId,additionalContext:btoa(JSON.stringify({dcaContentId:activityId}))};if(isDiagnosticAssessment){studentPerformanceData.isDiagnostic = true;}return studentPerformanceData;}, /**
   * @function getExternalAssessmentDataParams
   * Method to construct external assessment data params
   */getExternalAssessmentDataParams:function getExternalAssessmentDataParams(){var component=this;var student=component.get('activeStudent');var conductedOn=component.get('activityData.activation_date')?component.get('activityData.activation_date'):moment().format('YYYY-MM-DD');var maxScore=component.get('externalAssessmentMaxScore');var classId=component.get('classId');var activityId=component.get('activityData.id');var studentPerformanceData={student_id:student.get('id'),tenant_id:component.get('session.tenantId') || null,collection_type:'assessment-external',session_id:(0,_gooruWebUtilsUtils.generateUUID)(),time_zone:component.get('timeZone'),class_id:classId,collection_id:component.get('assessment.id'),partner_id:component.get('session.partnerId') || null,content_source:component.get('contentSource'),score:parseInt(student.get('score')) || 0,max_score:parseInt(maxScore) || 0,time_spent:0,conducted_on:conductedOn,additionalContext:btoa(JSON.stringify({dcaContentId:activityId}))};return studentPerformanceData;}, /**
   * @function resetStudentScores
   * Method to reset student scores
   */resetStudentScores:function resetStudentScores(){var component=this;var students=component.get('students');students.map(function(student){student.set('score',null);student.set('feedbackComment',null);});}, /**
   * @function parseStudentsActivityPerforamanceData
   * Method to parse student activity performance data
   */parseStudentsActivityPerforamanceData:function parseStudentsActivityPerforamanceData(studentsActivityPerformanceData){var component=this;var students=component.get('students');students.map(function(student){var studentActivityPerformanceData=studentsActivityPerformanceData.findBy('userId',student.get('id'));student.set('performance',studentActivityPerformanceData?studentActivityPerformanceData.get('collectionPerformanceSummary'):null);});if(component.get('isOverwriteScore')){component.loadActivityQuestionsPerformanceData();}}, /**
   * @function populateActivityQuestionsPerformance
   * Method to populate activity questions performance data
   */populateActivityQuestionsPerformance:function populateActivityQuestionsPerformance(questionsPerformanceData){var component=this;var questionsPerformance=questionsPerformanceData.get('resourceResults');var questions=component.get('questions');if(!component.isDestroyed){questions.map(function(question){var questionPerformance=questionsPerformance.findBy('resourceId',question.get('id'));if(questionPerformance){question.set('score',questionPerformance.get('rawScore'));question.set('feedbackComment',questionPerformance.get('feedbackComment'));question.set('isScored',true);}});component.set('isLoading',false);}}, /**
   * @function getOverwriteQuestionScoresPayload
   * Method to construct overwrite questions score payload
   */getOverwriteQuestionScoresPayload:function getOverwriteQuestionScoresPayload(){var component=this;var questions=component.get('questions');var activeStudent=component.get('activeStudent');var assessment=component.get('assessment');var activityId=component.get('activityData.id');var resourcesContext=_ember['default'].A([]);questions.map(function(question){var resourceContext={resource_id:question.get('id'),score:Number(question.get('score')) || 0,max_score:question.get('maxScore'),feedback_comment:question.get('feedbackComment')};resourcesContext.push(resourceContext);});var qustionsScorePayload={student_id:activeStudent.get('id'),session_id:activeStudent.get('performance.sessionId'),collection_id:assessment.get('id'),class_id:component.get('classId'),collection_type:'assessment',content_source:component.get('contentSource'),additional_context:btoa(JSON.stringify({dcaContentId:activityId})),resources:resourcesContext};return qustionsScorePayload;}});});