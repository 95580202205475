define('gooru-web/services/api-sdk/tenant',['exports','ember','gooru-web/serializers/tenant/tenant','gooru-web/adapters/tenant/tenant'],function(exports,_ember,_gooruWebSerializersTenantTenant,_gooruWebAdaptersTenantTenant){ /**
 * Service to support the Lookup entities
 *
 * Country, State, District
 *
 * @typedef {Object} LookupService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service(),lookupSerializer:null,lookupAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('tenantSerializer',_gooruWebSerializersTenantTenant['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('tenantAdapter',_gooruWebAdaptersTenantTenant['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Gets tenant information by id
   * @returns {Promise.<Tenant>}
   */findTenantById:function findTenantById(id){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){return service.get('tenantAdapter').findTenantById(id).then(function(response){_ember['default'].run(function(){resolve(service.get('tenantSerializer').normalizeTenant(response));});},function(){resolve(undefined); //ignore if the api call fails
});});}, /**
   * @function getTenantSettingKey
   * Method to get tenant setting key based on user UUID
   */getTenantSettingKey:function getTenantSettingKey(){return this.get('session.userId') + '_tenant_setting';}, /**
   * @function getLocalStorage
   * Method to get local storage instance
   */getLocalStorage:function getLocalStorage(){return window.localStorage;}, /**
   * @function getStoredTenantSetting
   * Method to get stored tenant setting
   */getStoredTenantSetting:function getStoredTenantSetting(){return this.getLocalStorage().getItem(this.getTenantSettingKey());}, /**
   * @function removeStoredTenantSetting
   * Method to remove stored tenant setting
   */removeStoredTenantSetting:function removeStoredTenantSetting(){return this.getLocalStorage().removeItem(this.getTenantSettingKey());}, /**
   * Gets tenant information from current session
   * @returns {Promise.<Tenant>}
   */findTenantFromCurrentSession:function findTenantFromCurrentSession(){var tenantId=this.get('session.tenantId');return this.findTenantById(tenantId);},getActiveTenantSetting:function getActiveTenantSetting(){var _this=this;var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var tenantSetting=service.getStoredTenantSetting();if(!tenantSetting){service.get('tenantAdapter').getActiveTenantSetting().then(function(tenantSetting){if(tenantSetting && tenantSetting.tenant_settings){if(!_this.get('session.isAnonymous')){service.getLocalStorage().setItem(service.getTenantSettingKey(),JSON.stringify(tenantSetting.tenant_settings));}resolve(tenantSetting.tenant_settings);}},reject);}else {resolve(JSON.parse(tenantSetting));}});},getActiveTenantSettingFromApi:function getActiveTenantSettingFromApi(){var _this2=this;var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('tenantAdapter').getActiveTenantSetting().then(function(tenantSetting){if(tenantSetting && tenantSetting.tenant_settings){if(!_this2.get('session.isAnonymous')){service.getLocalStorage().setItem(service.getTenantSettingKey(),JSON.stringify(tenantSetting.tenant_settings));}resolve(tenantSetting.tenant_settings);}});});},getActiveRoster:function getActiveRoster(params,classId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('tenantAdapter').getActiveRoster(params,classId).then(function(){resolve();},reject);});},getGrade:function getGrade(params){var service=this;return service.get('tenantAdapter').getGrade(params).then(function(response){return response;});}});});