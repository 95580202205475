define('gooru-web/components/content/modals/gru-add-to-classroom',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {ClassActivityService}
   */classActivityService:_ember['default'].inject.service('api-sdk/class-activity'), /**
   * @property {classService}
   */classService:_ember['default'].inject.service('api-sdk/class'),tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-add-to-classroom'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Select classroom
     */selectClassroom:function selectClassroom(classroom){this.set('selectedClassroom',classroom);var subject=classroom.get('preference.subject');var framework=classroom.get('preference.framework');var cSubject=this.get('model.content.subject');var cFramework=null;var setting=classroom.get('setting');if(setting && !setting['course.premium']){var splitSubject=cSubject && cSubject.split('.');if(splitSubject && splitSubject.length === 3){cFramework = splitSubject[0];cSubject = splitSubject[1] + '.' + splitSubject[2];}if(subject && (!cSubject || cSubject === subject) && framework && (!cFramework || cFramework === framework) || !subject || !cSubject){this.set('isNotAllow',false);return;}this.set('isNotAllow',true);}}, /**
     * Add to classroom or daily class activity
     */addTo:function addTo(){var component=this;var content=component.get('model.content');var isClassActivity=component.get('model.classActivity');var selectedClassroom=component.get('selectedClassroom');var classId=selectedClassroom.get('id');var addedDate=moment().format('YYYY-MM-DD');var collectionType=content.get('format') === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT || _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_COLLECTION?content.get('format'):content.get('collectionType');if(isClassActivity){component.get('classActivityService').addActivityToClass(classId,content.get('id'),collectionType,addedDate).then(function(){component.triggerAction({action:'closeModal'});});}else {component.get('classService').associateCourseToClass(content.get('id'),classId).then(function(){var callback=component.get('model.callback');selectedClassroom.set('courseId',content.get('id'));callback.success();component.triggerAction({action:'closeModal'});});}}}, // -------------------------------------------------------------------------
// Properties
/**
   * Model with the values to use in the modal
   */model:null,isNotAllow:false,isShowContentVisibility:_ember['default'].computed(function(){var tenantSetting=this.get('tenantService').getStoredTenantSetting();var parsedTenantSettings=JSON.parse(tenantSetting);if(parsedTenantSettings && parsedTenantSettings.default_class_course_content_visibility !== undefined){return parsedTenantSettings && parsedTenantSettings.default_class_course_content_visibility;}else {return 'off';}})});});