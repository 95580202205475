define('gooru-web/components/content/modals/gru-course-new',['exports','ember','gooru-web/models/content/course','gooru-web/utils/utils'],function(exports,_ember,_gooruWebModelsContentCourse,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} User service API SDK
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @property {Service} Taxonomy service API SDK
   */taxonomyService:_ember['default'].inject.service('taxonomy'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {limit}
   */ // this should come from config json
limit:400, /**
   * @property {offset}
   */offset:0,selectedPublisher:null, // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-course-new'],classNameBindings:['component-class'], // -------------------------------------------------------------------------
// Actions
actions:{selectCategory:function selectCategory(categoryValue){this.set('course.category',categoryValue);},createCourse:function createCourse(){var component=this;var course=component.get('course');var etlHrs=course.get('hours');var etlMins=course.get('minutes');(0,_gooruWebUtilsUtils.etlSecCalculation)(course,etlHrs,etlMins);course.validate().then((function(_ref){var validations=_ref.validations;if(validations.get('isValid')){component.set('isLoading',true);this.get('courseService').createCourse(course).then(function(course){component.set('isLoading',false);component.triggerAction({action:'closeModal'});component.get('router').transitionTo('content.courses.edit',course.get('id'),{queryParams:{editing:true}});},function(){var message=component.get('i18n').t('common.errors.course-not-created').string;component.get('notifications').error(message);component.set('isLoading',false);});}this.set('didValidate',true);}).bind(this));},onSelected:function onSelected(publisher){this.set('selectedPublisher',publisher);this.set('course.publisherId',publisher.id);},removePublish:function removePublish(){this.set('selectedPublisher',null);this.set('course.publisherId',null);}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);var course=_gooruWebModelsContentCourse['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null});component.set('course',course);component.get('taxonomyService').getCategories().then(function(categories){if(!component.get('isDestroyed')){component.set('categories',categories);}});},didInsertElement:function didInsertElement(){var component=this;var Pubisherlimit={offset:this.get('offset'),limit:this.get('limit')};component.get('courseService').getPublisherList(Pubisherlimit).then(function(pubisherList){component.set('publisherAll',pubisherList);});}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Ember.A} categories - List of course categories
   */categories:_ember['default'].A([]), /**
   * @type {?String} specific class
   */'component-class':null, /**
   * @type {Course} course
   */course:null, /**
   * Indicate if it's waiting for createCourse callback
   */isLoading:false});});