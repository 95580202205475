define('gooru-web/components/cards/gru-student-class-card',['exports','ember','gooru-web/utils/math','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebUtilsMath,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-student-class-card'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Opens the study player
     *
     * @function actions:playCollection
     * @param {string} type - collection or assessment
     * @param {string} item - collection, assessment, lesson or resource
     */playCollection:function playCollection(){var component=this;var currentLocation=component.get('class.currentLocation');var classData=component.get('class');var course=component.get('course');var setting=classData.get('setting');var isPremiumCourse=setting?setting['course.premium'] && setting['course.premium'] === true:false;var isGradeAdded=classData.get('gradeCurrent');if(course){if(isPremiumCourse && !currentLocation && isGradeAdded){component.get('router').transitionTo('student.class.course-map',classData.get('id'));}else {component.sendAction('onPlayCollection',currentLocation,classData);}}else {component.get('router').transitionTo('student.class.class-activities',classData.get('id'));}}, /**
     *
     * Triggered when an menu item is selected
     * @param item
     */selectItem:function selectItem(item){var classId=this.get('class.id');var courseId=this.get('course.id');if(this.get('onItemSelected')){this.sendAction('onItemSelected',item,classId,courseId);}}, /**
     * Action triggered when clear alert message
     */onClearAlert:function onClearAlert(){var component=this;var classData=component.get('class');classData.set('isNotAbleToStartPlayer',false);}},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Class} class information
   */'class':null, /**
   * @property {Course} course information
   */course:null, /**
   * @property {Boolean}
   */isPublicClass:_ember['default'].computed.alias('class.isPublic'), /**
   * @property {Boolean} isCompleted
   */isCompleted:_ember['default'].computed('showCurrentLocation',function(){var controller=this;var showCurrentLocation=controller.get('showCurrentLocation');var currentLocation=controller.get('class.currentLocation');return showCurrentLocation && currentLocation.get('status') === 'complete';}), /**
   * @property {String} current location title
   * Computed property for current Location Title
   */currentLocationTitle:_ember['default'].computed('class.currentLocation',function(){var currentLocation=this.get('class.currentLocation');var pathType=currentLocation.get('pathType');var prepandText=pathType === 'route0'?'Pre-study: ':'';return currentLocation?'' + prepandText + currentLocation.get('collectionTitle'):'';}),collectionType:_ember['default'].computed('class.currentLocation',function(){var component=this;var currentLocation=component.get('class.currentLocation');var collectionType=currentLocation.get('collectionType');return collectionType === 'collection'?'collection':'assessment';}), /**
   * The class is premium
   * @property {String}
   */isPremiumClass:_ember['default'].computed('class',function(){var controller=this;var currentClass=controller.get('class');var classSetting=currentClass.get('setting');return classSetting?classSetting['course.premium']:false;}), /**
   * @property {Boolean}
   * Computed property  to identify class  CM is started or not
   */hasCMStarted:_ember['default'].computed('class.performanceSummary',function(){var scorePercentage=this.get('class.performanceSummary.score');return scorePercentage !== null && (0,_gooruWebUtilsMath.isNumeric)(scorePercentage);}), /**
   * @property {Boolean}
   * Computed property  to identify class CA is started or not
   */hasCAStarted:_ember['default'].computed('class.performanceSummaryForDCA',function(){var scorePercentage=this.get('class.performanceSummaryForDCA.scoreInPercentage');return scorePercentage !== null && (0,_gooruWebUtilsMath.isNumeric)(scorePercentage);}), /**
   * @property {Boolean} cardBackgroundImage
   * Property help to maintain card images
   */cardBackgroundImage:_ember['default'].computed('course',function(){var appRootPath=this.get('appRootPath');var coverImage=this.get('class.coverImage');var thumbnail=coverImage?coverImage:this.get('course.thumbnailUrl');var thumbnailImage=appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.CLASS_DEFAULT;if(thumbnail){thumbnailImage = thumbnail === '/' + _gooruWebConfigConfig.DEFAULT_IMAGES.COURSE?appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.CLASS_DEFAULT:thumbnail;}return thumbnailImage;})});});