define('gooru-web/mixins/reports/player-report-mixin',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config','gooru-web/utils/taxonomy','gooru-web/utils/tenant'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig,_gooruWebUtilsTaxonomy,_gooruWebUtilsTenant){exports['default'] = _ember['default'].Mixin.create({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {ClassService} Service to retrieve class information
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires {AssessmentService} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @requires {CollectionService} Service to retrieve a collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @requires {Ember.Service} session management
   */session:_ember['default'].inject.service('session'), /**
   * @property {Ember.Service} Service to update analytics report.
   */analyticsService:_ember['default'].inject.service('api-sdk/analytics'), /**
   * @requires service:api-sdk/tenant
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), /**
   * @type {AttemptService} attemptService
   * @property {Ember.Service} Service to send attempt related events
   */quizzesAttemptService:_ember['default'].inject.service('quizzes/attempt'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user close the pull up.
     **/onPullUpClose:function onPullUpClose(closeAll){this.closePullUp(closeAll);}, /**
     * Action triggered when update question score
     */onUpdateQuestionScore:function onUpdateQuestionScore(data){this.updateQuestionScore(data);}, /**
     * Selects Performance Option or not
     * @function actions:selectPerformanceOption
     */selectPerformanceOption:function selectPerformanceOption(showPerformance){if(this.get('isTeacher') || !this.get('isAnswerKeyHidden')){this.set('showPerformance',showPerformance);}}}, // -------------------------------------------------------------------------
// Events
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);}, // -------------------------------------------------------------------------
// Properties
/**
   * Indicates the status of the spinner
   * @property {Boolean}
   */isLoading:false, /**
   * Indicates the status of the spinner for until report API is Load
   * @property {Boolean}
   */isReportLoading:false, /**
   * own report or  teacher report page  diffrentiate
   * @property {Boolean}
   */ownReport:false, /**
   * @property {AssessmentResult}
   */assessmentResult:null, /**
   * @property {UserSession[]}
   */completedSessions:[], /**
   * @property {collections[]}
   */collections:_ember['default'].computed('assessmentResult',function(){var component=this;if(component.get('assessmentResult')){return component.get('assessmentResult.collection');}}), /**
   * @type {JSON}
   * Property to store list of skipped rescope content
   */skippedContents:null, /**
   * @type {Boolean}
   * Property to toggle checkbox visibility
   */isChecked:false,showPullUp:false, /**
   * @property {Boolean}
   * Is teacher accessing the report or not
   */isTeacher:_ember['default'].computed('isStudent',function(){return !this.get('isStudent');}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collections.standards.[]',function(){var standards=this.get('collections.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), /**
   * This attribute will decide to show suggestion or not
   * @type {Boolean}
   */showSuggestion:false, /**
   * Maintains the state of show Suggestion pullup
   * @type {Boolean}
   */showSuggestionPullup:false, /**
   * Maintains the state of show OE Report pullup
   * @type {Boolean}
   */showOpenEndedPullup:false, /**
   * @property {JSON}
   */freeResponseContextParams:null, /**
   * @property {boolean} areAnswersHidden - Should answer results be hidden?
   */areAnswersHidden:_ember['default'].computed('collection.isAssessment','collection.showFeedback',function(){return this.get('collection.isAssessment') && this.get('collection.showFeedback') === _gooruWebConfigConfig.ASSESSMENT_SHOW_VALUES.NEVER;}), /**
   * Return ordered non open ended questions array
   * @return {Ember.Array}
   */orderedQuestions:_ember['default'].computed('assessmentResult',function(){if(this.get('assessmentResult')){var resourceResultsOrdered=this.get('assessmentResult.nonOpenEndedQuestionResults');resourceResultsOrdered.sortBy('resource.order');return resourceResultsOrdered;}}), /**
   * check question list  has answered items
   * @type {Boolean}
   */hasAnsweredQuestions:_ember['default'].computed('orderedQuestions','assessmentResult',function(){if(this.get('orderedQuestions')){var resourceResultsOrdered=this.get('orderedQuestions');var correctAnswers=resourceResultsOrdered.findBy('correct','correct');var inCorrectAnswers=resourceResultsOrdered.findBy('correct','incorrect');var skippedAnswers=resourceResultsOrdered.findBy('correct','skipped');var partiallyCorrectAnswers=resourceResultsOrdered.findBy('correct','partially-correct');var attemptedAnswers=resourceResultsOrdered.findBy('correct','attempted');return !!(correctAnswers || inCorrectAnswers || skippedAnswers || partiallyCorrectAnswers || attemptedAnswers);}else {return false;}}), /**
   * Indicate if the table show the performance columns
   *
   * @property {Boolean}
   */showPerformance:true, /**
   * Update question score list
   * @return {Array} list of question scores need to be update.
   */listOfQuestions:_ember['default'].A(), /**
   * @property {boolean} showCorrectAnswer
   * Property to show/hide correct answer toggle
   */showCorrectAnswer:_ember['default'].computed('class',function(){var showCorrectAnswer=true;var currentClass=this.get('class');if(currentClass){var setting=currentClass.get('setting');if(setting){showCorrectAnswer = setting.show_correct_answer || setting['show.correct.answer'];}}return showCorrectAnswer === undefined?true:showCorrectAnswer;}),masteryMinScore:_gooruWebConfigConfig.SCORES.VERY_GOOD, /**
   * @property {boolean} isAnswerKeyHidden - Should the answer key be hidden?
   */isAnswerKeyHidden:_ember['default'].computed('showCorrectAnswer','assessmentResult','masteryMinScore',function(){var component=this;var minScore=component.get('minScore');var showCorrectAnswer=component.get('showCorrectAnswer');var averageScore=component.get('assessmentResult.score');var totalAttempts=component.get('assessmentResult.totalAttempts');var isAssessment=component.get('collections.isAssessment');var isStudent=component.get('isStudent');var masteryMinScore=component.get('masteryMinScore');var attempts=component.get('collections.attempts');if(isAssessment && isStudent){var answerKeyHidden=showCorrectAnswer && (minScore && minScore <= averageScore || averageScore >= masteryMinScore || attempts === totalAttempts);return !answerKeyHidden;}return !component.get('showCorrectAnswer');}), // -------------------------------------------------------------------------
// Methods
/**
   * Function to show the student report
   */showStudentReport:function showStudentReport(){var component=this;var reportData=component.get('reportData');component.set('isAssessment',reportData.type === 'assessment');component.set('isCollection',reportData.type === 'collection');component.set('isLoading',true);this.getStundentCollectionReport(reportData);}, /**
   * @function updateQuestionScore
   * Method to update the question score
   */updateQuestionScore:function updateQuestionScore(questionScoreUpdateData){var component=this;var context=component.getContext(component.get('reportData'));var data=component.buildQuestionScoreUpdatePayLoad(questionScoreUpdateData);var completedSessions=component.get('completedSessions');var totalSessions=completedSessions.length;var session=totalSessions?completedSessions[totalSessions - 1]:null;if(session){ //collections has no session
context.set('sessionId',session.sessionId);data.session_id = session.sessionId;}component.get('analyticsService').updateQuestionScore(data).then(function(){component.loadSession(session).then(function(){return component.set('isChangeScoreEnabled',false);});});}, /**
   * @function loadSession
   * Method to load session from the reportData
   */loadSession:function loadSession(session){var component=this;component.set('isLoading',true);var context=component.getContext(component.get('reportData'));if(session){ //collections has no session
context.set('sessionId',session.sessionId);}var performanceService=component.get('performanceService');return performanceService.findAssessmentResultByCollectionAndStudent(context).then(function(assessmentResult){component.setAssessmentResult(assessmentResult,session);});}, /**
   * @function findCompletionMinScore
   * @return {Number | Promise}
   * Method to find completion min score based on assessment subject
   * and framework tagged with class/tenants frameworks
   * otherwise it will be either teanant's default score or Gooru's default score
   */findCompletionMinScore:function findCompletionMinScore(){var component=this;var assessment=component.get('collection');var assessmentGutCodes=assessment.get('gutCodes');var context=component.getContext(component.get('reportData'));var classId=context.get('classId');if(classId && assessmentGutCodes && assessmentGutCodes.length){return _ember['default'].RSVP.hash({classData:classId?component.get('class'):_ember['default'].RSVP.resolve({}),tenantSetting:component.getTenantSetting()}).then(function(_ref){var classData=_ref.classData;var tenantSetting=_ref.tenantSetting;var assSubject=(0,_gooruWebUtilsTaxonomy.getSubjectId)(assessmentGutCodes[0]);var tenantCompletionScore=(0,_gooruWebUtilsTenant.getMasteryMinScore)(assSubject,classData.preference,tenantSetting);component.set('masteryMinScore',tenantCompletionScore || _gooruWebConfigConfig.SCORES.VERY_GOOD);return tenantCompletionScore || _gooruWebConfigConfig.SCORES.VERY_GOOD;});}return _ember['default'].RSVP.resolve(_gooruWebConfigConfig.SCORES.VERY_GOOD);}, /**
   * @function getTenantSetting
   * Method to get tenant setting for active user
   */getTenantSetting:function getTenantSetting(){return this.get('tenantService').getActiveTenantSetting();}, /**
   * @function getClassData
   * @param {UUID} classId
   * Method to get class data by given class ID
   */getClassData:function getClassData(classId){return this.get('classService').readClassInfo(classId);},isShowExemplarText:function isShowExemplarText(classData){var component=this;var isShowExemplarText=true;if(classData){var tenantSetting=component.get('tenantService').getStoredTenantSetting();var parsedTenantSetting=JSON.parse(tenantSetting);isShowExemplarText = !(classData.setting && classData.setting.show_exemplar_in_report === 'off' || parsedTenantSetting && parsedTenantSetting.show_exemplar_in_report === 'off');return isShowExemplarText;}else {return isShowExemplarText;}}});});