define('gooru-web/components/content/modals/gru-content-suggestion',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-content-suggestion'], // -------------------------------------------------------------------------
// Services
/**
   * @required collectionService
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @required assessmentService
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;var mapLocation=component.get('mapLocation');var suggestedContent=mapLocation.suggestions[0];var currentContentContext=mapLocation.context;var currentItemId=currentContentContext.itemId;component.animateComponent();var currentContentPromise=currentContentContext.itemType === 'assessment'?component.getAssessmentById(currentItemId):component.getCollectionById(currentItemId);currentContentPromise.then(function(currentContent){component.set('suggestedContent',suggestedContent);component.set('currentContent',currentContent);});_ember['default'].$('body').addClass('system-suggested');},willDestroyElement:function willDestroyElement(){this._super.apply(this,arguments);_ember['default'].$('body').removeClass('system-suggested');}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user accept a suggestion
     */onAcceptSuggestion:function onAcceptSuggestion(){var component=this;component.sendAction('onAcceptSuggestion');}, /**
     * Action triggered when the user ignore a suggestion
     */onIgnoreSuggestion:function onIgnoreSuggestion(){var component=this;component.sendAction('onIgnoreSuggestion');}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function animateComponent
   * Method to animate the component
   */animateComponent:function animateComponent(){var component=this;component.$().animate({bottom:'50px'},850);}, /**
   * @function getCollectionById
   * Method to get a collection using ID
   */getCollectionById:function getCollectionById(collectionId){var component=this;var collectionService=component.get('collectionService');return _ember['default'].RSVP.hash({collection:collectionService.readCollection(collectionId)}).then(function(hash){return hash.collection;});}, /**
   * @function getAssessmentById
   * Method to get a assessment by ID
   */getAssessmentById:function getAssessmentById(assessmentId){var component=this;var assessmentService=component.get('assessmentService');return _ember['default'].RSVP.hash({assessment:assessmentService.readAssessment(assessmentId)}).then(function(hash){return hash.assessment;});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('currentContent',function(){var standards=this.get('currentContent.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags which is available in the suggested content
   */suggestedTags:_ember['default'].computed('suggestedContent',function(){var standards=this.get('suggestedContent.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), /**
   * @property {String} suggestedContentType
   * Property for suggested content type
   */suggestedContentType:_ember['default'].computed('suggestedContent.type',function(){var suggestedContent=this.get('suggestedContent');return suggestedContent?suggestedContent.get('type'):_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION;}), /**
   * @property {Boolean} isAssessment
   * Property to check whether suggested content is an assessment or not
   */isAssessment:_ember['default'].computed.equal('suggestedContentType',_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT)});});