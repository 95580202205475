define('gooru-web/components/cards/gru-student-independent-study-card',['exports','ember','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -----------------------------------------------------------------
// Attributes
classNames:['gru-student-independent-study-card'], // ------------------------------------------------------------------------
// Dependencies
/**
   * @type {FeaturedCourseService} Service to retrieve Featured Courses Service information
   */featuredCourseService:_ember['default'].inject.service('api-sdk/featured-courses'), /**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), // ----------------------------------------------------------------------
// Properties
course:null,thumbnailUrl:_ember['default'].computed('course',function(){var course=this.get('course');var randomNumber=parseInt(Math.random() * 3,0);return course?course.thumbnailUrl:_gooruWebConfigConfig.DEFAULT_IMAGES['CLASS_DEFAULT_' + randomNumber];}), // ------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});this._super.apply(this,arguments);}, // --------------------------------------------------------------------------
// Actions
actions:{onGoDashboardPage:function onGoDashboardPage(){this.get('router').transitionTo('student.class.dashboard',this.get('course.id'));}, /**
     * Action triggered when click on study now button
     */studyNow:function studyNow(){var courseSettings=this.get('course.settings');var gradeCurrent=courseSettings.get('gradeCurrent');this.joinPublicClassbyGrade(gradeCurrent);this.set('isExpand',false);var context={classId:this.get('course').id,courseTitle:this.get('course').title,courseId:this.get('course').id,originalCoourseId:this.get('course').originalCourseId,subject:this.get('course').subject,hasJoined:this.get('course').hasJoined,learnerCount:this.get('course').learnerCount};var userId=this.get('session.userId');var lastAccessedClassIdList=JSON.parse(localStorage.getItem('last_accessed_classId_' + userId)) || [];var currentDateTime=new Date().toISOString();var lastSelected={lastSelectedClassId:context.classId,currentDateTime:currentDateTime};lastAccessedClassIdList.push(lastSelected);localStorage.setItem('last_accessed_classId_' + userId,JSON.stringify(lastAccessedClassIdList));var getOwner=_ember['default'].getOwner;var studentClass=getOwner(this).lookup('controller:student.class');studentClass.set('isDisableNavbar',false);}}, // -------------------------------------------------------------------
// Methods
/**
   * @function joinPublicClassbyGrade
   * Method to join public class by student
   */joinPublicClassbyGrade:function joinPublicClassbyGrade(gradeLowerBound){var _this=this;var courseId=this.get('course.id');var featuredCourseService=this.get('featuredCourseService');return _ember['default'].RSVP.hash({joinClass:_ember['default'].RSVP.resolve(featuredCourseService.joinPublicClassbyGrade(courseId,gradeLowerBound))}).then(function(_ref){var joinClass=_ref.joinClass;_this.sendAction('updateUserClasses'); // Triggers the refresh of user classes in top header
var courseSettings=_this.get('course.settings');if(!courseSettings.get('gradeCurrent')){_this.get('router').transitionTo('student.class.course-map',joinClass.get('classId'));}else {_this.get('router').transitionTo('student.class.proficiency',joinClass.get('classId'));}});}});});