define('gooru-web/components/teacher/class/gru-class-navbar',['exports','ember','gooru-web/mixins/configuration','gooru-web/utils/math','gooru-web/mixins/tenant-settings-mixin','gooru-web/mixins/instructional-coache-mixin'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebUtilsMath,_gooruWebMixinsTenantSettingsMixin,_gooruWebMixinsInstructionalCoacheMixin){ /**
 * Teacher class navigation
 *
 * Component responsible for enabling more flexible navigation options for the teacher class.
 * For example, where {@link teacher/class/gru-class-nav-bar.js}} allows access the teacher class information and navigate through the menu options.
 * @module
 * @see controllers/teacher/class.js
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],_gooruWebMixinsTenantSettingsMixin['default'],_gooruWebMixinsInstructionalCoacheMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-class-navbar','teacher'], /**
     * Controls display of notification list, typical use from header is to hide it as required.
     */displayNotificationList:null, // -------------------------------------------------------------------------
// Properties
/**
     * @property {Class} class
     */'class':null, /**
     * @property {selectedSecondaryClass} selectedSecondaryClass
     */selectedSecondaryClass:null, /**
     * @property {SecondaryClass} secondaryClass
     */secondaryClass:null, /**
     * @property {String|Function} onItemSelected - event handler for when an menu item is selected
     */onItemSelected:null, /**
     * @property {String} selectedMenuItem - menu Item selected
     */selectedMenuItem:null, /**
     * @property {boolean|Function} onCollapseExpandClicked - event handler for when the toggle button is clicked
     */onCollapseExpandClicked:null, /**
     * @property {boolean} toggleState - indicates the toggle button state, false means open, true means closed
     */toggleState:false,navTitle:null, /**
     * @property {Boolean}
     * Computed property  to identify class CM is started or not
     */hasCMStarted:_ember['default'].computed('class.performanceSummary',function(){var scorePercentage=this.get('class.performanceSummary.score');return scorePercentage !== null && (0,_gooruWebUtilsMath.isNumeric)(scorePercentage);}),isInstructionalCoache:_ember['default'].computed(function(){return this.instructionalCoache();}), /**
     * @property {Object}
     * property  performanceSummaryForDCA
     */performanceSummaryForDCA:null, /**
     * @property {Boolean}
     * Computed property  to identify class CA is started or not
     */hasCAStarted:_ember['default'].computed('performanceSummaryForDCA',function(){var scorePercentage=this.get('performanceSummaryForDCA.performance.scoreInPercentage');return scorePercentage !== null && (0,_gooruWebUtilsMath.isNumeric)(scorePercentage);}),isPrimaryClass:_ember['default'].computed('secondaryClass','class',function(){var secondaryClass=this.get('secondaryClass.isSecondaryClass')?this.get('secondaryClass'):null;var primaryClass=secondaryClass?secondaryClass.get('id') === this.get('class.id'):true;return primaryClass;}),secondaryClasses:_ember['default'].A([]), /**
     * @property {Boolean} isMenuExpanded
     * Property to toggle nav bar
     */isMenuExpanded:true, // -------------------------------------------------------------------------
// Actions
actions:{onOpenLearningTools:function onOpenLearningTools(){var component=this;component.sendAction('onOpenLearningTools');},onOpenCourseReport:function onOpenCourseReport(){var component=this;component.sendAction('onOpenCourseReport');},onOpenPerformanceReport:function onOpenPerformanceReport(){var component=this;if(component.get('isPremiumClass')){component.sendAction('onOpenCompetencyReport');}else {component.sendAction('onOpenCourseReport');}},onOpenOCAReport:function onOpenOCAReport(){var component=this;component.sendAction('onOpenOCAReport');}, /**
       *
       * Triggered when an menu item is selected
       * @param item
       */selectItem:function selectItem(item){this.set('class.isDisable',false);var isPremiumClass=this.get('isPremiumClass');if(item !== this.get('selectedMenuItem')){this.set('selectedSecondaryClass',null);this.set('secondaryClass',null);this.$('.nav-class-drop-down').slideUp(400);}if(this.get('onItemSelected')){if(!(item === 'performance' && isPremiumClass)){this.selectItem(item);}this.sendAction('onItemSelected',item);}}, /**
       * Triggered when a menu item is selected. Set the class icon for the item selected showing in the mobiles dropdown menu.
       */toggleHeader:function toggleHeader(){this.set('toggleState',!this.get('toggleState'));if(this.onCollapseExpandClicked){this.sendAction('onCollapseExpandClicked',this.get('toggleState'));}},closeNotificationList:function closeNotificationList(){this.set('displayNotificationList',false);},toggleNavClass:function toggleNavClass(){var component=this;component.$('.nav-class-drop-down').slideToggle(400);},onChangeSecondaryClass:function onChangeSecondaryClass(secondaryClass){var secondaryClasses=this.get('secondaryClasses');secondaryClasses.map(function(secondary){secondary.set('isActive',false);});secondaryClass.set('isActive',true);this.set('selectedSecondaryClass',secondaryClass);this.send('toggleNavClass');this.sendAction('onChangeSecondaryClass',secondaryClass);}, //Action triggered while toggling nav bar
onToggleMenu:function onToggleMenu(){this.toggleProperty('isMenuExpanded');if(!this.get('isMenuExpanded')){this.sendAction('onItemSelected','class-activities');}},navigateRoute:function navigateRoute(){var component=this;var queryParams={};if(component.get('class').isArchived){queryParams = {showActiveClasses:false,showArchivedClasses:true};}component.get('router').transitionTo('teacher-home',{queryParams:queryParams});}}, // -------------------------------------------------------------------------
// Events
/**
     * DidInsertElement ember event
     */didInsertElement:function didInsertElement(){this._super.apply(this,arguments);var getOwner=_ember['default'].getOwner;var currentPath=getOwner(this).lookup('controller:application').currentPath;var component=this;if(currentPath === 'teacher.class.students'){component.set('selectedMenuItem','students');}else if(currentPath === 'teacher.class.course-map'){component.set('selectedMenuItem','course-map');}else if(currentPath === 'teacher.class.class-activities'){component.set('selectedMenuItem','class-activities');}var item=this.get('selectedMenuItem');this.selectItem(item);_ember['default'].$('header.gru-header').hide();},didRender:function didRender(){var component=this;this.$('.display-help-icon .showHelpIcon').remove();component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},willDestroyElement:function willDestroyElement(){this._super.apply(this,arguments);this.set('selectedMenuItem',null);this.set('isMenuExpanded',true);_ember['default'].$('header.gru-header').show();}, // -------------------------------------------------------------------------
// Observers
/**
     * Refreshes the left navigation with the selected menu item
     */refreshSelectedMenuItem:(function(){var urlParams=new URLSearchParams(window.location.search);var myParam=urlParams.get('landingPage');if(myParam === 'class-progress'){this.set('selectedMenuItem','atc');}var secondaryClasses=this.get('secondaryClasses');secondaryClasses.map(function(secondary){secondary.set('isActive',false);});var item=this.get('selectedMenuItem');this.selectItem(item);}).observes('selectedMenuItem'), // -------------------------------------------------------------------------
// Methods
/**
     * Triggered when a menu item is selected. Set the class icon for the item selected showing in the mobiles dropdown menu.
     * @param {string} item
     */selectItem:function selectItem(item){if(item){var itemElement='.' + item;this.$('.tab').removeClass('vactive');this.$(itemElement).addClass('vactive');}}});});